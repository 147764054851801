import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../../types/global";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../../../contexts/auth-context";

export const useSalesReturnCreate = (status?: string | null) => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      ...(permissions?.goods_return?.create
        ? [
            {
              value: t("inventory.goods_return.index"),
              disabled: false,
            },
          ]
        : []),
    ],
    [permissions?.goods_return?.create, t]
  );

  const [disabled, setDisabled] = useState<boolean>(false);
  useEffect(() => {
    switch (status) {
      case "approved":
      case "finished":
        setDisabled(false);
        break;
      default:
        setDisabled(true);
    }
  }, [status]);
  return {
    selectCreateOptions: defaultOptions,
    disabledCreateOptions: disabled,
  };
};
