import { Fragment } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";

import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledTextField from "../../../Controller/ControlledTextField";

import { IDefaultForm } from "../../../../types/global";
import { IGoodsReceive } from "../../../../types/Inventory/GoodsReceive";
import { formatDateTime } from "../../../../utils/Date";
import { checkIsNotDraftOrEmptyStatus } from "../../../../utils/Global";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useStateContext } from "../../../../contexts/auth-context";

type Props = {
  disabled: IDefaultForm["disabled"];
  nestedIndex: number;
  status: string;
};

const SerialList = ({ nestedIndex, disabled, status }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IGoodsReceive>();
  const { id } = useParams();

  const {
    state: { permissions },
  } = useStateContext();

  const { fields, remove, update } = useFieldArray({
    control,
    name: `trace_entry_list.${nestedIndex}.serial_list` as `trace_entry_list`,
  });

  const rescanBarcodeHandler = (index: number) => {
    const { id, ...otherFields } = fields[index];

    update(index, {
      ...otherFields,
      status: "is_active",
      source_bin_location: undefined,
      source_bin_location_id: undefined,
      scanned_by: undefined,
      posted_date: undefined,
    });
  };

  const notDraft = checkIsNotDraftOrEmptyStatus(status);
  const isFinished = ["finished", "cancelled"].includes(status);
  const uneditable = Boolean(id) && !permissions?.goods_receive?.update;

  return (
    <>
      {fields.map((serial, index) => (
        <TableRow key={serial.id}>
          <TableCell></TableCell>
          <TableCell
            align="center"
            sx={{
              px: 1,
              py: 1,
            }}
          >
            <Typography fontSize={14}>
              {formatDateTime(serial.posted_date)}
            </Typography>
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell align="center">
            <ControlledTextField
              control={control}
              type="number"
              name={`trace_entry_list.${nestedIndex}.serial_list.${index}.qty`}
              InputProps={{
                inputProps: { min: 0 },
              }}
              error={Boolean(
                errors?.trace_entry_list?.[nestedIndex]?.serial_list?.[index]
                  ?.qty
              )}
              viewMode={notDraft || uneditable}
            />
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>{serial.uom?.name_th}</Typography>
          </TableCell>
          {!notDraft && <TableCell></TableCell>}
          <TableCell></TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>{serial.serial_no}</Typography>
          </TableCell>
          {notDraft && (
            <Fragment>
              <TableCell>
                <Typography fontSize={14}>{serial.barcode}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontSize={14}>
                  {serial.source_bin_location?.name}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Box display={"flex"}>
                  <CustomizedStatus status={serial.status} />
                  {!isFinished &&
                    !uneditable &&
                    serial.status === "is_scanned" && (
                      <CustomizedTooltip title="สแกนใหม่">
                        <IconButton onClick={() => rescanBarcodeHandler(index)}>
                          <ReplayOutlinedIcon fontSize="small" />
                        </IconButton>
                      </CustomizedTooltip>
                    )}
                </Box>
              </TableCell>
              <TableCell align="center">
                {serial.scanned_by && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CustomizedAvatar
                      avatars={[
                        {
                          unique_id: serial.scanned_by.user_unique_id || "",
                          first_name: serial.scanned_by.first_name || "",
                          last_name: serial.scanned_by.last_name || "",
                          img_url: serial.scanned_by.img_url
                            ? serial.scanned_by.img_url[0]
                            : "",
                        },
                      ]}
                    />
                  </Box>
                )}
              </TableCell>
            </Fragment>
          )}
          <TableCell align="center">
            <Typography fontSize={14}>
              <ControlledTextField
                placeholder={t("sentence.remark")}
                control={control}
                name={`trace_entry_list.${nestedIndex}.serial_list.${index}.remark`}
                viewMode={isFinished || uneditable}
                viewModeNoLabel
              />
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>
              <ControlledDatePicker
                placeholder="LOT"
                control={control}
                name={`trace_entry_list.${nestedIndex}.serial_list.${index}.lot_date`}
                viewMode={isFinished || uneditable}
                viewModeNoLabel
              />
              {/* {formatDate(serial.lot_date)} */}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>
              <ControlledDatePicker
                placeholder={t("date.manufactured_date")}
                control={control}
                name={`trace_entry_list.${nestedIndex}.serial_list.${index}.manufactured_date`}
                viewMode={isFinished || uneditable}
                viewModeNoLabel
              />
              {/* {formatDate(serial.manufactured_date)} */}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>
              <ControlledDatePicker
                placeholder={t("date.expired_date")}
                control={control}
                name={`trace_entry_list.${nestedIndex}.serial_list.${index}.expired_date`}
                viewMode={isFinished || uneditable}
                viewModeNoLabel
              />
              {/* {formatDate(serial.expired_date)} */}
            </Typography>
          </TableCell>
          {!notDraft && !uneditable && (
            <TableCell align="center">
              <IconButton onClick={() => remove(index)}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      ))}
    </>
  );
};

export default SerialList;
