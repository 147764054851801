import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../../types/global";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../../../contexts/auth-context";

export const useCashSalesCreate = (status?: string | null) => {
  const { t } = useTranslation();

  const {
    state: { permissions },
  } = useStateContext();

  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      ...(permissions?.delivery_order?.create
        ? [
            {
              value: t("logistic.delivery_order.index"),
              disabled: false,
            },
          ]
        : []),
      ...(permissions?.sales_return?.create
        ? [
            {
              value: t("sales.return.index"),
              disabled: false,
            },
          ]
        : []),
    ],
    [permissions?.delivery_order?.create, permissions?.sales_return?.create, t]
  );

  const [disabled, setDisabled] = useState<boolean>(false);
  useEffect(() => {
    switch (status) {
      case "finished":
        setDisabled(false);
        break;
      default:
        setDisabled(true);
    }
  }, [status]);
  return {
    selectCreateOptions: defaultOptions,
    disabledCreateOptions: disabled,
  };
};
