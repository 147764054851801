import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { IBreadcrumbsAndMenu, IMenuOption, ITab } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { Box, Typography } from "@mui/material";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import PromotionTable from "../../../components/Table/Marketing/Promotion";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import AddIcon from "@mui/icons-material/Add";
import { PromotionCategory } from "../../../generated/marketing";

const Promotion = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const categoryFilter = searchParams.get("filter") as PromotionCategory;

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("marketing.index"),
      to: "/marketing",
    },
    {
      name: t("marketing.promotion.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    {
      label: t("marketing.promotion.type_short.discount_product"),
      path: `${pathname}?filter=discount_product`,
    },
    {
      label: t("marketing.promotion.type_short.discount_fix_price"),
      path: `${pathname}?filter=discount_fix_price`,
    },
    {
      label: t("marketing.promotion.type_short.discount_order"),
      path: `${pathname}?filter=discount_order`,
    },
    {
      label: t("marketing.promotion.type_short.free_gift"),
      path: `${pathname}?filter=free_gift`,
    },
  ];

  const currentTab =
    pathname + (categoryFilter ? `?filter=${categoryFilter}` : "");

  const createOptions: IMenuOption[] = [
    { value: t(`marketing.promotion.type.discount_product`) },
    { value: t(`marketing.promotion.type.discount_fix_price`) },
    { value: t(`marketing.promotion.type.discount_order`), disabled: true },
    { value: t(`marketing.promotion.type.free_gift`), disabled: true },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
        <Typography variant="h5">{t("marketing.promotion.index")}</Typography>
        <Box display="flex" gap={1}>
          <CustomizedMenuOptions
            label={`${t("button.create")}${t("marketing.promotion.index")}`}
            variant="contained"
            options={createOptions}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerHTML) {
                case t(`marketing.promotion.type.discount_product`):
                  navigate(`/marketing/promotion/discount-product/add`);
                  break;
                case t(`marketing.promotion.type.discount_fix_price`):
                  navigate(`/marketing/promotion/discount-fix-price/add`);
                  break;
                case t(`marketing.promotion.type.discount_order`):
                  navigate(`/marketing/promotion/discount-order/add`);
                  break;
                case t(`marketing.promotion.type.free_gift`):
                  navigate(`/marketing/promotion/free-gift/add`);
                  break;
                default:
                  break;
              }
            }}
            startIcon={<AddIcon />}
          />
        </Box>
      </Box>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <PromotionTable categoryFilter={categoryFilter} />
    </>
  );
};

export default Promotion;
