import { Box, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useConfirmation } from "../../../../hooks/use-confirmation";
import { IBreadcrumbsAndMenu, IMenuOption } from "../../../../types/global";
import CustomizedBreadcrumbs from "../../../Custom/CustomizedBreadcrumbs";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import { useState, useMemo } from "react";
import Confirmation from "../../../UI/Confirmation";
import { useParams } from "react-router-dom";
import { useFormContext, useWatch } from "react-hook-form";
import { ActivityLogSlideInPanel } from "../../../UI/SlideInPanel/ActivityLogSlideInPanel";
import { ActivityLogDocumentType } from "../../../../generated/general";
import { useStateContext } from "../../../../contexts/auth-context";

interface Props {
  cancelHandler: () => void;
  cancelPendingScanHandler: () => void;
}

const GoodsTransferHeaderBreadcrumbs = ({
  cancelHandler,
  cancelPendingScanHandler,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const { control, getValues } = useFormContext();

  const {
    state: { authUser, permissions },
  } = useStateContext();

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const traceEntries = useWatch({
    control,
    name: "trace_entry_list",
  });

  //TODO if normal tracability, check like below, if serial, check serial_list
  const traceEntriesHasPendingScan = useMemo(() => {
    return (
      traceEntries?.filter((trace: any) => {
        if (trace.tracability === "serial") {
          if (
            trace.serial_list?.some(
              (serialItem: any) =>
                serialItem.status !== "cancelled" &&
                (!serialItem.scanned_by || !serialItem.destination_scanned_by)
            )
          ) {
            return true;
          } else {
            return false;
          }
        }
        return (
          trace.status !== "cancelled" &&
          (!trace.scanned_by || !trace.destination_scanned_by)
        );
      })?.length > 0
    );
  }, [traceEntries]);

  const options: IMenuOption[] =
    (status === "finished" && !traceEntriesHasPendingScan) ||
    status !== "finished"
      ? [
          {
            value: t("button.cancel"),
            disabled: false,
          },
        ]
      : [
          {
            value: t("button.cancel_pending_scan"),
            disabled: false,
          },
        ];

  const disabledMenuOption =
    !id ||
    (status === "finished" && !traceEntriesHasPendingScan) ||
    status === "cancelled" ||
    !permissions?.goods_transfer?.cancel;

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(cancelHandler);

  const {
    confirmation: confirmationCancelPendingScan,
    openConfirmationHandler: openConfirmationCancelPendingScanHandler,
    closeConfirmationHandler: closeConfirmationCancelPendingScanHandler,
    submitConfirmationHandler: submitConfirmationCancelPendingScanHandler,
  } = useConfirmation(cancelPendingScanHandler);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.goods_transfer.index"),
      to: "/inventory/goods-transfer",
    },
    {
      name:
        id && Boolean(id)
          ? getValues("unique_id")
          : t(`${t("button.create")}${t("inventory.goods_transfer.index")}`),
    },
  ];

  return (
    <>
      {id && (
        <ActivityLogSlideInPanel
          open={openDrawer}
          handleClose={() => setOpenDrawer(false)}
          documentId={id!}
          documentType={ActivityLogDocumentType.GoodsTransfer}
        />
      )}
      <Box
        maxWidth={1040}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: 1040,
        }}
      >
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {!disabledMenuOption && (
            <CustomizedMenuOptions
              fullWidth
              size="medium"
              label={t("button.option")}
              options={options}
              onSelect={(e) => {
                const value = e.target as HTMLElement;
                switch (value.innerText) {
                  case `${t("button.cancel")}`:
                    openConfirmationHandler();
                    break;
                  case `${t("button.cancel_pending_scan")}`:
                    openConfirmationCancelPendingScanHandler();
                    break;
                  default:
                }
              }}
              sx={{
                "& .MuiButton-endIcon": {
                  marginLeft: "4px",
                },
                paddingX: "24px",
                height: "34px",
                width: "119px",
                borderRadius: "8px",
              }}
              disabled={!id}
            />
          )}
          <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
            <IconButton
              onClick={() => {
                setOpenDrawer(true);
              }}
              sx={{
                color: (theme) => theme.palette.grey[500],
                padding: 0,
                marginLeft: "4px",
              }}
            >
              <RestoreOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        </Box>
      </Box>
      <Confirmation
        title="ยืนยันหากต้องการยกเลิกเอกสาร"
        message="หากยกเลิกแล้ว จะไม่สามารถเปลี่ยนแปลงได้"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
      <Confirmation
        title="ยกเลิกจำนวนที่ค้าง"
        message="หากยกเลิกจำนวนที่ค้างจะไม่สามารถแก้ไขได้"
        open={confirmationCancelPendingScan}
        handleClose={closeConfirmationCancelPendingScanHandler}
        action={submitConfirmationCancelPendingScanHandler}
      />
    </>
  );
};

export default GoodsTransferHeaderBreadcrumbs;
