import { useCallback, useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../../types/global";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../../../contexts/auth-context";

export const usePurchaseOrderOption = (status?: string | null) => {
  const {
    state: { permissions },
  } = useStateContext();

  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: "อ้างอิงเอกสารภายนอก",
        disabled: false,
      },
      {
        value: "แก้ไข",
        disabled: false,
      },
      {
        value: "คัดลอก",
        disabled: false,
      },
      {
        value: "ยกเลิก",
        disabled: false,
      },
    ],
    []
  );

  const externalRefOption = defaultOptions[0];
  const editOption = defaultOptions[1];
  const copyOption = defaultOptions[2];
  const cancelOption = defaultOptions[3];

  const cancelPartiallyQtyOption: IMenuOption = useMemo(
    () => ({ value: "ยกเลิกจำนวนคงค้าง", disabled: false }),
    []
  );

  const canUpdateAndCancel = useCallback(() => {
    return (
      permissions?.purchase_order?.update && permissions?.purchase_order?.cancel
    );
  }, [permissions]);

  const getOptionsForStatus = useCallback(
    (status?: string | null) => {
      const isCreate = permissions?.purchase_order?.create;

      if (["draft", "not_approved"].includes(status || "")) {
        if (canUpdateAndCancel()) {
          return isCreate ? [copyOption, cancelOption] : [cancelOption];
        }
        return isCreate ? [copyOption] : [];
      }

      if (["wait_approve"].includes(status || "")) {
        if (canUpdateAndCancel()) {
          return isCreate
            ? [editOption, copyOption, cancelOption]
            : [editOption, cancelOption];
        }
        return isCreate ? [copyOption] : [];
      }

      if (["partially_imported"].includes(status || "")) {
        if (canUpdateAndCancel()) {
          return isCreate
            ? [cancelPartiallyQtyOption, externalRefOption, copyOption]
            : [cancelPartiallyQtyOption, externalRefOption];
        }
        return isCreate ? [copyOption] : [];
      }

      if (status === "fully_imported") {
        if (canUpdateAndCancel()) {
          return isCreate
            ? [externalRefOption, copyOption]
            : [externalRefOption];
        }
        return isCreate ? [copyOption] : [];
      }

      if (status === "cancelled") {
        return isCreate ? [copyOption] : [];
      }

      // Default case when no specific status matches
      return canUpdateAndCancel()
        ? isCreate
          ? defaultOptions
          : [externalRefOption, editOption, cancelOption]
        : isCreate
        ? [copyOption]
        : [];
    },
    [
      permissions?.purchase_order?.create,
      canUpdateAndCancel,
      defaultOptions,
      editOption,
      cancelOption,
      copyOption,
      cancelPartiallyQtyOption,
      externalRefOption,
    ]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    const options = getOptionsForStatus(status);
    setSelectedModifyOptions(options);
  }, [status, getOptionsForStatus]);

  return selectModifyOptions;
};

export const usePurchaseOrderCreate = (status?: string | null) => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      ...(permissions?.goods_receive?.create
        ? [
            {
              value: "ใบ" + t("inventory.goods_receive.index"),
              disabled: status === "fully_imported",
            },
          ]
        : []),
      ...(permissions?.purchase_return?.create
        ? [
            {
              value: t("purchase.return.index"),
              disabled: false,
            },
          ]
        : []),
    ],
    [
      permissions?.goods_receive?.create,
      permissions?.purchase_return?.create,
      status,
      t,
    ]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "partially_imported":
        setSelectedModifyOptions(
          permissions?.goods_receive?.create &&
            permissions?.purchase_return?.create
            ? [defaultOptions[0], defaultOptions[1]]
            : permissions?.goods_receive?.create
            ? [defaultOptions[0]]
            : permissions?.purchase_return?.create
            ? [defaultOptions[1]]
            : []
        ); // คัดลอก
        break;
      case "fully_imported":
        setSelectedModifyOptions(
          permissions?.purchase_return?.create ? [defaultOptions[1]] : []
        ); // คัดลอก
        break;
      default:
        setSelectedModifyOptions(defaultOptions);
        break;
    }
  }, [
    defaultOptions,
    permissions?.goods_receive?.create,
    permissions?.purchase_return?.create,
    status,
  ]);

  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    switch (status) {
      case "accepted":
      case "approved":
      case "finished":
      case "partially_imported":
      case "fully_imported":
        setDisabled(false);
        break;
      default:
        setDisabled(true);
    }
  }, [status]);

  return {
    selectCreateOptions: selectModifyOptions,
    disabledCreateOptions: disabled,
  };
};
