import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import ControlledTextField from "../../../Controller/ControlledTextField";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import { IGoodsReturn } from "../../../../types/Inventory/GoodsReturn";
import { useDisable } from "../../../../hooks/use-disable";
import { formatDate, formatDateTimeNoAMPM } from "../../../../utils/Date";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../../../../contexts/auth-context";
import { useParams } from "react-router-dom";

type Props = {
  nestedIndex: number;
};

const SerialList = ({ nestedIndex }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    state: { permissions },
  } = useStateContext();
  const {
    control,
    formState: { errors },
  } = useFormContext<IGoodsReturn>();
  const [disabled] = useDisable();
  const { fields, remove } = useFieldArray({
    control,
    name: `trace_entry_list.${nestedIndex}.serial_list` as `trace_entry_list`,
  });

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const uneditable = Boolean(id) && !permissions?.goods_return?.update;

  return (
    <>
      {fields.map((serial, index) => (
        <TableRow key={serial.id}>
          <TableCell></TableCell>
          <TableCell align="center">
            {formatDateTimeNoAMPM(serial.posted_date)}
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell align="center">
            {disabled && !uneditable ? (
              <Typography fontSize={14}>{serial.qty}</Typography>
            ) : (
              <ControlledTextField
                control={control}
                type="number"
                name={`trace_entry_list.${nestedIndex}.serial_list.${index}.qty`}
                error={Boolean(
                  errors?.trace_entry_list?.[nestedIndex]?.serial_list?.[index]
                    ?.qty
                )}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            )}
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>{serial.uom?.name_th}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>{serial.serial_no}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>
              {serial.source_bin_location?.name}
            </Typography>
          </TableCell>
          <TableCell align="center">
            {serial.scanned_by && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CustomizedAvatar
                  avatars={[
                    {
                      unique_id: serial.scanned_by.user_unique_id || "",
                      first_name: serial.scanned_by.first_name || "",
                      last_name: serial.scanned_by.last_name || "",
                      img_url: serial.scanned_by.img_url
                        ? serial.scanned_by.img_url[0]
                        : "",
                    },
                  ]}
                />
              </Box>
            )}
          </TableCell>
          <TableCell>{serial.barcode}</TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>
              <ControlledTextField
                placeholder={t("sentence.remark")}
                control={control}
                name={`trace_entry_list.${nestedIndex}.serial_list.${index}.remark`}
                viewMode={status === "finished" || uneditable}
                viewModeNoLabel
              />
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>{formatDate(serial.lot_date)}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>
              {formatDate(serial.manufactured_date)}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>
              {formatDate(serial.expired_date)}
            </Typography>
          </TableCell>
          {!disabled && !uneditable && (
            <TableCell align="center">
              <IconButton onClick={() => remove(index)}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      ))}
    </>
  );
};

export default SerialList;
