import { useCallback, useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../../types/global";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../../../contexts/auth-context";

export const useSalesInvoiceOption = (status?: string | null) => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: "แก้ไข",
        disabled: false,
      },
      {
        value: "คัดลอก",
        disabled: false,
      },
      {
        value: "ยกเลิก",
        disabled: false,
      },
    ],
    []
  );

  const editOption = defaultOptions[0];
  const copyOption = defaultOptions[1];
  const cancelOption = defaultOptions[2];

  const externalRefOption: IMenuOption = useMemo(
    () => ({ value: t("sales.external_reference_no"), disabled: false }),
    [t]
  );

  const canUpdate = useCallback(() => {
    return permissions?.sales_invoice?.update;
  }, [permissions]);

  const canCancel = useCallback(() => {
    return permissions?.sales_invoice?.cancel;
  }, [permissions]);

  const getOptionsForStatus = useCallback(
    (status?: string | null) => {
      const isCreate = permissions?.sales_invoice?.create;

      if (["draft", "not_approved"].includes(status || "")) {
        if (canCancel()) {
          return isCreate ? [copyOption, cancelOption] : [cancelOption];
        }
        return isCreate ? [copyOption] : [];
      }

      if (["cancelled", "finished"].includes(status || "")) {
        return isCreate ? [copyOption] : [];
      }

      if (
        ["wait_payment", "partially_payment", "fully_payment"].includes(
          status || ""
        )
      ) {
        if (canUpdate() && canCancel()) {
          return isCreate
            ? [externalRefOption, ...defaultOptions]
            : [externalRefOption, editOption, cancelOption];
        }
        if (canUpdate()) {
          return isCreate
            ? [externalRefOption, copyOption, editOption]
            : [externalRefOption, editOption];
        }
        if (canCancel()) {
          return isCreate ? [copyOption, cancelOption] : [cancelOption];
        }
        return isCreate ? [copyOption] : [];
      }

      // Default case when no specific status matches
      return canUpdate() && canCancel()
        ? isCreate
          ? defaultOptions
          : [editOption, cancelOption]
        : canUpdate()
        ? isCreate
          ? [copyOption, editOption]
          : [editOption]
        : canCancel()
        ? isCreate
          ? [copyOption, cancelOption]
          : [cancelOption]
        : [];
    },
    [
      permissions?.sales_invoice?.create,
      canUpdate,
      canCancel,
      defaultOptions,
      editOption,
      cancelOption,
      copyOption,
      externalRefOption,
    ]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    const options = getOptionsForStatus(status);
    setSelectedModifyOptions(options);
  }, [getOptionsForStatus, status]);

  return selectModifyOptions;
};
