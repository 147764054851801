import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import ControlledTextField from "../../../Controller/ControlledTextField";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import ControlledSelect from "../../../Controller/ControlledSelect";
import { IGoodsIssue } from "../../../../types/Inventory/GoodsIssue";
import { formatDate, formatDateTimeNoAMPM } from "../../../../utils/Date";
import { useDisable } from "../../../../hooks/use-disable";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../../../../contexts/auth-context";

type Props = {
  nestedIndex: number;
};

const SerialList = ({ nestedIndex }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IGoodsIssue>();
  const { fields, update, remove } = useFieldArray({
    control,
    name: `trace_entry_list.${nestedIndex}.serial_list` as `trace_entry_list`,
  });

  const { id } = useParams();
  const [disabled] = useDisable();
  const {
    state: { permissions },
  } = useStateContext();

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const disabledRemoveItem = ![undefined, null, "", "draft"].includes(
    status || ""
  );

  const uneditable = Boolean(id) && !permissions?.goods_issue?.update;

  return (
    <>
      {fields.map((serial, index) => (
        <TableRow key={serial.id}>
          <TableCell></TableCell>
          <TableCell align="center">
            {formatDateTimeNoAMPM(serial.posted_date)}
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell align="center">
            {disabledRemoveItem ? (
              <Typography fontSize={14}>{serial.qty}</Typography>
            ) : (
              <ControlledTextField
                control={control}
                type="number"
                name={`trace_entry_list.${nestedIndex}.serial_list.${index}.qty`}
                error={Boolean(
                  errors?.trace_entry_list?.[nestedIndex]?.serial_list?.[index]
                    ?.qty
                )}
                InputProps={{
                  inputProps: {
                    min: 0,
                    // style: {
                    //   fontSize: 14,
                    // },
                  },
                }}
                FormHelperTextProps={{
                  style: { fontSize: 10 },
                }}
                viewMode={uneditable}
                helperText={`จำนวนคงคลัง ${serial.stock_qty?.toString()}`}
              />
            )}
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>{serial.uom?.name_th}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>{serial.serial_no}</Typography>
          </TableCell>
          <TableCell align="center">
            {serial.all_bin_locations &&
            serial.all_bin_locations.length > 1 &&
            !disabled &&
            !uneditable ? (
              <ControlledSelect
                control={control}
                name={`trace_entry_list.${nestedIndex}.serial_list.${index}.source_bin_location_id`}
                options={serial.all_bin_locations.map((bin) => ({
                  label: bin.name,
                  value: bin.id,
                }))}
                onChange={(e: any) => {
                  const { id, ...otherFields } = fields[index];
                  update(index, {
                    ...otherFields,
                    stock_qty:
                      serial.all_bin_locations &&
                      serial.all_bin_locations.find(
                        (bin) => bin.id === e.target.value
                      ).stock_qty,
                  });
                }}
                inputProps={{
                  style: {
                    fontSize: 14,
                  },
                }}
                MenuProps={{
                  style: {
                    fontSize: 14,
                  },
                }}
                helperTextSize={10}
              />
            ) : (
              <Typography fontSize={14}>
                {serial.source_bin_location?.name}
              </Typography>
            )}
          </TableCell>
          <TableCell align="center">
            {serial.scanned_by && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CustomizedAvatar
                  avatars={[
                    {
                      unique_id: serial.scanned_by.user_unique_id || "",
                      first_name: serial.scanned_by.first_name || "",
                      last_name: serial.scanned_by.last_name || "",
                      img_url: serial.scanned_by.img_url
                        ? serial.scanned_by.img_url[0]
                        : "",
                    },
                  ]}
                />
              </Box>
            )}
          </TableCell>
          <TableCell>
            <Typography fontSize={14}>{serial.barcode}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>
              <ControlledTextField
                placeholder={t("sentence.remark")}
                control={control}
                name={`trace_entry_list.${nestedIndex}.serial_list.${index}.remark`}
                viewMode={(disabled && disabledRemoveItem) || uneditable}
                viewModeNoLabel
              />
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>{formatDate(serial.lot_date)}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>
              {formatDate(serial.manufactured_date)}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>
              {formatDate(serial.expired_date)}
            </Typography>
          </TableCell>
          {(!id || status === "draft") && !uneditable && (
            <TableCell align="center">
              <IconButton onClick={() => remove(index)}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      ))}
    </>
  );
};

export default SerialList;
