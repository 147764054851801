import {
  ColDef,
  ValueFormatterParams,
  ICellRendererParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../utils/Date";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";

import CustomizedStatus from "../../../Custom/CustomizedStatus";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";

export const usePromotionColumnDefs = () => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    setColumnDefs([
      {
        field: "unique_id",
        headerName: `${t("marketing.promotion.unique_id")}`,
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "name",
        headerName: `${t("marketing.promotion.name")}`,
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "promotion_category",
        headerName: `${t("marketing.promotion.type.index")}`,
        filter: "agSetColumnFilter",
        filterParams: {
          values: [
            "discount_product",
            "discount_fix_price",
            "discount_order",
            "free_gift",
          ],
          valueFormatter: (params: ValueFormatterParams) =>
            t(`marketing.promotion.type.${params.value}`),
        },
        valueFormatter: (params: ValueFormatterParams) =>
          t(`marketing.promotion.type.${params.value}`),
        flex: 1,
        minWidth: 200,
      },
      {
        field: "start_date",
        headerName: `${t("date.promotion.start_date")}`,
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        flex: 1,
        minWidth: 200,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "end_date",
        headerName: `${t("date.promotion.end_date")}`,
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        flex: 1,
        minWidth: 200,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "type",
        headerName: `${t("marketing.promotion.setting_type.index")}`,
        filter: "agSetColumnFilter",
        filterParams: {
          values: [
            "all_promotions",
            "single_promotion",
            "single_category_promotion",
          ],
          valueFormatter: (params: ValueFormatterParams) =>
            t(`marketing.promotion.setting_type.${params.value}`),
        },
        valueFormatter: (params: ValueFormatterParams) =>
          t(`marketing.promotion.setting_type.${params.value}`),
        flex: 1,
        minWidth: 200,
        hide: true,
      },
      {
        field: "use_auto",
        headerName: `${t("marketing.promotion.use_auto")}`,
        filter: "agSetColumnFilter",
        flex: 1,
        minWidth: 200,
        filterParams: {
          values: [1, 0],
          valueFormatter: (params: ValueFormatterParams) =>
            params.value ? "ใช่" : "ไม่ใช่",
        },
        valueFormatter: (params: ValueFormatterParams) =>
          params.value ? "ใช่" : "ไม่ใช่",
        hide: true,
        cellRenderer: ({ value }: { value: number }) => {
          return (
            <CustomizedStatus
              status={value ? "active" : "inactive"}
              text={value ? "ใช่" : "ไม่ใช่"}
            />
          );
        },
        cellStyle: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        headerClass: "ag-center-header",
      },
      {
        field: "priority",
        headerName: `${t("marketing.promotion.priority")}`,
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
        hide: true,
      },
      {
        field: "purchase_condition",
        headerName: `${t("marketing.promotion.purchase_condition.index")}`,
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["all_items", "specific_items"],
          valueFormatter: (params: ValueFormatterParams) =>
            t(`marketing.promotion.purchase_condition.${params.value}`),
        },
        valueFormatter: (params: ValueFormatterParams) =>
          t(`marketing.promotion.purchase_condition.${params.value}`),
        flex: 1,
        minWidth: 200,
        hide: true,
      },
      {
        field: "receive_type",
        headerName: `${t("marketing.promotion.receive_type.index")}`,
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["percent", "amount", "freebie"],
          valueFormatter: (params: ValueFormatterParams) =>
            t(`marketing.promotion.receive_type.table.${params.value}`),
        },
        valueFormatter: (params: ValueFormatterParams) =>
          t(`marketing.promotion.receive_type.table.${params.value}`),
        flex: 1,
        minWidth: 200,
        hide: true,
      },
      {
        field: "coupon_type",
        headerName: `${t("marketing.promotion.coupon_type.index")}`,
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["one_time", "many_time"],
          valueFormatter: (params: ValueFormatterParams) =>
            t(`marketing.promotion.coupon_type.${params.value}`),
        },
        valueFormatter: (params: ValueFormatterParams) =>
          t(`marketing.promotion.coupon_type.${params.value}`),
        flex: 1,
        minWidth: 200,
        hide: true,
      },
      {
        field: "available_amount",
        headerName: `${t("marketing.promotion.remaining_coupon")}`,
        filter: "agNumberColumnFilter",
        flex: 1,
        minWidth: 200,
        hide: true,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.data.usage_limit_type === "no_limit") {
            return "-";
          }
          return params.value;
        },
      },
      {
        field: "customer_eligibility",
        headerName: `${t("marketing.promotion.customer_eligibility.index")}`,
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["all", "specific_groups", "specific_customers"],
          valueFormatter: (params: ValueFormatterParams) =>
            t(`marketing.promotion.customer_eligibility.${params.value}`),
        },
        valueFormatter: (params: ValueFormatterParams) =>
          t(`marketing.promotion.customer_eligibility.${params.value}`),
        flex: 1,
        minWidth: 200,
        hide: true,
      },
      {
        field: "sales_channel_type",
        headerName: `${t("marketing.promotion.sales_channel_type.index")}`,
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["all", "specific_channels", "specific_sales"],
          valueFormatter: (params: ValueFormatterParams) =>
            t(`marketing.promotion.sales_channel_type.${params.value}`),
        },
        valueFormatter: (params: ValueFormatterParams) =>
          t(`marketing.promotion.sales_channel_type.${params.value}`),
        flex: 1,
        minWidth: 200,
        hide: true,
      },
      {
        field: "usage_limit_type",
        headerName: `${t("marketing.promotion.usage_limit.index")}`,
        // filter: "agNumberColumnFilter",
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["no_limit", "limited"],
          valueFormatter: (params: ValueFormatterParams) =>
            t(`marketing.promotion.usage_limit.${params.value}`),
        },
        valueFormatter: (params: ValueFormatterParams) =>
          t(`marketing.promotion.usage_limit.${params.value}`),
        flex: 1,
        minWidth: 200,
        hide: true,
      },
      {
        field: "created_date",
        headerName: `${t("marketing.promotion.created_at")}`,
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        flex: 1,
        minWidth: 200,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "created_by_object",
        headerName: t("marketing.promotion.created_by"),
        filter: "agTextColumnFilter",
        cellRenderer: (params: ICellRendererParams<any, any>) => {
          if (params.value) {
            return <CustomizedAvatar avatars={[params.value]} />;
          }
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "last_updated_date",
        headerName: `${t("marketing.promotion.last_updated_at")}`,
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        flex: 1,
        minWidth: 200,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "status",
        headerName: `${t("marketing.promotion.status")}`,
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["active", "inactive"],
          valueFormatter: (params: ValueFormatterParams) =>
            t(`status.${params.value}`),
        },
        cellRenderer: ({ value }: { value: string }) => {
          return <CustomizedStatus status={value} />;
        },
        cellStyle: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        flex: 1,
        minWidth: 200,
        headerClass: "ag-center-header",
      },
    ]);
  }, [t]);

  return columnDefs;
};
