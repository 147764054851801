import { v4 as uuidv4 } from "uuid";
import { AgGridReact } from "ag-grid-react";
import { GraphQLClient } from "graphql-request";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";

import TocIcon from "@mui/icons-material/Toc";
import CloseIcon from "@mui/icons-material/Close";

import ItemNameCell from "./ItemNameCell";
import SumPreVatAmount from "./SumPreVatAmount";
import LabelInput from "../../../UI/LabelInput";
import PreVatAmountCell from "./PreVatAmountCell";
import ButtonLayout from "../../../UI/ButtonLayout";
import Confirmation from "../../../UI/Confirmation";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import ItemTableModal from "../../../UI/Modal/ItemTableModal";
import CustomizedButton from "../../../Custom/CustomizedButton";
import ControlledSelect from "../../../Controller/ControlledSelect";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import CheckedPurchaseRequestModal from "../../../UI/Modal/CheckedPurchaseRequestModal";

import {
  numberFormatter,
  priceNumberFormatter,
  priceVatTypeFormatter,
  vatTypeFormatter,
} from "../../../../utils/Formatter/Global";
import { priceListInItemPurchase } from "../../../../utils/Formatter/Sales";

import { useModal } from "../../../../hooks/use-modal";
import { useDisable } from "../../../../hooks/use-disable";
import { usePurchaseItemListHeader } from "../../../../hooks/Purchase/use-purchase-item-list-header";

import { ISelectOption } from "../../../../types/global";
import { IPurchaseItemList } from "../../../../types/Purchase";
import { IPurchaseOrder } from "../../../../types/Purchase/purchaseOrder";
import {
  PriceVatType,
  PurchaseDocumentType,
} from "../../../../generated/purchase";

import { ITEM_SKU_DETAIL_AGGRID } from "../../../../services/AgGrid/InventoryAggrid";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import SubHeader from "../../../Form/Purchase/SubHeader";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import {
  CurrencyViewAggridQuery,
  useCurrencyViewAggridQuery,
} from "../../../../generated/general";

interface Props {
  referenceItemList?: IPurchaseItemList[];
  documentType: string;
}

const vatOptions: ISelectOption[] = [
  {
    label: "ไม่มี",
    value: "ไม่มี",
  },
  {
    label: "0 %",
    value: "0",
  },
  {
    label: "7 %",
    value: "7",
  },
];

const priceVatTypeOptions: ISelectOption[] = [
  {
    label: "ราคารวมภาษี",
    value: PriceVatType.IncludeVat,
  },
  {
    label: "ราคาแยกภาษี",
    value: PriceVatType.ExcludeVat,
  },
];

const PurchaseOrderitemList = ({ documentType, referenceItemList }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<IPurchaseOrder>();
  const [disabled] = useDisable();
  const gridRef = useRef<AgGridReact<any>>(null);

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const graphQLClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: rowData } =
    useCurrencyViewAggridQuery<CurrencyViewAggridQuery>(graphQLClientGeneral);

  const currencyOptions =
    rowData?.CurrencyViewAggrid.results
      ?.sort()
      .filter((currency) => currency?.status === "active")
      .map((currency) => ({
        label: (currency?.code || "") + " - " + (currency?.name || ""),
        value: currency?.code,
      })) || [];

  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState<boolean>(false);
  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );

  const {
    modal: itemModal,
    openModalHandler: openItemModalHandler,
    closeModalHandler: closeItemModalHandler,
  } = useModal();

  const {
    modal: purchaseRequestModal,
    openModalHandler: openPurchaseRequestModalHandler,
    closeModalHandler: closePurchaseRequestModalHandler,
  } = useModal();

  const currency = useWatch({
    control,
    name: "currency",
  });

  const exchange_rate =
    useWatch({
      control,
      name: "exchange_rate",
    }) || 0;

  const reference_document_list = useWatch({
    control,
    name: "reference_document_list",
  });

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [idsSnapshot, setIdsSnapshot] = useState<string[]>([]);

  useEffect(() => {
    if (reference_document_list && reference_document_list.length > 0) {
      const uniqueIdList = reference_document_list.map(
        ({ document_unique_id }) => document_unique_id || ""
      );
      setSelectedIds(uniqueIdList);
      setIdsSnapshot(uniqueIdList);
    } else {
      setSelectedIds([]);
      setIdsSnapshot([]);
    }
  }, [reference_document_list]);

  const { fields, move, remove, append } = useFieldArray({
    control,
    name: "item_list",
    keyName: "unique_id",
  });

  const dragEndHandler = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  const openDeleteItemConfirmationHandler = (index: number) => {
    setOpenDeleteItemConfirmation(true);
    setDeletedIndex(index);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setDeletedIndex(undefined);
  };

  const deleteItemConfirmationAction = () => {
    if (deletedIndex || deletedIndex === 0) {
      const itemList = getValues("item_list");
      const deletedItem = itemList[deletedIndex];
      if (
        itemList.filter(
          (list: IPurchaseItemList) =>
            list.reference_line_item?.line_item_document_unique_id ===
            deletedItem.reference_line_item?.line_item_document_unique_id
        ).length === 1
      ) {
        const refList = getValues("reference_document_list");
        const removeRefList = refList?.filter(
          (ref) =>
            ref.document_unique_id !==
            deletedItem.reference_line_item?.line_item_document_unique_id
        );
        setValue("reference_document_list", removeRefList);
      }
      remove(deletedIndex);
    }
    closeDeleteItemConfirmationHandler();
  };

  const addItemsHandler = () => {
    const selectedItems = gridRef?.current?.api.getSelectedRows();
    if (selectedItems) {
      const document_unique_id = getValues("unique_id");

      const vatType = (sale_vat_type: "ไม่มี" | "0 %" | "7 %") => {
        switch (sale_vat_type) {
          case "ไม่มี":
            return "ไม่มี";
          case "0 %":
            return "0";
          case "7 %":
            return "7";
          default:
            return "ไม่มี";
        }
      };

      const priceList = getValues("item_price_list");

      selectedItems.forEach((sku_detail) => {
        let formatItems: IPurchaseItemList = {
          unique_id: uuidv4(),
          reference_document_type: PurchaseDocumentType.PurchaseOrder,
          reference_unique_id: document_unique_id || "",
          item_unique_id: sku_detail.item_unique_id || "",
          item_sku_name: sku_detail.sku_name,
          item_name: sku_detail.name,
          item_sku_desc: sku_detail.description,
          tracability: sku_detail.tracability,
          branch_id: getValues("branch_id"),
          qty: 1,
          discount: 0,
          pre_vat_amount: 0,
          price_per_unit:
            currency === "THB"
              ? sku_detail.purchase_standard_price
              : exchange_rate
              ? sku_detail.purchase_standard_price / Number(exchange_rate)
              : 0,
          uom_id: sku_detail.stock_uom_id,
          remark: "",
          stock_qty: 1,
          vat_percentage: vatType(sku_detail.sale_vat_type),
          withholding_tax_type: "ยังไม่ระบุ",
          withholding_tax_value: 0,
          uom_name: sku_detail.stock_uom_name_th,
          uom_group: sku_detail.uom_group,
          item_sku_qty: sku_detail.item_sku_qty_list,
          item_img_url: sku_detail.img_url,
          barcode: sku_detail.barcode || undefined,
        };

        const formatWithPriceList = priceListInItemPurchase(
          priceList,
          formatItems
        );

        append(formatWithPriceList);
      });
    }
    closeItemModalHandler();
  };

  const finishPurchaseRequestSelect = async (data: any) => {
    if (data && data.length > 0) {
      const po_unique_id = getValues("unique_id");
      const reference_document_list =
        getValues("reference_document_list") || [];

      const { itemList } = data.reduce(
        (lists: any, pr: any) => {
          pr.item_list.forEach((item: any) =>
            lists.itemList.push(item.item_sku_name)
          );
          return lists;
        },
        {
          itemList: [],
        }
      );

      const { itemSkuDetailsFindManyAggrid } =
        await graphQLClientWithHeaderItem.request(ITEM_SKU_DETAIL_AGGRID, {
          aggridInput: {
            startRow: 0,
            endRow: 9999,
            filterModel: {
              sku_name: {
                filterType: "set",
                values: itemList,
              },
            },
          },
        });

      const { results: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

      data.forEach((pr: any) => {
        pr.item_list.forEach((sku_detail: any) => {
          if (sku_detail) {
            const foundItemIndex = itemSkuDetails.findIndex(
              (sku: any) => sku.sku_name === sku_detail.item_sku_name
            );

            const apiItem = itemSkuDetails[foundItemIndex];

            const uniqueId = uuidv4();

            let formatItems: IPurchaseItemList = {
              unique_id: uniqueId,
              reference_unique_id: po_unique_id,
              reference_document_type: PurchaseDocumentType.PurchaseOrder,
              reference_line_item: {
                line_item_document_unique_id: pr.unique_id,
                line_item_document_type: PurchaseDocumentType.PurchaseRequest,
                line_item_unique_id: sku_detail.unique_id,
              },
              item_unique_id: sku_detail.item_unique_id || "",
              item_sku_name: sku_detail.item_sku_name,
              item_name: sku_detail.item_name,
              item_sku_desc: sku_detail.item_sku_desc,
              tracability: sku_detail.tracability,
              branch_id: getValues("branch_id"),
              discount: 0,
              price_per_unit:
                currency === "THB"
                  ? apiItem.item.purchase_standard_price
                  : exchange_rate
                  ? apiItem.item.purchase_standard_price / Number(exchange_rate)
                  : 0,
              vat_percentage: "ไม่มี",
              withholding_tax_type: "ยังไม่ระบุ",
              qty: sku_detail.qty - sku_detail.po_qty || 0,
              uom_id: sku_detail.uom_id,
              remark: sku_detail.remark || "",
              stock_qty: sku_detail.qty,
              uom_name: sku_detail.uom_name,
              uom_group: apiItem.item.uom_group,
              item_sku_qty: apiItem.item_sku_qty,
              item_img_url: sku_detail.item_img_url,
              barcode: sku_detail.barcode || apiItem.barcode || "",
            };
            const itemList = getValues("item_list");

            const isNotPresent = !itemList.some(
              (item) =>
                item.reference_line_item?.line_item_document_unique_id ===
                  formatItems.reference_line_item
                    ?.line_item_document_unique_id &&
                item.item_sku_name === formatItems.item_sku_name
            );

            if (isNotPresent) append(formatItems);
          }
        });
      });

      const referenceList = [
        ...reference_document_list,
        ...data.map((pr: any) => ({
          document_id: pr.id,
          document_unique_id: pr.unique_id,
          document_type: PurchaseDocumentType.PurchaseRequest,
        })),
      ];

      const newRef = Array.from(
        new Set(
          referenceList
            .map((doc: any) => doc.document_unique_id)
            .map((uniqueId: string) =>
              referenceList.find(
                (doc: any) => doc.document_unique_id === uniqueId
              )
            )
        )
      );

      setValue("reference_document_list", newRef);
    }
    closePurchaseRequestModalHandler();
  };

  const uomOptions = (field: IPurchaseItemList) => {
    const options = (
      field.uom_group
        ? field.uom_group.convertable_uom_list &&
          field.uom_group.convertable_uom_list.length > 0
          ? [
              {
                label: field.uom_group?.base_uom?.name_th || "",
                value: field.uom_group?.base_uom?.id || "",
              },
              ...field.uom_group.convertable_uom_list.map((uom) => ({
                label: uom.name_th,
                value: uom.id,
              })),
            ]
          : [
              {
                label: field.uom_group?.base_uom?.name_th || "",
                value: field.uom_group?.base_uom?.id || "",
              },
            ]
        : []
    ) as ISelectOption[];
    return options;
  };

  // const itemPriceListChangeHandler = (e: any) => {
  //   const value = e.target.value;
  //   if (value) {
  //     const itemPriceList: IPriceList = itemPriceListOption.find(
  //       (price) => price.value === value
  //     )?.actual_value;

  //     if (itemPriceList) {
  //       setValue("item_price_list", itemPriceList);
  //       const currentItemList: IPurchaseItemList[] = getValues("item_list");
  //       priceListEnumerator(itemPriceList, currentItemList, setValue);
  //     } else {
  //       setValue("item_price_list", undefined);
  //     }
  //   }
  // };

  const notEditStatus =
    ["approved", "partially_imported", "fully_imported"].includes(
      status || ""
    ) || disabled;

  const finishedStatus = [
    "approved",
    "partially_imported",
    "fully_imported",
  ].includes(status || "");

  const headers = usePurchaseItemListHeader(
    documentType,
    notEditStatus,
    status
  );

  return (
    <CustomizedBox
      sx={{ breakAfter: "auto" }}
      margin="2rem 0 0 0"
      maxWidth={1650}
    >
      <SubHeader title={"สินค้า"} />
      <Grid container my={2} spacing={2}>
        <Grid item sm={12} md={4}>
          {disabled ? (
            <LabelInput
              label={t("sales.price_vat_type")}
              value={priceVatTypeFormatter(getValues("price_vat_type"))}
            />
          ) : (
            <ControlledSelect
              control={control}
              name="price_vat_type"
              label={t("sales.price_vat_type")}
              options={priceVatTypeOptions}
              error={Boolean(errors.price_vat_type)}
              helperText={errors?.price_vat_type?.message?.toString()}
              disabled={notEditStatus}
            />
          )}
        </Grid>

        <Grid item sm={12} md={4}>
          {disabled ? (
            <LabelInput
              label={t("global.currency")}
              value={
                getValues("currency_display_name") ||
                currencyOptions.find(
                  (option) => option.value === getValues("currency")
                )?.label ||
                getValues("currency") ||
                ""
              }
            />
          ) : (
            <Controller
              control={control}
              name="currency_display_name"
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  label={t("global.currency")}
                  options={currencyOptions}
                  error={Boolean(errors.currency)}
                  helperText={errors.currency?.message?.toString()}
                  onChange={(e, value) => {
                    field.onChange(value?.label || "");
                    setValue("currency", value?.value || "");
                    if (value?.value === "THB") setValue("exchange_rate", "");
                  }}
                  value={
                    field.value ||
                    currencyOptions.find(
                      (option) => option.value === getValues("currency")
                    )?.label ||
                    getValues("currency") ||
                    ""
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.label === value
                  }
                  sx={{ width: "100%" }}
                  disabled={disabled || finishedStatus}
                  required
                />
              )}
            />
          )}
        </Grid>
        <Grid item sm={12} md={4}>
          <ControlledNumberTextField
            control={control}
            name="exchange_rate"
            label={t("global.exchange_rate")}
            error={Boolean(errors.exchange_rate)}
            helperText={errors.exchange_rate?.message?.toString()}
            disabled={currency === "THB" || disabled || finishedStatus}
            viewMode={disabled}
            sx={{ width: "100%" }}
            decimalScale={4}
            required
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align="center"
                  key={index}
                  sx={{
                    px: 1,
                    py: 1.5,
                    backgroundColor: "#F0F1FA",
                  }}
                  width={header.width}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      width: header.width,
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      color={"primary.main"}
                    >
                      {header.thaiLabel}
                    </Typography>
                    {header.required && (
                      <Typography
                        fontSize={14}
                        color={"error.main"}
                        fontWeight={600}
                      >
                        *
                      </Typography>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable" isDropDisabled={disabled}>
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((field, index) => (
                    <Draggable
                      key={field.unique_id}
                      draggableId={"item-" + field.unique_id}
                      index={index}
                      isDragDisabled={disabled}
                    >
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            ...provided.draggableProps.style,
                            breakInside: "avoid",
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[0]?.width || 0
                                : 0) + 16
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {!disabled && <TocIcon fontSize="small" />}
                              <Typography fontSize={14}>{index + 1}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[1]?.width || 0
                                : 0) + 16
                            }
                          >
                            <ItemNameCell
                              control={control}
                              errors={errors}
                              field={field}
                              index={index}
                              disabled={notEditStatus}
                              documentType={documentType}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[2]?.width || 0
                                : 0) + 16
                            }
                          >
                            {notEditStatus ? (
                              <Typography fontSize={14}>
                                {numberFormatter(field.qty)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                control={control}
                                name={`item_list.${index}.qty`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.qty
                                )}
                                InputProps={{ inputProps: { min: 0 } }}
                                onChange={(value) => {
                                  const parseValue = value || 0;
                                  const currentUomId = getValues(
                                    `item_list.${index}.uom_id`
                                  );
                                  if (currentUomId) {
                                    if (field.uom_group) {
                                      if (
                                        currentUomId ===
                                        field.uom_group.base_uom?.id
                                      ) {
                                        setValue(
                                          `item_list.${index}.stock_qty`,
                                          parseValue
                                        );
                                      } else {
                                        if (
                                          field.uom_group.uom_conversion_list &&
                                          field.uom_group.uom_conversion_list
                                            .length > 0
                                        ) {
                                          const conversionUom =
                                            field.uom_group.uom_conversion_list.find(
                                              (conversion) =>
                                                conversion.target_uom_id ===
                                                currentUomId
                                            );
                                          if (conversionUom) {
                                            const targetUomQty =
                                              conversionUom.base_uom_rate /
                                              conversionUom.target_uom_rate;
                                            setValue(
                                              `item_list.${index}.stock_qty`,
                                              parseValue * targetUomQty
                                            );
                                          }
                                        }
                                      }
                                    }
                                  }
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[3]?.width || 0
                                : 0) + 16
                            }
                          >
                            {notEditStatus ? (
                              <Typography fontSize={14}>
                                {field.uom_name}
                              </Typography>
                            ) : (
                              <ControlledSelect
                                control={control}
                                name={`item_list.${index}.uom_id`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.uom_id
                                )}
                                options={uomOptions(field)}
                                onChange={(e: any) => {
                                  const uom_name = uomOptions(field).find(
                                    (uom) => uom.value === e.target.value
                                  )?.label;
                                  setValue(
                                    `item_list.${index}.uom_name`,
                                    uom_name
                                  );
                                  const currentQty = getValues(
                                    `item_list.${index}.qty`
                                  );
                                  if (field.uom_group) {
                                    if (
                                      e.target.value ===
                                      field.uom_group.base_uom?.id
                                    ) {
                                      setValue(
                                        `item_list.${index}.stock_qty`,
                                        currentQty
                                      );
                                    } else {
                                      if (
                                        field.uom_group.uom_conversion_list &&
                                        field.uom_group.uom_conversion_list
                                          .length > 0
                                      ) {
                                        const conversionUom =
                                          field.uom_group.uom_conversion_list.find(
                                            (conversion) =>
                                              conversion.target_uom_id ===
                                              e.target.value
                                          );
                                        if (conversionUom) {
                                          const targetUomQty =
                                            conversionUom.base_uom_rate /
                                            conversionUom.target_uom_rate;
                                          setValue(
                                            `item_list.${index}.stock_qty`,
                                            currentQty * targetUomQty
                                          );
                                        }
                                      }
                                    }
                                  }
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[4]?.width || 0
                                : 0) + 16
                            }
                          >
                            {notEditStatus ? (
                              <Typography fontSize={14}>
                                {priceNumberFormatter(field.price_per_unit)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                control={control}
                                name={`item_list.${index}.price_per_unit`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.price_per_unit
                                )}
                                decimalScale={4}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[5]?.width || 0
                                : 0) + 16
                            }
                          >
                            {notEditStatus ? (
                              <Typography fontSize={14}>
                                {priceNumberFormatter(field.discount)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                control={control}
                                name={`item_list.${index}.discount`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.discount
                                )}
                                decimalScale={4}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[6]?.width || 0
                                : 0) + 16
                            }
                          >
                            {notEditStatus ? (
                              <Typography fontSize={14}>
                                {vatTypeFormatter(field.vat_percentage)}
                              </Typography>
                            ) : (
                              <ControlledSelect
                                control={control}
                                name={`item_list.${index}.vat_percentage`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.vat_percentage
                                )}
                                options={vatOptions}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[7]?.width || 0
                                : 0) + 16
                            }
                          >
                            <PreVatAmountCell
                              control={control}
                              index={index}
                              field={field}
                              disabled={notEditStatus}
                              setValue={setValue}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[9]?.width || 0
                                : 0) + 16
                            }
                          >
                            <Typography fontSize={14}>
                              {
                                field.reference_line_item
                                  ?.line_item_document_unique_id
                              }
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[9]?.width || 0
                                : 0) + 16
                            }
                          >
                            {notEditStatus ? (
                              <>
                                <Typography fontSize={14}>
                                  {numberFormatter(field.stock_qty)}
                                </Typography>
                                <Typography variant="caption" color="#BDBDBD">
                                  {field.uom_group?.base_uom?.name_th}
                                </Typography>
                              </>
                            ) : (
                              <ControlledTextField
                                sx={{ mt: 3 }}
                                type="number"
                                control={control}
                                name={`item_list.${index}.stock_qty`}
                                helperText={field.uom_group?.base_uom?.name_th}
                                InputProps={{
                                  inputProps: {
                                    style: { textAlign: "center" },
                                  },
                                }}
                                viewMode={true}
                              />
                            )}
                          </TableCell>
                          {finishedStatus && (
                            <TableCell
                              align="center"
                              sx={{
                                px: 1,
                                py: 1,
                              }}
                            >
                              <Typography fontSize={14}>
                                {numberFormatter(field.po_qty || 0)}
                              </Typography>
                            </TableCell>
                          )}
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[10]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {field.remark}
                              </Typography>
                            ) : (
                              <ControlledTextField
                                control={control}
                                name={`item_list.${index}.remark`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.remark
                                )}
                              />
                            )}
                          </TableCell>

                          {!notEditStatus && (
                            <TableCell
                              align="center"
                              sx={{
                                px: 1,
                                py: 1,
                              }}
                              width={
                                (headers && headers.length > 0
                                  ? headers[11]?.width || 0
                                  : 0) + 16
                              }
                            >
                              <IconButton
                                aria-label="delete"
                                size="small"
                                sx={{ ml: 1, color: "rgba(189, 189, 189, 1)" }}
                                onClick={() =>
                                  openDeleteItemConfirmationHandler(index)
                                }
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      {!notEditStatus && (
        <ButtonLayout sx={{ marginTop: 2 }}>
          <CustomizedButton
            title="เพิ่มสินค้า"
            variant="outlined"
            onClick={openItemModalHandler}
            addIcon
          />
          <CustomizedButton
            title="นำเข้าใบขอซื้อ"
            variant="outlined"
            onClick={openPurchaseRequestModalHandler}
            addIcon
          />
        </ButtonLayout>
      )}

      <Grid container maxWidth={984} spacing={0}>
        <Grid item xs={12} sm={12} md={5} />
        <Grid item xs={12} sm={12} md={7}>
          <SumPreVatAmount control={control} />
        </Grid>
      </Grid>
      <Confirmation
        title="ยืนยันการลบสินค้า"
        open={openDeleteItemConfirmation}
        handleClose={closeDeleteItemConfirmationHandler}
        action={deleteItemConfirmationAction}
      />
      <ItemTableModal
        ref={gridRef}
        itemModal={itemModal}
        closeItemModalHandler={closeItemModalHandler}
        addItemsHandler={addItemsHandler}
        purchasableItem
      />
      <CheckedPurchaseRequestModal
        showSelectItem={purchaseRequestModal}
        closeItemTable={closePurchaseRequestModalHandler}
        finishItemsSelect={finishPurchaseRequestSelect}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        idsSnapshot={idsSnapshot}
        setIdsSnapshot={setIdsSnapshot}
        rowSelection={"multiple"}
      />
    </CustomizedBox>
  );
};

export default PurchaseOrderitemList;
