import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import { Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import SalesByCustomerReportTable from "../../../components/Table/Sales/Report/SalesByCustomerReportTable";
import { useSalesReport } from "../../../hooks/Sales/use-sales-report";
import { SalesExportType } from "../../../generated/sales";
import { useStateContext } from "../../../contexts/auth-context";

const SalesByCustomerReport = () => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const gridRef = useRef<AgGridReact>(null);

  const { onBtnExport, isLoading } = useSalesReport(
    gridRef,
    SalesExportType.SalesByCustomer
  );

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("report"),
      to: "/sales/report",
    },
    {
      name: t("sales.report.sales_by_customer"),
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">
          {t("sales.report.sales_by_customer")}
        </Typography>
        {permissions?.sales_export?.view && (
          <CustomizedButton
            title={`${t("button.export")}`}
            variant="contained"
            onClick={onBtnExport}
            disabled={isLoading}
          />
        )}
      </HeaderLayout>
      <SalesByCustomerReportTable gridRef={gridRef} />
    </>
  );
};

export default SalesByCustomerReport;
