import { useState } from "react";
import { Control, Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
// import CustomizedTextField from "../Custom/CustomizedTextField";
import { TextField, TextFieldProps, InputAdornment } from "@mui/material";
import { StandardLonghandProperties } from "../../../node_modules/csstype/index";
import LabelInput from "../UI/LabelInput";

type ExtendedNumberTextFieldProps = {
  disabled?: TextFieldProps["disabled"];
  control: Control<any>;
  label?: TextFieldProps["label"];
  name: string;
  error?: TextFieldProps["error"];
  sx?: TextFieldProps["sx"];
  helperText?: TextFieldProps["helperText"];
  onChange?: (value: number | undefined) => void;
  onKeyDown?: TextFieldProps["onKeyDown"];
  onBlur?: TextFieldProps["onBlur"];
  textAlign?: StandardLonghandProperties["textAlign"];
  InputLabelProps?: TextFieldProps["InputLabelProps"];
  InputProps?: TextFieldProps["InputProps"];
  pricePerUnit?: number;
  helperTextAlign?: StandardLonghandProperties["textAlign"];
  fullWidth?: boolean;
  endAdornmentMessage?: string;
  viewMode?: boolean;
  decimalScale?: number;
  required?: boolean;
  disableDefaultOnChange?: boolean;
};

const ControlledNumberTextField = ({
  disabled,
  control,
  label,
  name,
  error,
  sx,
  onChange,
  helperText,
  pricePerUnit,
  onKeyDown,
  onBlur,
  textAlign,
  InputLabelProps,
  helperTextAlign,
  fullWidth,
  InputProps,
  endAdornmentMessage,
  viewMode,
  decimalScale,
  required,
  disableDefaultOnChange,
}: ExtendedNumberTextFieldProps) => {
  const [warn, setWarn] = useState(false);
  const showWarning = () => setWarn(true);
  const hideWarning = () => setWarn(false);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <>
          {viewMode ? (
            <LabelInput
              label={label}
              value={field.value}
              textAlign={textAlign || "left"}
              endAdornment={endAdornmentMessage}
              noDecimal={decimalScale === 0}
            />
          ) : (
            <NumericFormat
              fullWidth={fullWidth}
              size="small"
              label={label}
              value={field.value}
              allowNegative={true}
              thousandSeparator=","
              decimalScale={
                decimalScale === 0 ? 0 : decimalScale ? decimalScale : 2
              }
              onValueChange={(v) => {
                if (disableDefaultOnChange) {
                  return;
                }
                if (typeof v.floatValue === "undefined") {
                  field.onChange("");
                } else {
                  field.onChange(v.floatValue);
                }
                onChange && onChange(v.floatValue || 0);
                if (pricePerUnit) {
                  const value = v.floatValue || 0;
                  if (pricePerUnit > value) {
                    showWarning();
                  } else hideWarning();
                }
              }}
              disabled={disabled}
              customInput={TextField}
              helperText={helperText}
              error={error || warn}
              inputProps={{
                style: { textAlign: textAlign ? textAlign : "left" },
              }}
              InputProps={{
                ...InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {endAdornmentMessage}
                  </InputAdornment>
                ),
              }}
              InputLabelProps={InputLabelProps}
              FormHelperTextProps={{
                style: {
                  textAlign: helperTextAlign ? helperTextAlign : "left",
                },
              }}
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              sx={sx}
              fixedDecimalScale
              required={required}
            />
          )}
        </>
      )}
    />
  );
};

export default ControlledNumberTextField;
