import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

import {
  Box,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

import ControlledTextField from "../../../Controller/ControlledTextField";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import { useNavigate, useParams } from "react-router-dom";
import { useDisable } from "../../../../hooks/use-disable";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import CustomizedChips from "../../../Custom/CustomizedChips";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  SalesModelType,
  SalesUniqueIdGenerateQuery,
  useSalesUniqueIdGenerateQuery,
} from "../../../../generated/sales";
import { useCallback, useEffect } from "react";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import { IApprovalTemplate } from "../../../../types/global";
import { useModal } from "../../../../hooks/use-modal";
import ExternalReferenceModal from "../../../UI/Modal/ExternalReferenceModal";
import {
  IExternalProps,
  ISalesDeduction,
  ISalesPaymentChannel,
} from "../../../../types/Sales";
import { useConfirmation } from "../../../../hooks/use-confirmation";
import Confirmation from "../../../UI/Confirmation";
import { ISalesInvoice } from "../../../../types/Sales/salesInvoice";
import ApproverBox from "../../../UI/ApproverBox";
import { copySalesInvoiceFormatter } from "../../../../utils/Formatter/Sales/SalesInvoice";
import { useSalesInvoiceOption } from "../../../../hooks/Sales/SalesInvoice/use-sales-invoice-option";
import ControlledSwitch from "../../../Controller/ControlledSwitch";
import CustomizedSplitButton from "../../../Custom/CustomizedSplitButton";
import PaymentModal from "../../../UI/Modal/PaymentModal";
import DeductionModal from "../../../UI/Modal/DeductionModal";
import { useStateContext } from "../../../../contexts/auth-context";
import { useSalesInvoicePdfOption } from "../../../../hooks/Sales/SalesInvoice/use-sales-invoice-print-option";
import SalesPersonHeader from "../SalesPerson";

type Props = {
  editClickHandler: () => void;
  externalReferenceHandler: (data: IExternalProps) => void;
  cancelHandler: (data: ISalesInvoice) => Promise<void>;
  approverList: IApprovalTemplate[];
  approvalStep?: number | null;
  addPaymentHandler: (data: ISalesPaymentChannel) => void;
  addDeductionHandler: (data: ISalesDeduction) => void;
  isEdit: boolean;
};

const SalesInvoiceHeader = ({
  editClickHandler,
  externalReferenceHandler,
  cancelHandler,
  approverList,
  approvalStep,
  isEdit,
}: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<any>();
  const [disabled] = useDisable();
  const navigate = useNavigate();
  const {
    state: { authUser, permissions },
  } = useStateContext();

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const reference_document_list = useWatch({
    control,
    name: "reference_document_list",
  });

  const external_reference_no = useWatch({
    control,
    name: "external_reference_no",
  });

  const is_need_tax = useWatch({
    control,
    name: "need_tax_invoice",
  });

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { refetch: refetchUniqueId } =
    useSalesUniqueIdGenerateQuery<SalesUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: SalesModelType.SalesInvoice,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const selectModifyOptions = useSalesInvoiceOption(status);

  const printOptions = useSalesInvoicePdfOption(is_need_tax, status, id);

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const {
    modal: payment,
    openModalHandler: openPaymentModal,
    closeModalHandler: closePaymentModal,
  } = useModal();

  const {
    modal: deduction,
    openModalHandler: openDeductionModal,
    closeModalHandler: closeDeductionModal,
  } = useModal();

  const submitModalHandler = (data: IExternalProps) => {
    externalReferenceHandler(data);
    closeModalHandler();
  };

  const copyDocumentHandler = async () => {
    const salesInvoice = getValues();
    navigate("/sales/invoice/add", {
      state: await copySalesInvoiceFormatter(salesInvoice, authUser),
    });
  };

  const {
    confirmation: copyConfirmation,
    openConfirmationHandler: openCopyConfirmation,
    closeConfirmationHandler: closeCopyConfirmation,
    submitConfirmationHandler: submitCopyConfirmation,
  } = useConfirmation(copyDocumentHandler);

  const {
    confirmation: cancelConfirmation,
    openConfirmationHandler: openCancelConfirmation,
    closeConfirmationHandler: closeCancelConfirmation,
    submitConfirmationHandler: submitCancelConfirmation,
  } = useConfirmation(cancelHandler);

  return (
    <Box maxWidth={1040} mt={2}>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">{t("sales.invoice.index")}</Typography>
            <CustomizedStatus status={status} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1.5} justifyContent={"flex-end"}>
            <Grid item xs={5.5} sm={5.5} md={4} lg={4} xl={4}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"พิมพ์เอกสาร"}
                options={printOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case `พิมพ์${t("sales.invoice.index")}`:
                      navigate(`/sales/invoice/${id}/sales-invoice-pdf`);
                      break;
                    case `พิมพ์${t("sales.invoice.tax_invoice")}`:
                      navigate(`/sales/invoice/${id}/tax-invoice-pdf`);
                      break;
                    case `พิมพ์${t("sales.invoice.delivery_tax_invoice")}`:
                      navigate(`/sales/invoice/${id}/delivery-tax-invoice-pdf`);
                      break;
                    case `พิมพ์${t("sales.invoice.invoice_tax_invoice")}`:
                      navigate(
                        `/sales/invoice/${id}/sales-invoice-tax-invoice-pdf`
                      );
                      break;
                  }
                }}
                disabled={
                  !id || status === "cancelled" || status === "not_approved"
                }
              />
            </Grid>
            {selectModifyOptions.length > 0 && (
              <Grid item xs={5.5} sm={5.5} md={4} lg={4} xl={4}>
                <CustomizedMenuOptions
                  fullWidth
                  size="medium"
                  label={"ตัวเลือก"}
                  options={selectModifyOptions}
                  onSelect={(e) => {
                    const value = e.target as HTMLElement;
                    switch (value.innerText) {
                      case "แก้ไข":
                        editClickHandler();
                        break;
                      case "คัดลอก":
                        openCopyConfirmation();
                        break;
                      case "ยกเลิก":
                        openCancelConfirmation();
                        break;
                      case t("sales.external_reference_no"):
                        openModalHandler();
                        break;
                      default:
                        break;
                    }
                  }}
                  disabled={!id}
                />
              </Grid>
            )}
            {(status === "wait_payment" || status === "partially_payment") &&
              permissions?.sales_invoice?.update && (
                <Grid item xs={5.5} sm={5.5} md={4} lg={4} xl={4}>
                  <CustomizedSplitButton
                    options={["รับชำระ", "ตัดกับเอกสาร"]}
                    handleClick={(e) => {
                      const value = e.target as HTMLElement;
                      switch (value.innerText) {
                        case "รับชำระ":
                          openPaymentModal();
                          break;
                        case "ตัดกับเอกสาร":
                          openDeductionModal();
                          break;
                        default:
                          break;
                      }
                    }}
                    disabled={isEdit}
                  />
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1.5} alignItems="center">
            <Grid item xs={12} sm={12} md={4}>
              <Box display={"flex"} gap={0.5}>
                <Typography fontWeight="bold">
                  {t("sales.unique_id")}
                </Typography>
                <Typography color={"error.main"}>*</Typography>
              </Box>
            </Grid>
            <Grid item xs={11} sm={11} lg={7}>
              <ControlledTextField
                name="unique_id"
                control={control}
                placeholder={t("sales.unique_id")}
                error={Boolean(errors?.unique_id)}
                helperText={
                  errors?.unique_id && errors?.unique_id.message?.toString()
                }
                disabled={Boolean(id) && status && status !== "draft"}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={1}>
              {(!Boolean(id) ||
                (status &&
                  status === "draft" &&
                  permissions?.sales_invoice?.update)) && (
                <CustomizedTooltip
                  title="เรียกเลขที่เอกสารใหม่"
                  enterNextDelay={200}
                >
                  <IconButton
                    onClick={generateUniqueId}
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("sales.reference_document_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <Box mx={disabled ? 1.5 : 0}>
                {reference_document_list && reference_document_list.length > 0
                  ? reference_document_list.map((reference: any) => (
                      <CustomizedChips
                        key={`${reference.document_id}-${reference.document_unique_id}-${reference.document_type}`}
                        onClick={() => {
                          if (reference.document_type === "sales_order") {
                            window.open(
                              `/sales/order/${encodeURIComponent(
                                reference.document_id
                              )}`,
                              "_blank"
                            );
                          }
                        }}
                        value={reference.document_unique_id}
                        color="primary"
                      />
                    ))
                  : "-"}
              </Box>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight="bold">
                {t("sales.sentence.issuing_tax_invoices")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} lg={7}>
              <Box mx={disabled ? 1.5 : 0}>
                <ControlledSwitch
                  control={control}
                  name="need_tax_invoice"
                  label={t("sales.sentence.tax_invoices")}
                  disabled={
                    disabled ||
                    status === "wait_payment" ||
                    status === "partially_payment" ||
                    status === "fully_payment" ||
                    status === "finished" ||
                    status === "cancelled"
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={1} />
            {is_need_tax &&
              (status === "wait_payment" ||
                status === "partially_payment" ||
                status === "fully_payment" ||
                status === "finished" ||
                status === "cancelled") && (
                <>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box display={"flex"} gap={0.5}>
                      <Typography fontWeight="bold">
                        {t("sales.sentence.tax_invoice_no")}
                      </Typography>
                      <Typography color={"error.main"}>*</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={11} sm={11} lg={7}>
                    <ControlledTextField
                      name="tax_invoice_no"
                      control={control}
                      placeholder={t("sales.sentence.tax_invoice_no")}
                      error={Boolean(errors?.unique_id)}
                      helperText={
                        errors?.unique_id &&
                        errors?.unique_id.message?.toString()
                      }
                      disabled
                      viewMode={disabled}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  {/* <Grid item xs={1}>
                  <CustomizedTooltip
                    title="เรียกหมายเลขใบกำกับภาษีใหม่"
                    enterNextDelay={200}
                  >
                    <IconButton
                      onClick={generateUniqueId}
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                      }}
                    >
                      <RestartAltOutlinedIcon />
                    </IconButton>
                  </CustomizedTooltip>
                </Grid> */}
                </>
              )}
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("sales.reference_no")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <ControlledTextField
                name="reference_no"
                control={control}
                placeholder={t("sales.reference_no")}
                error={Boolean(errors?.reference_no)}
                helperText={
                  errors?.reference_no &&
                  errors?.reference_no.message?.toString()
                }
                disabled={disabled}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("sales.external_reference_no")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} lg={7}>
              <Typography mx={disabled ? 1.5 : 0}>
                {external_reference_no ? external_reference_no : "-"}
              </Typography>
            </Grid>
            <Grid item xs={1} />
            {id && status !== "draft" && approverList.length > 0 && (
              <>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography fontWeight={600}>
                    {t("sales.approval")}
                  </Typography>
                </Grid>
                <Grid item xs={11} sm={11} md={7}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      mt: 1,
                      whiteSpace: "nowrap",
                      flexWrap: "wrap",
                      mx: disabled ? 1 : 0,
                    }}
                  >
                    {approverList.map((approval, index) => {
                      const formatApproval = approval as IApprovalTemplate;
                      return (
                        <ApproverBox
                          key={index}
                          approval={formatApproval}
                          index={index}
                          approvalStep={approvalStep}
                        />
                      );
                    })}
                  </Box>
                </Grid>
                <Grid item xs={1} />
              </>
            )}
            <Grid item xs={12} sm={12} lg={4}>
              <Typography fontWeight={600}>
                {t("sales.employee_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} lg={7}>
              <Box mx={disabled ? 1.5 : 0}>
                <SalesPersonHeader />
              </Box>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} mt={isSmall ? 2 : 0}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs={6} sm={6} md={6} lg={4}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                label={t("date.created_date")}
                disabled={true}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={4}>
              <ControlledDatePicker
                name="issue_date"
                control={control}
                label={t("date.issue_date")}
                disabled={disabled}
                viewMode={disabled}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={1.5} justifyContent="flex-end" mt={0}>
            <Grid item xs={6} sm={6} md={6} lg={4}>
              <ControlledDatePicker
                name="due_date"
                control={control}
                label={t("date.due_date")}
                disabled={disabled}
                viewMode={disabled}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={1.5} justifyContent="flex-end" mt={0}>
            <Grid item xs={6} sm={6} md={3} lg={4}>
              <ControlledTextField
                type="number"
                label={t("sales.credit_day")}
                control={control}
                name="credit_day"
                onChange={(e, field) => {
                  const parseIntValue = parseInt(e.target.value);
                  field.onChange(parseIntValue);
                }}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                viewMode={disabled}
                noDecimal
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ExternalReferenceModal
        open={modal}
        handleClose={closeModalHandler}
        action={submitModalHandler}
      />
      <Confirmation
        title="ยกเลิกใบแจ้งหนี้"
        message="หากทำการยกเลิกเอกสารจะไม่สามารถย้อนกลับได้"
        open={cancelConfirmation}
        handleClose={closeCancelConfirmation}
        action={submitCancelConfirmation}
      />
      <Confirmation
        title="ยืนยันหากต้องการคัดลอกเอกสาร"
        open={copyConfirmation}
        handleClose={closeCopyConfirmation}
        action={submitCopyConfirmation}
      />
      <PaymentModal open={payment} closeModalHandler={closePaymentModal} />
      <DeductionModal
        open={deduction}
        closeModalHandler={closeDeductionModal}
      />
    </Box>
  );
};

export default SalesInvoiceHeader;
