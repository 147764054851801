import {
  Avatar,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  FieldArrayWithId,
  UseFieldArrayRemove,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { Tracability } from "../../../../generated/inventory";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { Fragment, useState } from "react";
import { IGoodsReturn } from "../../../../types/Inventory/GoodsReturn";
import { useGoodsReturnItemList } from "../../../../hooks/Inventory/use-return-item-list";
import { useDisable } from "../../../../hooks/use-disable";
import TotalPostedQuantityCell from "./TotalPostedQuantityCell";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import Confirmation from "../../../UI/Confirmation";
import { useConfirmation } from "../../../../hooks/use-confirmation";
import ClearIcon from "@mui/icons-material/Clear";
import { useParams } from "react-router-dom";
import { useStateContext } from "../../../../contexts/auth-context";

type Props = {
  fields: FieldArrayWithId<IGoodsReturn, "trace_entry_list", "id">[];
  remove: UseFieldArrayRemove;
};

const GoodsReturnItemList = ({ fields, remove }: Props) => {
  const { control } = useFormContext<IGoodsReturn>();
  const [disabled] = useDisable();
  const { id } = useParams();
  const {
    state: { permissions },
  } = useStateContext();
  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const [deletedIndex, setDeletedIndex] = useState<number>();

  const deleteItemHandler = () => {
    remove(deletedIndex);
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(deleteItemHandler);

  const headers = useGoodsReturnItemList(status || "", disabled);
  const uneditable = Boolean(id) && !permissions?.goods_return?.update;

  return (
    <CustomizedBox maxWidth={1650} margin={0}>
      <Typography color="primary.main" fontWeight={600} mb={2}>
        รายการสินค้า
      </Typography>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align={header.align ? header.align : "center"}
                  key={index}
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={header.width}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    width={
                      !status || status === "draft" ? undefined : header.width
                    }
                    color={"primary.main"}
                  >
                    {header.thaiLabel}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((trace, index) => (
              <Fragment key={trace.id}>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[0]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>{index + 1}</Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[1]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        minWidth: "90px",
                        justifyContent: "center",
                      }}
                    >
                      <Avatar
                        alt={trace.item_name}
                        src={trace?.item_img_url ? trace.item_img_url[0] : ""}
                        sx={{
                          width: 60,
                          height: 60,
                          border: "1px solid #BEBEBE",
                          borderRadius: "2px",
                        }}
                        variant="square"
                      >
                        <ImageOutlinedIcon
                          sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                          fontSize="medium"
                        />
                      </Avatar>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[2]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {trace.item_unique_id}
                    </Typography>
                    <Typography fontSize={14}>{trace.item_name}</Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[3]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {trace.document_item_qty || 0}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[4]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {trace.posted_qty || 0}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[5]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {(trace.document_item_qty || 0) - (trace.posted_qty || 0)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[6]?.width || 0
                        : 0) + 16
                    }
                  >
                    {trace.tracability === Tracability.Normal ? (
                      <Typography fontSize={14}>{trace.qty}</Typography>
                    ) : (
                      <TotalPostedQuantityCell nestedIndex={index} />
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[7]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>{trace.uom?.name_th}</Typography>
                  </TableCell>
                  {!disabled && !uneditable && (
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[8]?.width || 0
                          : 0) + 16
                      }
                    >
                      <IconButton
                        onClick={() => {
                          openConfirmationHandler();
                          setDeletedIndex(index);
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Confirmation
        title="ยืนยันหากต้องการลบสินค้า"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </CustomizedBox>
  );
};

export default GoodsReturnItemList;
