import * as Yup from "yup";

export const itemListSchema = Yup.object().shape({
  item_name: Yup.string().required("กรุณาระบุชื่อสินค้า"),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุจำนวนที่มากกว่า 0")
    .required("กรุณาระบุจำนวนสินค้า"),
  discount: Yup.number().transform((value) =>
    isNaN(value) || value === null || value === undefined ? 0 : value
  ),
  uom_id: Yup.number().required("กรุณาเลือกหน่วย"),
  price_per_unit: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุราคาต่อหน่วยที่มากกว่า 0")
    .required("กรุณาระบุราคาต่อหน่วย")
    .test(
      "price_per_unit_below_fixed_price",
      function (value, { parent }) {
        const promotion_discount = parent.promotion_discount || [];
        if (promotion_discount < 0) {
          return this.createError({
            message: `ราคา/หน่วย ของ ${parent.item_unique_id} ต่ำกว่าราคาในโปรโมชันที่กำหนด กรุณาตรวจสอบ`,
          });
        }
        return true;
      }
    ),
  promotion_discount: Yup.number().transform((value) =>
    isNaN(value) || value === null || value === undefined || "" ? 0 : value
  ),
});

export const itemListPRSchema = Yup.object().shape({
  item_name: Yup.string().required("กรุณาระบุชื่อสินค้า"),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุจำนวนที่มากกว่า 0")
    .required("กรุณาระบุจำนวนสินค้า"),
  uom_id: Yup.number().required("กรุณาเลือกหน่วย"),
});
