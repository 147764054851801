import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

import { useDisable } from "../../../../hooks/use-disable";
import { useStateContext } from "../../../../contexts/auth-context";

import { Avatar, Box, Grid, Typography } from "@mui/material";

import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedLetterAvatar from "../../../Custom/CustomizedLetterAvatar";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";

import { IGoodsIssue } from "../../../../types/Inventory/GoodsIssue";

import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import ApproverBox from "../../../UI/ApproverBox";
import { IApprovalTemplate } from "../../../../types/global";
import { printStatusWithApproval } from "../../../../utils/Constant";

type Props = {
  approverList: IApprovalTemplate[];
  approvalStep?: number | null;
};

const GoodsIssueHeader = ({ approverList, approvalStep }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    state: { authUser, permissions },
  } = useStateContext();

  const { control, setValue, getValues } = useFormContext<IGoodsIssue>();

  const [disabled] = useDisable();

  const status = useWatch({ control, name: "aggrid_status" });

  const watchCreatedBy = useWatch({
    control,
    name: "created_by",
  });

  const uneditable = Boolean(id) && !permissions?.goods_issue?.update;

  useEffect(() => {
    if (!id && authUser) {
      setValue("created_by", {
        user_unique_id: authUser?.unique_id,
        email: authUser?.email,
        first_name: authUser?.first_name,
        last_name: authUser?.last_name,
        img_url: authUser?.img_url,
      });
    }
  }, [authUser, id, setValue]);

  return (
    <>
      <Box maxWidth={1040} mt={2}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={12} md={6}>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Typography variant="h5">
                {id
                  ? getValues("unique_id")
                  : `${t("button.create")}${t("inventory.goods_issue.index")}`}
              </Typography>
              <CustomizedStatus status={status} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={1.5}>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                <CustomizedMenuOptions
                  fullWidth
                  size="medium"
                  label={t("button.print_document")}
                  options={[{ value: t("button.print") }]}
                  onSelect={(e) => {
                    const value = e.target as HTMLElement;
                    switch (value.innerText) {
                      case `${t("button.print")}`:
                        navigate("pdf");
                        break;
                      default:
                    }
                  }}
                  disabled={!id || !printStatusWithApproval.includes(status)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                <ControlledDatePicker
                  name="created_date"
                  control={control}
                  label={t("date.created_date")}
                  viewMode={uneditable}
                  disabled
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                <ControlledDatePicker
                  name="posted_date"
                  control={control}
                  label={t("date.issued_date")}
                  disabled={disabled}
                  viewMode={uneditable}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center", mt: 1 }}>
          <Typography fontWeight="bold">{t("sentence.created_by")}</Typography>
          <CustomizedTooltip
            title={`${watchCreatedBy?.first_name} ${watchCreatedBy?.last_name}`}
            enterNextDelay={200}
          >
            {watchCreatedBy?.img_url && watchCreatedBy?.img_url.length > 0 ? (
              <Avatar
                alt={`avatar`}
                src={watchCreatedBy?.img_url ? watchCreatedBy?.img_url[0] : ""}
              />
            ) : (
              <CustomizedLetterAvatar
                name={`${watchCreatedBy?.first_name} ${watchCreatedBy?.last_name}`}
              />
            )}
          </CustomizedTooltip>
        </Box>
      </Box>
      <Box maxWidth={1650} mb={2}>
        {id && status !== "draft" && approverList.length > 0 && (
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              mt: 1,
              whiteSpace: "nowrap",
              flexWrap: "wrap",
            }}
          >
            <Typography fontWeight={"bold"}>ผู้อนุมัติ</Typography>
            {approverList.map((approval, index) => {
              const formatApproval = approval as IApprovalTemplate;
              return (
                <ApproverBox
                  key={index}
                  approval={formatApproval}
                  index={index}
                  approvalStep={approvalStep}
                />
              );
            })}
          </Box>
        )}
      </Box>
    </>
  );
};

export default GoodsIssueHeader;
