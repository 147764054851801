import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import CustomizedTab from "../../../components/Custom/CustomizedTab";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";

import { IBreadcrumbsAndMenu, ITab, IMenuOption } from "../../../types/global";
import CashSalesTable from "../../../components/Table/Sales/CashSales";

import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import ExporterSalesModal from "../../../components/UI/Modal/ExporterSalesModal";
import { useModal } from "../../../hooks/use-modal";
import { SalesExportType } from "../../../generated/sales";
import { useStateContext } from "../../../contexts/auth-context";

const CashSales = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const {
    state: { permissions },
  } = useStateContext();

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.cash_sales.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const options: IMenuOption[] = [
    {
      value: t("sales.export"),
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
        <Typography variant="h5">{t("sales.cash_sales.index")}</Typography>
        <Box display="flex" gap={1}>
          {permissions?.cash_sales?.create && (
            <CustomizedButton
              title={`${t("button.create")}${t("sales.cash_sales.index")}`}
              variant="contained"
              onClick={() => navigate(`${pathname}/add`)}
              addIcon
            />
          )}
          {permissions?.sales_export?.view && (
            <CustomizedMenuOptions
              isIcon
              options={options}
              onSelect={(e) => {
                const value = e.target as HTMLElement;
                switch (value.innerText) {
                  case `${t("sales.export")}`:
                    openModalHandler();
                    break;
                  default:
                }
              }}
            />
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      </Box>
      <CashSalesTable isFilter={isFilter} />{" "}
      <ExporterSalesModal
        open={modal}
        closeModalHandler={closeModalHandler}
        documentType={SalesExportType.CashSales}
      />
    </>
  );
};

export default CashSales;
