import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

type Props = {
  documentType: string;
};

export const usePromotionApply = ({ documentType }: Props) => {
  const { getValues, setValue } = useFormContext();

  const recalculatePromotionDiscounts = useCallback(() => {
    if (documentType !== "cash_sales") {
      return;
    }
    const itemList = getValues("item_list") as any[];
    const promotionList = getValues("promotion_list") as any[];

    const updatedItemPromotionDiscounts = Array(itemList?.length).fill(0.0);
    const updatedTotalDiscountAmounts = Array(promotionList?.length).fill(0.0);

    itemList?.forEach((item, itemIndex) => {
      promotionList?.forEach((promotion, promotionIndex) => {
        let recalculatedPromotionDiscount = 0;
        let promotionDiscountPerUnit = 0;
        const qty = item.qty ?? 0;

        const redeemItem = promotion.redeem_item_list?.find(
          (redeemItem: any) => redeemItem.item_id === item.item_id
        );
        const maxRedeemQty = redeemItem ? redeemItem.qty : qty;

        const effectiveQty = Math.min(qty, maxRedeemQty) ?? 0;

        if (redeemItem) {
          if (promotion.receive_type === "percent") {
            const price_per_unit = item.price_per_unit ?? 0;
            const discount = item.discount ?? 0;
            const percentage_discount_rate = promotion.discount_rate / 100;

            promotionDiscountPerUnit =
              (price_per_unit - discount) * percentage_discount_rate;

            recalculatedPromotionDiscount =
              (effectiveQty * price_per_unit - discount) *
              percentage_discount_rate;

            if (recalculatedPromotionDiscount < 0) {
              recalculatedPromotionDiscount = 0;
            }
          } else if (promotion.receive_type === "amount") {
            const price_per_unit = item.price_per_unit ?? 0;
            const fixed_price = redeemItem.fixed_price ?? null;
            if (fixed_price !== null) {
              recalculatedPromotionDiscount =
                (price_per_unit - fixed_price) * effectiveQty;

            } else {
              const discount_rate =
                typeof promotion.discount_rate === "number"
                  ? promotion.discount_rate
                  : parseFloat(promotion.discount_rate) || 0;

              promotionDiscountPerUnit = discount_rate;
              recalculatedPromotionDiscount = discount_rate * effectiveQty;
            }
          }
        }

        // Update the discounts regardless of eligibility
        updatedItemPromotionDiscounts[itemIndex] += recalculatedPromotionDiscount;
        updatedTotalDiscountAmounts[promotionIndex] +=
          recalculatedPromotionDiscount;
      });
    });

    updatedItemPromotionDiscounts.forEach((promotionDiscount, index) => {
      setValue(
        `item_list.${index}.promotion_discount`,
        promotionDiscount ?? 0.0
      );
    });

    updatedTotalDiscountAmounts.forEach((totalDiscount, promotionIndex) => {
      setValue(
        `promotion_list.${promotionIndex}.total_discount_amount`,
        totalDiscount ?? 0.0
      );
    });
  }, [getValues, setValue, documentType]);

  return { recalculatePromotionDiscounts };
};
