import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from "@mui/material";
import { useSalesPromotionHeader } from "./coulumnDefs";
import { useDisable } from "../../../../hooks/use-disable";
import { numberFormatter } from "../../../../utils/Formatter/Global";
import Confirmation from "../../../UI/Confirmation";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

type Props = {
  fields: any[];
  remove: (index: number) => void;
};

const SalesPromotionTable = ({ fields, remove }: Props) => {
  const [disabled] = useDisable();
  const headers = useSalesPromotionHeader(disabled);
  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState<boolean>(false);
  const [indexToDelete, setIndexToDelete] = useState<number | undefined>(
    undefined
  );

  const openDeleteCouponConfirmationHandler = (index: number) => {
    setOpenDeleteItemConfirmation(true);
    setIndexToDelete(index);
  };

  const closeDeleteCouponConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setIndexToDelete(undefined);
  };

  const deleteCouponConfirmationAction = () => {
    if (indexToDelete !== undefined) {
      remove(indexToDelete);
    }
    closeDeleteCouponConfirmationHandler();
  };

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 650, overflow: "scroll" }}
        aria-label="simple table"
      >
        <TableHead sx={{ bgcolor: "primary.light" }}>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell
                align={header.align}
                key={index}
                sx={{
                  px: 1,
                  py: 1,
                }}
                width={header.width}
              >
                <Typography fontWeight={600}>{header.thaiLabel}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((field, index) => (
            <TableRow key={index}>
              <TableCell
                align="center"
                sx={{
                  px: 1,
                  py: 1,
                }}
              >
                <Typography>{index + 1}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize={14} fontWeight={600} color="primary.main">
                  {field.promotion_unique_id}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize={14}>{field.name}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize={14}>{field.coupon_code}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography fontSize={14}>
                  {field.discount_rate
                    ? `${numberFormatter(field.discount_rate)}${
                        field.receive_type === "percent" ? " %" : ""
                      }`
                    : "-"}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography fontSize={14}>
                  {numberFormatter(field.total_discount_amount)}
                </Typography>
              </TableCell>
              <TableCell align="left">
                {field.redeem_item_list?.length > 0 ? (
                  <>
                    {field.redeem_item_list?.[0]?.fixed_price != null ? (
                      <Typography fontSize={14}>{"-"}</Typography>
                    ) : (
                      <>
                        <Typography fontSize={14}>
                          {field.redeem_item_list?.[0]?.item_name}
                        </Typography>
                        <Typography fontSize={14}>
                          {field.redeem_item_list?.[0]?.item_unique_id}
                        </Typography>
                      </>
                    )}
                  </>
                ) : (
                  <Typography fontSize={14}>{"-"}</Typography>
                )}
              </TableCell>
              <TableCell align="right">
                {field.redeem_item_list?.length > 0 ? (
                  <>
                    <Typography fontSize={14}>
                      {field.redeem_item_list?.[0]?.qty}
                    </Typography>
                    <Typography fontSize={12} color={"#737373"}>
                      {field.redeem_item_list?.[0]?.unit}
                    </Typography>
                  </>
                ) : (
                  <Typography fontSize={14}>{"-"}</Typography>
                )}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  px: 1,
                  py: 1,
                }}
              >
                {!disabled && (
                  <IconButton
                    aria-label="delete"
                    size="small"
                    sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                    onClick={() => openDeleteCouponConfirmationHandler(index)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Confirmation
        title="ยืนยันการลบโปรโมชัน"
        open={openDeleteItemConfirmation}
        handleClose={closeDeleteCouponConfirmationHandler}
        action={deleteCouponConfirmationAction}
      />
    </TableContainer>
  );
};

export default SalesPromotionTable;
