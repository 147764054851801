import { useNavigate, useParams } from "react-router-dom";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  CashSalesFindUniqueQuery,
  SalesReferenceDocumentType,
  useCashSalesFindUniqueQuery,
  useSalesOriginalPrintMutation,
} from "../../../generated/sales";
// import { useSalesItemListFormatter } from "../../../hooks/Sales/use-item-list-formatter";
import { IPdfInfo, ISelectOption } from "../../../types/global";
import { ISalesItemList } from "../../../types/Sales";
import { formatDate } from "../../../utils/Date";
import { renderAddressString } from "../../../utils/Formatter/Global";
import { Box, CircularProgress, Typography } from "@mui/material";
import SalesItemListPdfTable from "../../../components/Table/Pdf/SalesItemList";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { usePDFMultiplePage } from "../../../hooks/use-pdf-multiple-page";
import { useReactToPrint } from "react-to-print";
import { ReactInstance, useEffect, useRef, useState } from "react";
import SalesPdfLayout from "../../../components/UI/SalesPdf/SalesPdfLayout";
import { IContactPerson } from "../../../types/Contact/contact";
import { formatNumber } from "../../../utils/Global";
import { useTranslation } from "react-i18next";
import CustomizedSelect from "../../../components/Custom/CustomizedSelect";
import { useConfirmation } from "../../../hooks/use-confirmation";
import PrintModal from "../../../components/UI/Modal/PrintModal";
import { useStateContext } from "../../../contexts/auth-context";

type Props = {
  name: string;
  variableName: string;
};

const CashSalesPdf = ({ name, variableName }: Props) => {
  const { id } = useParams();
  const componentRef = useRef<ReactInstance | null>(null);
  const { t } = useTranslation();

  const [printType, setPrintType] = useState<"original" | "copy">("copy");

  const graphQLClient = createGraphQLClientWithMiddleware("sales");
  const { data, isLoading, refetch, isSuccess } =
    useCashSalesFindUniqueQuery<CashSalesFindUniqueQuery>(graphQLClient, {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    });

  const navigate = useNavigate();

  const {
    state: { authUser, permissions },
  } = useStateContext();

  useEffect(() => {
    if (isSuccess && permissions?.cash_sales?.view_self) {
      const createdBy = data?.CashSalesFindUnique?.created_by;
      const relatedUserList = data?.CashSalesFindUnique?.related_user_list;
      if (
        createdBy?.user_unique_id === authUser?.unique_id ||
        relatedUserList?.find(
          (user) => user.user_unique_id === authUser?.unique_id
        )
      ) {
        return;
      } else {
        navigate(`/unauthorized`, {
          replace: true,
        });
      }
    }
  }, [
    authUser?.unique_id,
    data?.CashSalesFindUnique?.created_by,
    data?.CashSalesFindUnique?.related_user_list,
    isSuccess,
    navigate,
    permissions?.cash_sales?.view_self,
  ]);

  const { mutateAsync } = useSalesOriginalPrintMutation<Error>(graphQLClient);

  const { paginatedItems, startNumbers } = usePDFMultiplePage(
    data?.CashSalesFindUnique, //data
    "cash_sales", // document type
    141, // name and description row width
    700, // max height without footer
    500, // max height with footer
    226.484, // left header minimum height
    226.484, // right header minimum height
    undefined, // left footer minimum height (you don't need to specify this if the document doesn't have remark and tags)
    undefined // right footer minimum height (you don't need to specify this if the document doesn't have summary)
  );

  const printTypeOption: ISelectOption[] = [
    {
      label: t(`print.original`),
      value: "original",
      disabled:
        data?.CashSalesFindUnique.is_original_print &&
        data?.CashSalesFindUnique.is_original_print[variableName],
    },
    {
      label: t(`print.copy`),
      value: "copy",
    },
  ];

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: async () => {
      if (printType === "original") {
        await mutateAsync({
          input: {
            document_id: id ? parseInt(id) : 0,
            document_type: SalesReferenceDocumentType.CashSales,
            key: variableName,
          },
        });
        await refetch();
        setPrintType("copy");
      }
    },
  });

  const taxInvoice = data?.CashSalesFindUnique.tax_invoice_no;

  const highlightSection: IPdfInfo = {
    headers: [
      "เลขที่เอกสาร",
      ...(taxInvoice ? ["เลขที่ใบกำกับภาษี"] : []),
      "วันที่ออกเอกสาร",
      // "ใช้ได้ถึง",
    ],
    info: [
      data?.CashSalesFindUnique.unique_id || "-",
      ...(taxInvoice ? [taxInvoice] : []),
      data?.CashSalesFindUnique.issue_date
        ? formatDate(data?.CashSalesFindUnique.issue_date)
        : "-",
      // data?.CashSalesFindUnique.due_date
      //   ? formatDate(data?.CashSalesFindUnique.due_date)
      //   : "-",
    ],
  };

  const identityNoBranch = data?.CashSalesFindUnique.customer_details
    .identity_no
    ? // &&
      // data?.CashSalesFindUnique.branch
      // ? `${data?.CashSalesFindUnique.customer_details.identity_no} (${data?.CashSalesFindUnique.branch.unique_id})`
      `${data?.CashSalesFindUnique.customer_details.identity_no}`
    : "-";

  const leftSection: IPdfInfo = {
    headers: [
      "ลูกค้า",
      "ที่อยู่",
      "ที่อยู่จัดส่งสินค้า",
      "เลขประจำตัวผู้เสียภาษี",
      "เบอร์โทรศัพท์@โทรสาร",
      "อีเมล",
    ],
    info: [
      `${data?.CashSalesFindUnique.customer_unique_id} ${data?.CashSalesFindUnique.customer_details.name}`,
      renderAddressString(
        data?.CashSalesFindUnique.customer_details.billing_address
      ),
      renderAddressString(
        data?.CashSalesFindUnique.customer_details.delivery_address
      ),
      identityNoBranch,
      `${data?.CashSalesFindUnique.customer_details.phone || "-"}@${
        data?.CashSalesFindUnique.customer_details.fax || "-"
      }`,
      data?.CashSalesFindUnique.customer_details.email || "-",
    ],
  };

  const salesPersonFirstName =
    (data?.CashSalesFindUnique.related_user_list &&
      data?.CashSalesFindUnique.related_user_list.length > 0 &&
      data.CashSalesFindUnique.related_user_list[0].first_name) ||
    "-";

  const salesPersonLastname =
    (data?.CashSalesFindUnique.related_user_list &&
      data?.CashSalesFindUnique.related_user_list.length > 0 &&
      data.CashSalesFindUnique.related_user_list[0].last_name) ||
    "";
  const salePerson = salesPersonFirstName + " " + salesPersonLastname;

  const contactPerson: IContactPerson =
    data?.CashSalesFindUnique.contact_person_list &&
    data?.CashSalesFindUnique.contact_person_list.length > 0 &&
    data?.CashSalesFindUnique.contact_person_list[0];

  const contactPersonName = contactPerson
    ? `${contactPerson.title_name}${contactPerson.first_name} ${contactPerson.last_name}`
    : "-";

  const contactPersonPhone = contactPerson
    ? contactPerson.contact_channel_list.find(
        (channel) => channel.contact_channel_type === "phone"
      )?.contact_channel_info || "-"
    : "-";

  const rightSection: IPdfInfo = {
    headers: [
      "อ้างอิง",
      "พนักงานขาย",
      // "เครดิต (วัน)",
      "หมายเลขอ้างอิง",
      "ผู้ติดต่อ",
      "เบอร์โทรศัพท์",
    ],
    info: [
      data?.CashSalesFindUnique.reference_document_list
        ?.map((doc) => doc.document_unique_id)
        .join(", ") || "-",
      salePerson || "-",
      // `${data?.CashSalesFindUnique.credit_day?.toString()} วัน` || "-",
      data?.CashSalesFindUnique.reference_no || "-",
      contactPersonName,
      contactPersonPhone,
    ],
  };

  const payment =
    data?.CashSalesFindUnique.payment_list &&
    data?.CashSalesFindUnique.payment_list.length > 0
      ? data.CashSalesFindUnique.payment_list[0]
      : undefined;

  const paymentChannelName = payment?.payment_channel_unique_id
    ? payment?.payment_channel_unique_id + " - " + payment?.payment_channel_name
    : "-";

  const leftPaymentSection: IPdfInfo = {
    headers: [
      "การรับชำระ",
      "ชำระโดย",
      "ชื่อบัญชี",
      "จำนวนเงิน",
      "มูลค่าถูกหัก ณ ที่จ่าย",
    ],
    info: [
      payment?.payment_date ? formatDate(payment.payment_date) : "-",
      payment?.payment_channel_payment_name || "-",
      paymentChannelName,
      payment?.payment_amount
        ? formatNumber(payment.payment_amount) + " บาท"
        : "-",
      payment?.withholding_tax_amount
        ? formatNumber(payment.withholding_tax_amount) + " บาท"
        : "-",
    ],
  };

  const rightPaymentSection: IPdfInfo = {
    headers: ["วันที่เช็ค", "ธนาคารผู้ออกเช็ค", "เลขที่เช็ค", "หมายเหตุ"],
    info: [
      payment?.cheque_date ? formatDate(payment.cheque_date) : "-",
      payment?.cheque_provider || "-",
      payment?.cheque_no || "-",
      payment?.remark || "-",
    ],
  };

  const needTaxInvoice = data?.CashSalesFindUnique.need_tax_invoice;

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(() => handlePrint());

  if (id && isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  const allPages = paginatedItems.length;

  return (
    <>
      <Box maxWidth={1650}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Box>
            <Typography variant="h5">{name}</Typography>
            <Typography>{`เลขที่เอกสาร ${data?.CashSalesFindUnique.unique_id}`}</Typography>
          </Box>

          <Box display={"flex"} gap={1}>
            {needTaxInvoice && (
              <Box>
                <CustomizedSelect
                  sx={{ width: 120 }}
                  label={t("print.type")}
                  options={printTypeOption}
                  onChange={(e) => {
                    const value = e.target.value as "copy" | "original";
                    setPrintType(value);
                  }}
                  value={printType}
                />
              </Box>
            )}
            <CustomizedButton
              fullWidth
              title="พิมพ์เอกสาร"
              variant="outlined"
              onClick={
                printType === "original" ? openConfirmationHandler : handlePrint
              }
            />
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Box ref={componentRef} sx={{ m: "auto" }}>
            {paginatedItems.map((item, index) => {
              return (
                <SalesPdfLayout
                  printType={needTaxInvoice ? printType : undefined}
                  highlightSection={highlightSection}
                  leftSection={leftSection}
                  rightSection={rightSection}
                  leftPaymentSection={leftPaymentSection}
                  rightPaymentSection={rightPaymentSection}
                  name={`${name}`}
                  multiplePage={paginatedItems.length > 1}
                  page={index + 1}
                  allPages={allPages}
                  data={data?.CashSalesFindUnique}
                  summary={index === paginatedItems.length - 1}
                  salePerson={salePerson}
                  totalAmount={
                    data?.CashSalesFindUnique.total_amount
                      ? data?.CashSalesFindUnique.total_amount
                      : 0
                  }
                  documentType="cash_sales"
                  hideAdditionalDiscount={false}
                  showPaymentSection={true}
                  //isPrinting={isPrinting}
                >
                  <SalesItemListPdfTable
                    itemList={(item as ISalesItemList[]) || []}
                    startNumber={startNumbers[index]}
                    documentType="cash_sales"
                    isLastPage={index === paginatedItems.length - 1}
                    //multiplePage={paginatedItems.length > 1}
                    lastTable={
                      paginatedItems[paginatedItems.length - 1].length === 0
                        ? index === paginatedItems.length - 2
                        : index === paginatedItems.length - 1
                    }
                    totalAmount={
                      data?.CashSalesFindUnique.total_amount
                        ? data?.CashSalesFindUnique.total_amount
                        : 0
                    }
                    hasItems={item.length > 0}
                  />
                </SalesPdfLayout>
              );
            })}
          </Box>
        </Box>
      </Box>
      <PrintModal
        open={confirmation}
        closeModalHandler={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default CashSalesPdf;
