import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import { Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CurrentStockByBranchReportTable from "../../../components/Table/Inventory/Report/CurrentStockByBranchReportTable";
import { useInventoryReport } from "../../../hooks/Inventory/use-inventory-report";
import { InventoryReportType } from "../../../generated/inventory";
import { useStateContext } from "../../../contexts/auth-context";

const CurrentStockByBranchReport = () => {
  const { t } = useTranslation();

  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("report"),
      to: "/inventory/report",
    },
    {
      name: "จำนวน" + t("inventory.itemCurrentStockBranch.index"),
    },
  ];

  const gridRef = useRef<AgGridReact>(null);

  const { onBtnExport, isExporting, isFetching } = useInventoryReport(
    gridRef,
    InventoryReportType.CurrentStockByBranch
  );

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">
          จำนวน{t("inventory.itemCurrentStockBranch.index")}
        </Typography>
        {permissions?.inventory_export.view && (
          <CustomizedButton
            title={`${t("button.export")}`}
            variant="contained"
            onClick={onBtnExport}
            disabled={isExporting && isFetching}
          />
        )}
      </HeaderLayout>
      <CurrentStockByBranchReportTable gridRef={gridRef} />
    </>
  );
};

export default CurrentStockByBranchReport;
