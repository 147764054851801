import { useCallback, useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../../types/global";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../../../contexts/auth-context";

export const usePurchaseReturnOption = (
  status?: string | null,
  disabled?: boolean
) => {
  const {
    state: { permissions },
  } = useStateContext();

  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: "แก้ไข",
        disabled: !disabled,
      },
      {
        value: "ยกเลิก",
        disabled: false,
      },
    ],
    [disabled]
  );

  const cancelOption = defaultOptions[1];

  const canUpdateAndCancel = useCallback(() => {
    return (
      permissions?.purchase_return?.update &&
      permissions?.purchase_return?.cancel
    );
  }, [permissions]);

  // Helper function to get options based on permissions and status
  const getOptionsForStatus = useCallback(
    (status?: string | null) => {
      if (["draft", "not_approved"].includes(status || "")) {
        return canUpdateAndCancel() ? [cancelOption] : [];
      }

      // Default case when no specific status matches
      return canUpdateAndCancel() ? defaultOptions : [];
    },
    [canUpdateAndCancel, cancelOption, defaultOptions]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    const options = getOptionsForStatus(status);
    setSelectedModifyOptions(options);
  }, [status, getOptionsForStatus]);

  return selectModifyOptions;
};

export const usePurchaseReturnCreate = (
  status?: string | null,
  disabledCreate?: boolean
) => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      ...(permissions?.goods_issue?.create
        ? [
            {
              value: "ใบ" + t("inventory.goods_issue.index"),
              disabled: disabledCreate,
            },
          ]
        : []),
    ],
    [disabledCreate, permissions?.goods_issue?.create, t]
  );

  const [disabled, setDisabled] = useState<boolean>(false);
  useEffect(() => {
    switch (status) {
      case "accepted":
      case "approved":
      case "finished":
        setDisabled(false);
        break;
      default:
        setDisabled(true);
    }
  }, [status]);
  return {
    selectCreateOptions: defaultOptions,
    disabledCreateOptions: disabled,
  };
};
