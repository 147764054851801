import { Fragment, useState } from "react";
import { useSnackbar } from "notistack";
import {
  useForm,
  useFormContext,
  useWatch,
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  UseFieldArrayReplace,
} from "react-hook-form";
import { useStateContext } from "../../../../contexts/auth-context";
import { useTranslation } from "react-i18next";

import { useModal } from "../../../../hooks/use-modal";
import { useDisable } from "../../../../hooks/use-disable";
import { useGoodsReceiveItemList } from "../../../../hooks/Inventory/use-receive-item-list";
import useInventoryControl from "../../../../hooks/Inventory/use-inventory-control";

import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

import {
  InventoryDocumentType,
  Tracability,
} from "../../../../generated/inventory";
import {
  Box,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  IconButton,
} from "@mui/material";

import SerialList from "./SerialList";
import TotalPostedQuantityCell from "./TotalPostedQuantityCell";

import SerialModal from "../../../UI/Modal/SerialModal";
import CheckedItemModal from "../../../UI/Modal/CheckedItemModal";

import ControlledSelect from "../../../Controller/ControlledSelect";
import ControlledTextField from "../../../Controller/ControlledTextField";

import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CustomizedButton from "../../../Custom/CustomizedButton";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
// import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";

import ClearIcon from "@mui/icons-material/Clear";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import {
  IGoodsReceive,
  ISerialForm,
} from "../../../../types/Inventory/GoodsReceive";
import { IBarcodeForm, ITraceEntry } from "../../../../types/Inventory";

import { formatDateTime } from "../../../../utils/Date";
import { checkIsNotDraftOrEmptyStatus } from "../../../../utils/Global";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import { useParams } from "react-router-dom";

// const options: IMenuOption[] = [
//   {
//     value: "นำออก Excel",
//     disabled: false,
//   },
//   {
//     value: "นำออก CSV",
//     disabled: false,
//   },
//   // {
//   //   value: "พิมพ์ทั้งหมด",
//   //   disabled: false,
//   // },
// ];

type Props = {
  fields: FieldArrayWithId<IGoodsReceive, "trace_entry_list", "id">[];
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IGoodsReceive, "trace_entry_list">;
  replace: UseFieldArrayReplace<IGoodsReceive, "trace_entry_list">;
};

const GoodsReceiveItemList = ({ fields, remove, replace, update }: Props) => {
  const { t } = useTranslation();
  const [disabled] = useDisable();
  const { enqueueSnackbar } = useSnackbar();
  const [itemIds, setItemIds] = useState<string[]>([]);
  const [itemIdsSnapshot, setItemIdsSnapshot] = useState<string[]>([]);
  const { id } = useParams();

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<IGoodsReceive>();

  // const gridRef = useRef<AgGridReact<ISkuDetail>>(null);

  const watchType = useWatch({
    control,
    name: "type",
  });

  const watchBranchId = useWatch({
    control,
    name: "branch_id",
  });

  const watchWarehouseId = useWatch({
    control,
    name: "warehouse_id",
  });

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const { binLocationOptions } = useInventoryControl(
    watchBranchId,
    watchWarehouseId
  );

  const headers = useGoodsReceiveItemList(Boolean(disabled), status);

  const {
    modal: itemModal,
    openModalHandler: openItemModalHandler,
    closeModalHandler: closeItemModalHandler,
  } = useModal();

  const {
    control: serialControl,
    reset: serialReset,
    getValues: getSerialValues,
  } = useForm<ISerialForm>({
    defaultValues: {
      serial_quantity: 1,
      quantity_per_serial: 1,
      remark: "",
      lot_date: null,
      manufactured_date: null,
      expired_date: null,
    },
  });

  const {
    state: { authUser, permissions },
  } = useStateContext();

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const addItemsHandler = (selectedItems: any[]) => {
    const documentUniqueId = getValues("unique_id");

    if (selectedItems) {
      let filteredItems = fields
        .filter((item) => itemIds.includes(item.item_unique_id))
        .map(({ id, ...other }) => other) as ITraceEntry[];

      // get current items id to prevent duplication when appending newly selected items
      const filteredItemsId = filteredItems.map((item) => item.item_unique_id);

      selectedItems.forEach((item) => {
        if (!filteredItemsId.includes(item.sku_name)) {
          let formatItems: ITraceEntry | undefined;
          if (item.tracability === Tracability.Serial) {
            formatItems = {
              unique_id: uuidv4(),
              type: InventoryDocumentType.GoodsReceive,
              item_unique_id: item.item_unique_id || "",
              sku_name: item.sku_name,
              po_reference_unique_id: "",
              item_name: item.name || "",
              item_img_url: item.img_url,
              tracability: item.tracability,
              qty: 0,
              uom: { id: item.stock_uom_id, name_th: item.stock_uom_name_th },
              reference_unique_id: documentUniqueId,
              serial_list: [],
              is_stockable: true,
            };
          } else {
            formatItems = {
              unique_id: uuidv4(),
              type: InventoryDocumentType.GoodsReceive,
              item_unique_id: item.item_unique_id || "",
              sku_name: item.sku_name,
              po_reference_unique_id: "",
              item_name: item.name || "",
              item_img_url: item.img_url,
              tracability: item.tracability || Tracability.Normal,
              qty: 0,
              uom: { id: item.stock_uom_id, name_th: item.stock_uom_name_th },
              status: "is_active",
              reference_unique_id: documentUniqueId,
              barcode: item.barcode,
              is_stockable: true,
              remark: "",
              lot_date: null,
              manufactured_date: null,
              expired_date: null,
            };
          }
          filteredItems.push(formatItems);
        }
      });
      // sort for proerly render order (and for role assignment when submitting)
      filteredItems = filteredItems.sort((a, b) => {
        return (
          itemIds.indexOf(a.item_unique_id) - itemIds.indexOf(b.item_unique_id)
        );
      });
      replace(filteredItems);
    }
    closeItemModalHandler();
  };

  const {
    modal: serialModal,
    openModalHandler: openSerialModalHandler,
    closeModalHandler: closeSerialModalHandler,
  } = useModal();

  const addSerialsHandler = () => {
    const {
      serial_quantity,
      quantity_per_serial,
      lot_date,
      remark,
      manufactured_date,
      expired_date,
    } = getSerialValues();

    const currentTrace: ITraceEntry = getValues(
      `trace_entry_list.${currentIndex}`
    );

    const currentSerialList = getValues(
      `trace_entry_list.${currentIndex}.serial_list`
    ) as ITraceEntry[];

    const warehouseId = getValues("warehouse_id");
    const uniqueId = getValues("unique_id");
    if (!uniqueId || uniqueId === "") {
      enqueueSnackbar(`กรุณาระบุเลขที่เอกสาร`, {
        variant: "error",
      });
      return closeSerialModalHandler();
    }

    for (let i = 0; i < serial_quantity; i++) {
      const padSn = String(currentSerialList.length + 1).padStart(5, "0");
      currentSerialList.push({
        item_unique_id: currentTrace.item_unique_id,
        sku_name: currentTrace.sku_name,
        is_stockable: currentTrace.is_stockable,
        po_reference_unique_id: currentTrace.po_reference_unique_id,
        item_name: currentTrace.item_name,
        item_img_url: currentTrace.item_img_url,
        reference_unique_id: currentTrace.reference_unique_id,
        type: currentTrace.type,
        unique_id: uuidv4(),
        qty: quantity_per_serial,
        tracability: Tracability.Serial,
        uom: currentTrace.uom,
        serial_no: `${warehouseId}#${uniqueId}#${padSn}`,
        status: "is_active",
        lot_date,
        remark,
        manufactured_date,
        expired_date,
      });
    }

    setValue(`trace_entry_list.${currentIndex}.serial_list`, currentSerialList);

    closeSerialModalHandler();
    serialReset();
  };

  const {
    control: barcodeControl,
    getValues: getBarcodeValues,
    reset: resetBarcode,
    watch: watchBarcode,
  } = useForm<IBarcodeForm>({
    defaultValues: {
      barcode: "",
      source_bin_location_id: undefined,
    },
  });

  const onBarcodeSubmitHandler = (barcode?: string) => {
    const source_bin_location_id = getBarcodeValues("source_bin_location_id");
    const foundBinLocation = binLocationOptions?.find(
      (bin) => bin.value === source_bin_location_id
    );

    const traceList = getValues("trace_entry_list");

    if (barcode) {
      traceList.forEach((trace, index) => {
        if (trace.tracability === Tracability.Normal) {
          if (trace.barcode === barcode.trim()) {
            if (trace.status === "is_active") {
              const currentTrace = getValues(`trace_entry_list.${index}`);
              update(index, {
                ...currentTrace,
                scanned_by: {
                  first_name: authUser?.first_name || "",
                  last_name: authUser?.last_name || "",
                  email: authUser?.email || "",
                  img_url: authUser?.img_url,
                  user_unique_id: authUser?.unique_id || "",
                },
                source_bin_location: {
                  id: foundBinLocation?.value,
                  name: foundBinLocation?.label,
                },
                source_bin_location_id,
                status: "is_scanned",
                posted_date: dayjs().toDate(),
              });
              enqueueSnackbar(`สแกน SN :\n${currentTrace.barcode}\nสำเร็จ`, {
                variant: "success",
                style: { whiteSpace: "pre-line" },
              });
            } else {
              enqueueSnackbar(
                `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                {
                  variant: "error",
                  style: { whiteSpace: "pre-line" },
                }
              );
            }
          }
        } else {
          const totalSerial = trace.serial_list?.length;
          const totalScanned = trace.serial_list
            ? trace.serial_list.filter(
                (serial) => serial.status === "is_scanned"
              ).length
            : 0;
          trace.serial_list?.forEach((serial, subIndex) => {
            if (serial.barcode === barcode.trim()) {
              if (serial.status === "is_active") {
                const currentTrace = traceList[index];
                const formatSerial = {
                  ...serial,
                  scanned_by: {
                    first_name: authUser?.first_name || "",
                    last_name: authUser?.last_name || "",
                    email: authUser?.email || "",
                    img_url: authUser?.img_url,
                    user_unique_id: authUser?.unique_id || "",
                  },
                  source_bin_location: {
                    id: foundBinLocation?.value,
                    name: foundBinLocation?.label,
                  },
                  source_bin_location_id,
                  status: "is_scanned",
                  posted_date: dayjs().toDate(),
                };
                if (currentTrace && currentTrace.serial_list) {
                  currentTrace.serial_list[subIndex] = formatSerial;
                }
                update(index, currentTrace);
                enqueueSnackbar(
                  `สแกน SN :\n${serial.barcode}\nสำเร็จ จำนวน ${
                    totalScanned + 1
                  }/${totalSerial}`,
                  {
                    variant: "success",
                    style: { whiteSpace: "pre-line" },
                  }
                );
              } else {
                enqueueSnackbar(
                  `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                  {
                    variant: "error",
                    style: { whiteSpace: "pre-line" },
                  }
                );
              }
            }
          });
        }
      });

      if (
        !fields.some((trace) => {
          if (trace.tracability === Tracability.Normal) {
            return trace.barcode === barcode.trim();
          } else {
            if (
              !trace.serial_list?.some(
                (serial) => serial.barcode === barcode.trim()
              )
            ) {
              return false;
            } else {
              return true;
            }
          }
        })
      ) {
        enqueueSnackbar(`QR/Barcode\nสินค้านี้ไม่อยู่ในรายการ\nกรุณาสแกนใหม่`, {
          variant: "error",
          style: { whiteSpace: "pre-line" },
        });
      }
    }

    resetBarcode((prev) => ({
      ...prev,
      barcode: "",
    }));
  };

  // const exportBarcodeHandler = (type?: string) => {
  //   // const formatData =
  //   //   goodReceiveData &&
  //   //   goodReceiveData.trace_entry_list &&
  //   //   goodReceiveData.trace_entry_list.map((trace) => {
  //   //     if (trace) {
  //   //       return {
  //   //         รหัสสินค้า: trace.item_unique_id,
  //   //         ชื่อสินค้า: trace.item_name,
  //   //         SN: trace.serial_no,
  //   //         Lot: formatDate(trace.lot_date),
  //   //         Barcode: trace.barcode,
  //   //         จำนวน: trace.qty,
  //   //         หน่วย: trace.uom?.name,
  //   //         สถานะ:
  //   //           trace.status === InventoryDocumentStatus.IsActive
  //   //             ? "รอสแกน"
  //   //             : "สแกนแล้ว",
  //   //       };
  //   //     } else {
  //   //       return true;
  //   //     }
  //   //   });
  //   // exportAsExcelBarcode(formatData, "Barcode" + id, undefined, type);
  //   // enqueueSnackbar("นำออกBarcodeสำเร็จ", {
  //   //   variant: "success",
  //   // });
  // };

  //   const printBarcodeHandler = async (traceEntryList: ITraceEntry[]) => {
  //     const formatValues = traceEntryList.map((trace) => ({
  //       productId: trace.item_unique_id,
  //       productName: trace.item_name,
  //       productSn: trace.serial_no,
  //       productLot: trace.lot_date,
  //       productQty: trace.qty,
  //       productUom: trace.uom?.name,
  //       productBarcode: trace.barcode,
  //     }));
  //     try {
  //       const res = await fetch(`http://localhost:4000/print`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(formatValues),
  //       });
  //       const data = await res.json();
  //       if (data.status === "success") {
  //         enqueueSnackbar("พิมพ์สำเร็จ", {
  //           variant: "success",
  //         });
  //       } else if (data.status === "error") {
  //         enqueueSnackbar("พบการขัดข้องในการพิมพ์", {
  //           variant: "error",
  //         });
  //       }
  //     } catch (err) {
  //       console.error(err);
  //       enqueueSnackbar("ไม่สามารถเชื่อมต่อกับเครื่องพิมพ์", {
  //         variant: "error",
  //       });
  //     }
  //   };

  //   const { scrollRef, showCamera, setShowCamera, setScanData, setIsInterval } =
  //     useBarcodeScanner(onBarcodeSubmitHandler);

  const notDraft = checkIsNotDraftOrEmptyStatus(status);
  const isFinished = ["finished", "cancelled"].includes(status);
  const uneditable = Boolean(id) && !permissions?.goods_receive?.update;

  const rescanBarcodeHandler = (index: number) => {
    const { id, ...otherFields } = fields[index];
    update(index, {
      ...otherFields,
      status: "is_active",
      source_bin_location: undefined,
      source_bin_location_id: undefined,
      scanned_by: undefined,
      posted_date: undefined,
    });
  };

  return (
    <>
      {status && status !== "draft" && !isFinished && !uneditable && (
        <CustomizedBox maxWidth={1650} margin={"0 0 1.5rem 0"}>
          <Box display="flex" justifyContent="space-between" my={1}>
            <Typography color="primary.main" fontWeight={"bold"}>
              สแกน Barcode
            </Typography>
          </Box>
          {status !== "finished" && (
            <Grid container spacing={2} mt={1} mb={2}>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <ControlledSelect
                  label="เลือกสถานที่"
                  control={barcodeControl}
                  name="source_bin_location_id"
                  options={binLocationOptions}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                <ControlledTextField
                  label="Scan Barcode"
                  control={barcodeControl}
                  name="barcode"
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && e.shiftKey === false) {
                      const target = e.target as HTMLInputElement;
                      const data = target.value;
                      onBarcodeSubmitHandler(data);
                    }
                  }}
                  disabled={!watchBarcode("source_bin_location_id")}
                />
              </Grid>
            </Grid>
          )}
        </CustomizedBox>
      )}
      <CustomizedBox maxWidth={1650} margin={"0 0 1.5rem 0"}>
        <Box display="flex" justifyContent="space-between" my={1}>
          <Typography color="primary.main" fontWeight={"bold"} mb={2}>
            รายการสินค้า
          </Typography>
          {/* {notDraft && (
            <CustomizedMenuOptions
              size="medium"
              label="นำออก Barcode"
              options={options}
              onSelect={(e) => {
                const value = e.target as HTMLElement;
                switch (value.innerText) {
                  case `นำออก Excel`:
                    exportBarcodeHandler("xlsx");
                    break;
                  case `นำออก CSV`:
                    exportBarcodeHandler();
                    break;
                  case `พิมพ์ทั้งหมด`:
                    // const traceEntry =
                    //   goodReceiveData?.trace_entry_list as ITraceEntry[];
                    // printBarcodeHandler(traceEntry);
                    break;
                  default:
                }
              }}
            />
          )} */}
        </Box>
        <TableContainer>
          <Table
            sx={{ minWidth: 650, overflow: "scroll" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    align={header.align ? header.align : "center"}
                    key={index}
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={header.width}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      width={header.width}
                      sx={{
                        color: "#324290",
                      }}
                    >
                      {header.thaiLabel}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((trace, index) => (
                <Fragment key={trace.id}>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[0]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>{index + 1}</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[0]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {formatDateTime(trace.posted_date)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[1]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          minWidth: "90px",
                          justifyContent: "center",
                        }}
                      >
                        <Avatar
                          alt={trace.item_name}
                          src={trace?.item_img_url ? trace.item_img_url[0] : ""}
                          sx={{
                            width: 60,
                            height: 60,
                            border: "1px solid #BEBEBE",
                            borderRadius: "2px",
                          }}
                          variant="square"
                        >
                          <ImageOutlinedIcon
                            sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                            fontSize="medium"
                          />
                        </Avatar>
                      </Box>
                    </TableCell>
                    <TableCell
                      width={
                        (headers && headers.length > 0
                          ? headers[2]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>{trace.sku_name}</Typography>
                      <Typography fontSize={14}>{trace.item_name}</Typography>
                      {/* {goodReceiveData?.main_status === "draft" &&
                        initialTraceItems?.includes(trace.item_unique_id) &&
                        !existingInitialTraceItems?.includes(
                          trace.item_unique_id
                        ) && (
                          <Typography
                            fontSize={12}
                            sx={{
                              mt: 0.5,
                              color: (theme) => theme.palette.error.main,
                            }}
                          >
                            สินค้านี้ถูกลบออกจากระบบแล้ว กรุณาลบสินค้า
                          </Typography>
                        )} */}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[3]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {trace.po_reference_unique_id || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[4]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {trace.document_item_qty || 0}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[5]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {trace.posted_qty || 0}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[6]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {(trace.document_item_qty || 0) -
                          (trace.posted_qty || 0)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[7]?.width || 0
                          : 0) + 16
                      }
                    >
                      {trace.tracability === Tracability.Normal ? (
                        <ControlledTextField
                          type="number"
                          control={control}
                          name={`trace_entry_list.${index}.qty`}
                          error={Boolean(
                            (errors &&
                              errors.trace_entry_list &&
                              errors.trace_entry_list[index] &&
                              errors.trace_entry_list[index]?.qty) ||
                              (watchType !== "other" &&
                                trace.qty >
                                  (trace.document_item_qty || 0) -
                                    (trace.posted_qty || 0))
                          )}
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                          viewMode={notDraft || uneditable}
                        />
                      ) : (
                        <TotalPostedQuantityCell nestedIndex={index} />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[8]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography fontSize={14}>
                          {trace.uom?.name_th}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[9]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>{trace.cost_price}</Typography>
                    </TableCell>
                    {!notDraft && (
                      <TableCell
                        align="center"
                        width={
                          (headers && headers.length > 0
                            ? headers[10]?.width || 0
                            : 0) + 16
                        }
                      >
                        {trace.tracability === Tracability.Serial &&
                          !uneditable && (
                            <IconButton
                              onClick={() => {
                                setCurrentIndex(index);
                                openSerialModalHandler();
                              }}
                              disabled={!watchWarehouseId}
                            >
                              <AddCircleOutlineIcon fontSize="small" />
                            </IconButton>
                          )}
                      </TableCell>
                    )}
                    <TableCell align="center">
                      <Typography fontSize={14}>{trace.serial_no}</Typography>
                    </TableCell>
                    {notDraft && (
                      <Fragment>
                        <TableCell>
                          {trace.tracability === Tracability.Normal &&
                            trace.barcode}
                        </TableCell>
                        <TableCell align="center">
                          {trace.source_bin_location?.name}
                        </TableCell>
                        <TableCell>
                          {trace.tracability === Tracability.Normal && (
                            <Box display={"flex"}>
                              <CustomizedStatus status={trace.status} />
                              {!isFinished &&
                                trace.status === "is_scanned" &&
                                uneditable && (
                                  <CustomizedTooltip title="สแกนใหม่">
                                    <IconButton
                                      onClick={() =>
                                        rescanBarcodeHandler(index)
                                      }
                                    >
                                      <ReplayOutlinedIcon fontSize="small" />
                                    </IconButton>
                                  </CustomizedTooltip>
                                )}
                            </Box>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {trace.scanned_by && (
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <CustomizedAvatar
                                avatars={[
                                  {
                                    unique_id:
                                      trace.scanned_by.user_unique_id || "",
                                    first_name:
                                      trace.scanned_by.first_name || "",
                                    last_name: trace.scanned_by.last_name || "",
                                    img_url: trace.scanned_by.img_url
                                      ? trace.scanned_by.img_url[0]
                                      : "",
                                  },
                                ]}
                              />
                            </Box>
                          )}
                        </TableCell>
                      </Fragment>
                    )}
                    <TableCell align="center">
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>
                          <ControlledTextField
                            placeholder={t("sentence.remark")}
                            control={control}
                            name={`trace_entry_list.${index}.remark`}
                            viewMode={isFinished || uneditable}
                            viewModeNoLabel
                          />
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>
                          <ControlledDatePicker
                            placeholder="LOT"
                            control={control}
                            name={`trace_entry_list.${index}.lot_date`}
                            viewMode={isFinished || uneditable}
                            viewModeNoLabel
                          />
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>
                          <ControlledDatePicker
                            placeholder={t("date.manufactured_date")}
                            control={control}
                            name={`trace_entry_list.${index}.manufactured_date`}
                            viewMode={isFinished || uneditable}
                            viewModeNoLabel
                          />
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>
                          <ControlledDatePicker
                            placeholder={t("date.expired_date")}
                            control={control}
                            name={`trace_entry_list.${index}.expired_date`}
                            viewMode={isFinished || uneditable}
                            viewModeNoLabel
                          />
                        </Typography>
                      )}
                    </TableCell>
                    {!notDraft && !uneditable && (
                      <TableCell align="center">
                        <IconButton
                          onClick={() => {
                            remove(index);
                            setItemIds((prev) =>
                              prev.filter((id) => id !== trace.item_unique_id)
                            );
                            setItemIdsSnapshot((prev) =>
                              prev.filter((id) => id !== trace.item_unique_id)
                            );
                          }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                  <SerialList
                    nestedIndex={index}
                    disabled={disabled}
                    status={status}
                  />
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={2} display={"flex"} alignItems={"center"} gap={1}>
          {watchType === "other" &&
            (!status || status === "draft") &&
            !uneditable && (
              <CustomizedButton
                variant="outlined"
                title="เพิ่มสินค้า"
                onClick={openItemModalHandler}
              />
            )}
        </Box>
      </CustomizedBox>
      <CheckedItemModal
        showSelectItem={itemModal}
        closeItemTable={closeItemModalHandler}
        finishItemsSelect={addItemsHandler}
        itemIds={itemIds}
        itemIdsSnapshot={itemIdsSnapshot}
        setItemIds={setItemIds}
        setItemIdsSnapshot={setItemIdsSnapshot}
        rowSelection="multiple"
      />
      <SerialModal
        control={serialControl}
        reset={serialReset}
        serialModal={serialModal}
        closeSerialModalHandler={closeSerialModalHandler}
        addSerialsHandler={addSerialsHandler}
      />
    </>
  );
};

export default GoodsReceiveItemList;
