import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { ISelectOption } from "../../../../types/global";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { numberFormatter } from "../../../../utils/Formatter/Global";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import ControlledSelect from "../../../Controller/ControlledSelect";
import { useDisable } from "../../../../hooks/use-disable";
import { useSalesSummary } from "../../../../hooks/Sales/use-sales-summary";
import { AdditionalDiscountType } from "../../../../generated/sales";
import { formatNumber } from "../../../../utils/Global";

interface ISummary {
  name?: string;
  label: string;
  amount: number;
}

interface Props {
  documentType?: string;
}

const PurchaseSummary = ({ documentType }: Props) => {
  const { control, resetField, setValue } = useFormContext();
  const [disabled] = useDisable();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const currency = useWatch({
    control,
    name: "currency",
  });

  const exchange_rate =
    useWatch({
      control,
      name: "exchange_rate",
    }) || 0;

  const pre_vat_amount = useWatch({
    control,
    name: "pre_vat_amount",
  });

  const additionDiscountType = useWatch({
    control,
    name: "additional_discount_type",
  });

  const additionalDiscountTypeOptions: ISelectOption[] = [
    {
      label: currency ? currency : "THB",
      value: AdditionalDiscountType.Baht,
    },
    {
      label: "%",
      value: AdditionalDiscountType.Percent,
    },
  ];

  const { t } = useTranslation();
  const summary = useSalesSummary(control, setValue, "item_list");

  const defaultsSummaryList: ISummary[] = useMemo(
    () => [
      {
        name: "additional_discount",
        label: `${t("sales.additional_discount")}`,
        amount: summary.additional_discount,
      },
      {
        name: "post_discount_amount",
        label: `${t("sales.post_discount_amount")}`,
        amount: summary.post_discount_amount,
      },
      {
        name: "vat_exempted_amount",
        label: `${t("purchase.vat_exempted_amount")}`,
        amount: summary.vat_exempted_amount,
      },
      {
        name: "vat_0_percent_amount",
        label: `${t("purchase.vat_0_percent_amount")}`,
        amount: summary.vat_0_percent_amount,
      },
      {
        name: "vat_7_percent_amount",
        label: `${t("purchase.vat_7_percent_amount")}`,
        amount: summary.vat_7_percent_amount,
      },
      {
        name: "vat_amount",
        label: `${t("purchase.vat_amount")}`,
        amount: summary.vat_amount,
      },
      {
        name: "net_amount",
        label: `${t("sales.net_amount")}`,
        amount: summary.net_amount,
      },
    ],
    [
      summary.additional_discount,
      summary.net_amount,
      summary.post_discount_amount,
      summary.vat_0_percent_amount,
      summary.vat_7_percent_amount,
      summary.vat_amount,
      summary.vat_exempted_amount,
      t,
    ]
  );

  const [summaryList, setSummaryList] =
    useState<ISummary[]>(defaultsSummaryList);

  useEffect(() => {
    if (additionDiscountType === "percent") {
      setSummaryList([
        {
          name: "additional_discount",
          label: `${t("sales.additional_discount")}`,
          amount: (summary.additional_discount / pre_vat_amount) * 100,
        },
        {
          label: "",
          amount: summary.additional_discount,
        },
        {
          name: "post_discount_amount",
          label: `${t("sales.post_discount_amount")}`,
          amount: summary.post_discount_amount,
        },
        {
          name: "vat_exempted_amount",
          label: `${t("purchase.vat_exempted_amount")}`,
          amount: summary.vat_exempted_amount,
        },
        {
          name: "vat_0_percent_amount",
          label: `${t("purchase.vat_0_percent_amount")}`,
          amount: summary.vat_0_percent_amount,
        },
        {
          name: "vat_7_percent_amount",
          label: `${t("purchase.vat_7_percent_amount")}`,
          amount: summary.vat_7_percent_amount,
        },
        {
          name: "vat_amount",
          label: `${t("sales.vat_amount")}`,
          amount: summary.vat_amount,
        },
        {
          name: "net_amount",
          label: `${t("sales.net_amount")}`,
          amount: summary.net_amount,
        },
      ]);
    } else {
      setSummaryList(defaultsSummaryList);
    }
  }, [
    additionDiscountType,
    defaultsSummaryList,
    pre_vat_amount,
    summary.additional_discount,
    summary.net_amount,
    summary.post_discount_amount,
    summary.vat_0_percent_amount,
    summary.vat_7_percent_amount,
    summary.vat_amount,
    summary.vat_exempted_amount,
    t,
  ]);

  const additionalDiscountType: string = useWatch({
    control,
    name: "additional_discount_type",
  });

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const notEditStatus =
    ["approved", "partially_imported", "fully_imported"].includes(
      status || ""
    ) ||
    disabled ||
    documentType === "purchase_return";

  return (
    <>
      {summaryList.map((item) => (
        <Box key={item.label} sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1.5,
              gap: 3,
              py: item.name === "net_amount" ? 1 : 0,
            }}
            bgcolor={item.name === "net_amount" ? "primary.light" : undefined}
          >
            {!["additional_discount"].includes(item?.name || "") || disabled ? (
              <>
                <Typography
                  color={"primary.main"}
                  textAlign={isMobile ? "left" : "right"}
                  flex={2}
                  fontWeight={item.name === "net_amount" ? 600 : 400}
                >
                  {item.label}
                </Typography>
                <Typography
                  color={"primary.main"}
                  fontWeight={item.name === "net_amount" ? 600 : 400}
                  sx={{ minWidth: 150, textAlign: "right", mr: 2, flex: 1 }}
                >
                  {numberFormatter(item.amount)}
                </Typography>
                {additionalDiscountType === AdditionalDiscountType.Baht ? (
                  <Typography
                    color={"primary.main"}
                    sx={{ ml: 2.5, mr: 4, minWidth: "36px" }}
                    fontWeight={item.name === "net_amount" ? 600 : 400}
                  >
                    {currency ? currency : "THB"}
                  </Typography>
                ) : item.name === "additional_discount" ? (
                  <Typography
                    color={"primary.main"}
                    sx={{ ml: 2.5, mr: 4, minWidth: "36px" }}
                  >
                    %
                  </Typography>
                ) : (
                  <Typography
                    color={"primary.main"}
                    sx={{ ml: 2.5, mr: 4, minWidth: "36px" }}
                    fontWeight={item.name === "net_amount" ? 600 : 400}
                  >
                    {currency ? currency : "THB"}
                  </Typography>
                )}
              </>
            ) : (
              <>
                <Typography
                  color={"primary.main"}
                  mr={2.5}
                  textAlign={isMobile ? "left" : "right"}
                  flex={2}
                >
                  {item.label}
                </Typography>
                <Box
                  minWidth={143}
                  flex={1}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <ControlledNumberTextField
                    name={item?.name || ""}
                    control={control}
                    textAlign="right"
                    disabled={notEditStatus}
                  />
                </Box>
                {item.name === "additional_discount" ? (
                  <Box sx={{ width: "90px" }}>
                    <ControlledSelect
                      control={control}
                      name="additional_discount_type"
                      options={additionalDiscountTypeOptions}
                      onChange={() => {
                        if (resetField)
                          resetField("additional_discount", {
                            defaultValue: 0,
                          });
                      }}
                      disabled={notEditStatus}
                    />
                  </Box>
                ) : (
                  <Typography color={"primary.main"} sx={{ ml: 2.5, mr: 4 }}>
                    {currency ? currency : "THB"}
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Box>
      ))}
      {currency && currency !== "THB" && (
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1.5,
              gap: 3,
              py: 0,
            }}
          >
            <Typography
              color={"primary.main"}
              textAlign={isMobile ? "left" : "right"}
              flex={2}
              fontWeight={400}
            ></Typography>
            <Typography
              color="#737373"
              fontWeight={400}
              sx={{ minWidth: 150, textAlign: "right", mr: 2, flex: 1 }}
            >
              {formatNumber((summary.total_amount || 0) * exchange_rate)}
            </Typography>
            <Typography
              color="#737373"
              sx={{ ml: 2.5, mr: 4, minWidth: "36px" }}
              fontWeight={400}
            >
              THB
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PurchaseSummary;
