import { Box, CircularProgress, Typography } from "@mui/material";
import SalesItemListPdfTable from "../../../components/Table/Pdf/SalesItemList";
import {
  QuotationFindUniqueQuery,
  useQuotationFindUniqueQuery,
} from "../../../generated/sales";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useNavigate, useParams } from "react-router-dom";
import SalesPdfLayout from "../../../components/UI/SalesPdf/SalesPdfLayout";
import { IPdfInfo } from "../../../types/global";
import { formatDate } from "../../../utils/Date";
import { renderAddressString } from "../../../utils/Formatter/Global";
import { ISalesItemList } from "../../../types/Sales";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useReactToPrint } from "react-to-print";
import { ReactInstance, useEffect, useRef } from "react";
import { usePDFMultiplePage } from "../../../hooks/use-pdf-multiple-page";
import dayjs from "dayjs";
import { IContactPerson } from "../../../types/Contact/contact";
import {
  ApprovalTemplateFindByDocumentTypeQuery,
  useApprovalTemplateFindByDocumentTypeQuery,
} from "../../../generated/general";
import { useStateContext } from "../../../contexts/auth-context";

type Props = {
  name: string;
};

const QuotationPdf = ({ name }: Props) => {
  const { id } = useParams();
  const componentRef = useRef<ReactInstance | null>(null);
  const navigate = useNavigate();

  const {
    state: { authUser, permissions },
  } = useStateContext();

  const graphQLClient = createGraphQLClientWithMiddleware("sales");
  const graphQLClientGeneral = createGraphQLClientWithMiddleware("general");

  const { data, isLoading, isSuccess } =
    useQuotationFindUniqueQuery<QuotationFindUniqueQuery>(graphQLClient, {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    });

  const { data: approvalTemplates, isLoading: isApprovalLoading } =
    useApprovalTemplateFindByDocumentTypeQuery<ApprovalTemplateFindByDocumentTypeQuery>(
      graphQLClientGeneral,
      {
        documentType: "quotation",
      }
    );

  useEffect(() => {
    if (isSuccess && permissions?.quotation?.view_self) {
      const createdBy = data?.QuotationFindUnique?.created_by;
      const relatedUserList = data?.QuotationFindUnique?.related_user_list;
      if (
        createdBy?.user_unique_id === authUser?.unique_id ||
        relatedUserList?.find(
          (user) => user.user_unique_id === authUser?.unique_id
        )
      ) {
        return;
      } else {
        navigate(`/unauthorized`, {
          replace: true,
        });
      }
    }
  }, [
    authUser?.unique_id,
    data?.QuotationFindUnique?.created_by,
    data?.QuotationFindUnique?.related_user_list,
    isSuccess,
    navigate,
    permissions?.quotation?.view_self,
  ]);

  const { paginatedItems, startNumbers } = usePDFMultiplePage(
    data?.QuotationFindUnique, //data
    "sales_quotation", // document type
    141, // name and description row width
    700, // max height without footer
    500, // max height with footer
    226.484, // left header minimum height
    226.484, // right header minimum height
    undefined, // left footer minimum height (you don't need to specify this if the document doesn't have remark and tags)
    undefined // right footer minimum height (you don't need to specify this if the document doesn't have summary)
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: data?.QuotationFindUnique.unique_id,
  });

  const issueDate = dayjs(data?.QuotationFindUnique.issue_date);

  const dueDate = dayjs(data?.QuotationFindUnique.due_date);

  const highlightSection: IPdfInfo = {
    headers: ["เลขที่เอกสาร", "วันที่ออกเอกสาร", "ใช้ได้ถึง"],
    info: [
      data?.QuotationFindUnique.unique_id || "-",
      data?.QuotationFindUnique.issue_date
        ? formatDate(data?.QuotationFindUnique.issue_date)
        : "-",
      data?.QuotationFindUnique.due_date
        ? formatDate(data?.QuotationFindUnique.due_date)
        : "-",
    ],
  };

  const identityNoBranch = data?.QuotationFindUnique.customer_details
    .identity_no
    ? // &&
      // data?.QuotationFindUnique.branch
      // ? `${data?.QuotationFindUnique.customer_details.identity_no} (${data?.QuotationFindUnique.branch.unique_id})`
      `${data?.QuotationFindUnique.customer_details.identity_no}`
    : "-";

  const leftSection: IPdfInfo = {
    headers: [
      "ลูกค้า",
      "ที่อยู่",
      "เลขประจำตัวผู้เสียภาษี",
      "เบอร์โทรศัพท์@โทรสาร",
      "อีเมล",
    ],
    info: [
      `${data?.QuotationFindUnique.customer_unique_id} ${data?.QuotationFindUnique.customer_details.name}`,
      renderAddressString(
        data?.QuotationFindUnique.customer_details.billing_address
      ),
      identityNoBranch,
      `${data?.QuotationFindUnique.customer_details.phone || "-"}@${
        data?.QuotationFindUnique.customer_details.fax || "-"
      }`,
      data?.QuotationFindUnique.customer_details.email || "-",
    ],
  };

  const salesPersonFirstName =
    (data?.QuotationFindUnique.related_user_list &&
      data?.QuotationFindUnique.related_user_list.length > 0 &&
      data.QuotationFindUnique.related_user_list[0].first_name) ||
    "-";

  const salesPersonLastname =
    (data?.QuotationFindUnique.related_user_list &&
      data?.QuotationFindUnique.related_user_list.length > 0 &&
      data.QuotationFindUnique.related_user_list[0].last_name) ||
    "";
  const salesPerson = salesPersonFirstName + " " + salesPersonLastname;

  const priceDay =
    data?.QuotationFindUnique.issue_date &&
    data?.QuotationFindUnique.due_date &&
    dueDate.endOf("day").diff(issueDate.startOf("day"), "days");

  const contactPerson: IContactPerson =
    data?.QuotationFindUnique.contact_person_list &&
    data?.QuotationFindUnique.contact_person_list.length > 0 &&
    data?.QuotationFindUnique.contact_person_list[0];

  const contactPersonName = contactPerson
    ? `${contactPerson.title_name}${contactPerson.first_name} ${contactPerson.last_name}`
    : "-";

  const contactPersonPhone = contactPerson
    ? contactPerson.contact_channel_list.find(
        (channel) => channel.contact_channel_type === "phone"
      )?.contact_channel_info || "-"
    : "-";

  const rightSection: IPdfInfo = {
    headers: [
      "พนักงานขาย",
      "ยืนราคา (วัน)",
      "เครดิต (วัน)",
      "หมายเลขอ้างอิง",
      "ผู้ติดต่อ",
      "เบอร์โทรศัพท์",
    ],
    info: [
      salesPerson || "-",
      priceDay ? `${priceDay.toString()} วัน` : "-",
      `${data?.QuotationFindUnique.credit_day?.toString()} วัน` || "-",
      data?.QuotationFindUnique.reference_no || "-",
      contactPersonName,
      contactPersonPhone,
    ],
  };

  if (id && (isLoading || isApprovalLoading)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box maxWidth={1650}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box>
          <Typography variant="h5">{name}</Typography>
          <Typography>{`เลขที่เอกสาร ${data?.QuotationFindUnique.unique_id}`}</Typography>
        </Box>
        <Box>
          <CustomizedButton
            fullWidth
            title="พิมพ์เอกสาร"
            variant="outlined"
            onClick={handlePrint}
          />
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Box ref={componentRef} sx={{ m: "auto" }}>
          {paginatedItems.map((item, index) => (
            <SalesPdfLayout
              highlightSection={highlightSection}
              leftSection={leftSection}
              rightSection={rightSection}
              name={"ใบเสนอราคา"}
              multiplePage={paginatedItems.length > 1}
              page={index + 1}
              allPages={paginatedItems.length}
              data={data?.QuotationFindUnique}
              summary={index === paginatedItems.length - 1}
              salePerson={salesPerson}
              totalAmount={
                data?.QuotationFindUnique.total_amount
                  ? data?.QuotationFindUnique.total_amount
                  : 0
              }
              showPaymentSection={false}
              documentType="quotation"
              approvalTemplates={
                approvalTemplates?.ApprovalTemplateFindByDocumentType || []
              }
            >
              <SalesItemListPdfTable
                itemList={
                  (item as ISalesItemList[]) || []
                  // (data?.QuotationFindUnique.item_list as ISalesItemList[]) || []
                }
                startNumber={startNumbers[index]}
                lastTable={
                  paginatedItems[paginatedItems.length - 1].length === 0
                    ? index === paginatedItems.length - 2
                    : index === paginatedItems.length - 1
                }
                totalAmount={
                  data?.QuotationFindUnique.total_amount
                    ? data?.QuotationFindUnique.total_amount
                    : 0
                }
              />
            </SalesPdfLayout>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default QuotationPdf;
