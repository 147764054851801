import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import ControlledTextField from "../../../Controller/ControlledTextField";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import ControlledSelect from "../../../Controller/ControlledSelect";
import { formatDate, formatDateTimeNoAMPM } from "../../../../utils/Date";
import { useDisable } from "../../../../hooks/use-disable";
import { checkIsNotDraftOrEmptyStatus } from "../../../../utils/Global";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { IGoodsTransfer } from "../../../../types/Inventory/GoodsTransfer";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useStateContext } from "../../../../contexts/auth-context";

type Props = {
  nestedIndex: number;
};

const GoodsTransferSerialList = ({ nestedIndex }: Props) => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<IGoodsTransfer>();
  const { id } = useParams();

  const {
    state: { permissions },
  } = useStateContext();

  const { fields, update, remove } = useFieldArray({
    control,
    name: `trace_entry_list.${nestedIndex}.serial_list` as `trace_entry_list`,
  });

  const status = getValues("aggrid_status");
  const isNotDraft = checkIsNotDraftOrEmptyStatus(status || "");
  const isOpenDestinationScanned = [
    "wait_transfer",
    "in_transit",
    "finished",
  ].includes(status || "");
  const isFinished = ["finished", "cancelled"].includes(status || "");

  const [disabled] = useDisable();

  const tablePadding = {
    px: 1,
    py: 1,
  };
  const uneditable = Boolean(id) && !permissions?.goods_transfer?.update;
  const { t } = useTranslation();

  return (
    <>
      {fields.map((serial, index) => (
        <TableRow key={serial.id}>
          <TableCell sx={tablePadding}></TableCell>
          <TableCell align="center" sx={tablePadding}>
            <Typography fontSize={14}>
              {formatDateTimeNoAMPM(serial.posted_date)}
            </Typography>
          </TableCell>
          <TableCell sx={tablePadding}></TableCell>
          <TableCell sx={tablePadding}></TableCell>
          <TableCell align="center" sx={tablePadding}>
            <Typography fontSize={14}>{serial.stock_qty}</Typography>
          </TableCell>
          <TableCell align="center" sx={tablePadding}>
            <>
              {disabled ? (
                <Typography fontSize={14}>{serial.qty}</Typography>
              ) : (
                <ControlledTextField
                  type="number"
                  control={control}
                  name={`trace_entry_list.${nestedIndex}.serial_list.${index}.qty`}
                  error={Boolean(errors?.trace_entry_list?.[index]?.qty)}
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                  FormHelperTextProps={{
                    style: { fontSize: "10px" },
                  }}
                  viewMode={isNotDraft || uneditable}
                />
              )}
            </>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>{serial.uom?.name_th}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>{serial.serial_no}</Typography>
          </TableCell>
          <TableCell align="center">
            {serial.all_bin_locations &&
            serial.all_bin_locations.length > 1 &&
            !disabled &&
            !isNotDraft &&
            !uneditable ? (
              <ControlledSelect
                control={control}
                name={`trace_entry_list.${nestedIndex}.serial_list.${index}.source_bin_location_id`}
                options={serial.all_bin_locations.map((bin) => ({
                  label: bin.name,
                  value: bin.id,
                }))}
                onChange={(e: any) => {
                  const { id, ...otherFields } = fields[index];
                  update(index, {
                    ...otherFields,
                    stock_qty:
                      serial.all_bin_locations &&
                      serial.all_bin_locations.find(
                        (bin) => bin.id === e.target.value
                      ).stock_qty,
                    qty: 0,
                  });
                }}
                inputProps={{
                  style: {
                    fontSize: 14,
                  },
                }}
                MenuProps={{
                  style: {
                    fontSize: 14,
                  },
                }}
                helperTextSize={10}
              />
            ) : (
              <Typography fontSize={14}>
                {serial.source_bin_location?.name}
              </Typography>
            )}
          </TableCell>
          <TableCell align="center">
            {serial.scanned_by && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CustomizedAvatar
                  avatars={[
                    {
                      unique_id: serial.scanned_by?.user_unique_id || "",
                      first_name: serial.scanned_by?.first_name || "",
                      last_name: serial.scanned_by?.last_name || "",
                      img_url: serial.scanned_by?.img_url
                        ? serial.scanned_by.img_url[0]
                        : "",
                    },
                  ]}
                />
              </Box>
            )}
          </TableCell>
          {isOpenDestinationScanned ? (
            <>
              <TableCell align="center">
                <Typography fontSize={14}>
                  {serial.destination_bin_location?.name || ""}
                </Typography>
              </TableCell>
              <TableCell align="center">
                {serial.status === "cancelled" ? (
                  <Box display="flex" justifyContent="end" alignItems="center">
                    <CustomizedStatus status={"inactive"} text={"ยกเลิก"} />
                  </Box>
                ) : serial.destination_scanned_by ? (
                  <Box display="flex" justifyContent="end" alignItems="center">
                    <CustomizedStatus status={"is_scanned"} />
                  </Box>
                ) : (
                  <Box display="flex" justifyContent="end" alignItems="center">
                    <CustomizedStatus status={"is_active"} />
                  </Box>
                )}
              </TableCell>
              <TableCell align="center">
                {serial.destination_scanned_by ? (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <CustomizedAvatar
                      avatars={[
                        {
                          unique_id:
                            serial.destination_scanned_by.user_unique_id || "",
                          first_name:
                            serial.destination_scanned_by.first_name || "",
                          last_name:
                            serial.destination_scanned_by.last_name || "",
                          img_url: serial.destination_scanned_by.img_url
                            ? serial.destination_scanned_by.img_url[0]
                            : "",
                        },
                      ]}
                    />
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    justifyContent="end"
                    alignItems="center"
                  ></Box>
                )}
              </TableCell>
              <TableCell align="center" sx={tablePadding}>
                <Typography fontSize={14}>
                  {serial.destination_scanned_date
                    ? formatDateTimeNoAMPM(serial.destination_scanned_date)
                    : ""}
                </Typography>
              </TableCell>
            </>
          ) : null}
          <TableCell>
            <Typography fontSize={14}>{serial.barcode}</Typography>
          </TableCell>
          <TableCell>
            {isFinished || uneditable ? (
              <Typography fontSize={14}>{serial.remark}</Typography>
            ) : (
              <ControlledTextField
                placeholder={t("sentence.remark")}
                control={control}
                name={`trace_entry_list.${nestedIndex}.serial_list.${index}.remark`}
                viewMode={isFinished || uneditable}
              />
            )}
          </TableCell>
          <TableCell>
            <Typography fontSize={14}>{formatDate(serial.lot_date)}</Typography>
          </TableCell>
          <TableCell>
            <Typography fontSize={14}>
              {formatDate(serial.manufactured_date)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography fontSize={14}>
              {formatDate(serial.expired_date)}
            </Typography>
          </TableCell>
          {!isNotDraft && !uneditable && (
            <TableCell align="center">
              <IconButton
                onClick={() => {
                  remove(index);
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      ))}
    </>
  );
};

export default GoodsTransferSerialList;
