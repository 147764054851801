import { useFormContext, useWatch } from "react-hook-form";
import { ISalesReturn } from "../../../types/Sales/salesReturn";
import { useTranslation } from "react-i18next";
import { useDisable } from "../../../hooks/use-disable";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "../../../contexts/auth-context";
import { useActivityLog } from "../../../hooks/use-activity-log";
import { useSnackbar } from "notistack";
import { useSalesOption } from "../../../hooks/Sales/use-sales-option";
import { IApprovalTemplate } from "../../../types/global";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useSalesError } from "../../../hooks/Sales/use-sales-error";
import { ActivityType } from "../../../generated/inventory";
import {
  ActivityLogDocumentType,
  SalesReferenceDocumentType,
  SalesReturnCreateInput,
  SalesReturnFindUniqueQuery,
  SalesReturnUpdateInput,
  useSalesItemFindManyQuery,
  useSalesReturnCreateMutation,
  useSalesReturnFindUniqueQuery,
  useSalesReturnUpdateMutation,
} from "../../../generated/sales";
import { IExternalProps, ISalesItemList } from "../../../types/Sales";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  salesReturnCreatePayloadFormatter,
  salesReturnQueryFormatter,
  salesReturnUpdatePayloadFormatter,
} from "../../../utils/Formatter/Sales/SalesReturn";
import { Box, CircularProgress, Stack } from "@mui/material";
import SalesReturnHeader from "../../../components/Form/Sales/Return/Header";
import CustomerInfo from "../../../components/Form/Sales/CustomerInfo";
import AddressInfo from "../../../components/Form/Sales/CustomerInfo/AddressInfo";
import SalesInfo from "../../../components/Form/Sales/SalesInfo";
import SalesItemList from "../../../components/Table/Sales/ItemList/SalesItemList";
import SalesFooter from "../../../components/Form/Sales/Footer";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import ReturnDetail from "../../../components/Form/Sales/Return/ReturnDetail";
import dayjs from "dayjs";
import { useSalesItemListFormatter } from "../../../hooks/Sales/use-item-list-formatter";
import { validateSalesItemQty } from "../../../utils/Formatter/Sales";
// import { v4 as uuidv4 } from "uuid";

const DocumentInfoTab = () => {
  const {
    control,
    reset,
    handleSubmit,
    getValues,
    formState: { dirtyFields, errors },
  } = useFormContext<ISalesReturn>();

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const { t } = useTranslation();
  const [disabled, setDisabled] = useDisable();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { id } = useParams();
  const { state } = useLocation();

  const {
    state: { authUser, permissions },
  } = useStateContext();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const { createActivityLog } = useActivityLog();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { isLoading: isOptionLoading, approvalTemplates } =
    useSalesOption("sales_return");
  const [approverList, setApproverList] = useState<IApprovalTemplate[]>([]);
  const [approvalStep, setApprovalStep] = useState<number>(0);
  const [isHaveApproval, setIsHaveApproval] = useState<boolean>(false);
  const isApproval = approvalTemplates && approvalTemplates.length > 0;
  const [referenceItemList, setReferenceItemList] = useState<ISalesItemList[]>(
    []
  );
  const [netAmount, setNetAmount] = useState<number | null | undefined>(0);

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { data, isLoading, isSuccess, refetch } =
    useSalesReturnFindUniqueQuery<SalesReturnFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const { data: salesItemList, isLoading: itemListLoading } =
    useSalesItemFindManyQuery(
      graphQLClient,
      {
        input: {
          reference_document_type: state
            ? state.reference_document_list &&
              state.reference_document_list[0].document_type
            : data?.SalesReturnFindUnique?.reference_document_list &&
              data?.SalesReturnFindUnique?.reference_document_list[0]
                .document_type,
          reference_unique_id: state
            ? state.reference_document_list &&
              state.reference_document_list[0].document_unique_id
            : data?.SalesReturnFindUnique?.reference_document_list &&
              data?.SalesReturnFindUnique?.reference_document_list[0]
                .document_unique_id,
        },
      },
      {
        enabled: Boolean(
          state
            ? state.reference_document_list &&
                state.reference_document_list[0].document_unique_id
            : data?.SalesReturnFindUnique?.reference_document_list &&
                data?.SalesReturnFindUnique?.reference_document_list[0]
                  .document_unique_id
        ),
      }
    );

  const formatItemList = useSalesItemListFormatter(
    salesItemList?.SalesItemFindMany as ISalesItemList[]
  );

  useEffect(() => {
    setIsLoadingData(true);
    if (isSuccess) {
      const { SalesReturnFindUnique } = data;
      setNetAmount(SalesReturnFindUnique.net_amount);
      const getSalesReturnData = async () => {
        const formattedSalesReturn = await salesReturnQueryFormatter(
          SalesReturnFindUnique
        );
        reset(formattedSalesReturn);
        setIsLoadingData(false);
      };
      getSalesReturnData();
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (isSuccess) {
      const { SalesReturnFindUnique } = data;
      const currentStep = SalesReturnFindUnique?.approval_step as number;
      setApprovalStep(currentStep);
      if (SalesReturnFindUnique?.main_status === "wait_approve") {
        setApproverList(approvalTemplates as IApprovalTemplate[]);
      } else {
        const allApproval = SalesReturnFindUnique?.approver_list || [];
        setApproverList(allApproval);
      }

      const currentReviewerList =
        approvalTemplates.find(
          (template) => template?.step_number === currentStep
        )?.approver_list || [];

      const isHaveApproval = currentReviewerList.some(
        (reviewer) => reviewer.id === authUser?.id
      );

      setIsHaveApproval(isHaveApproval);
    }
  }, [approvalTemplates, authUser?.id, data, isSuccess]);

  useEffect(() => {
    if (
      id &&
      ((status !== "draft" && status !== "not_approved") ||
        !permissions?.sales_return?.update)
    ) {
      setDisabled(true);
    }

    return () => {
      setDisabled(false);
    };
  }, [id, permissions?.sales_return?.update, setDisabled, status]);

  useEffect(() => {
    if (state) {
      const { copied_unique_id, copied_id, ...otherState } = state;
      reset({
        ...otherState,
        created_date: dayjs(),
        issue_date: dayjs(),
        due_date: dayjs().add(state.credit_day || 0, "days"),
      });
    }
  }, [reset, state]);

  useEffect(() => {
    if (formatItemList && formatItemList.length > 0) {
      setReferenceItemList(formatItemList);
    }
  }, [formatItemList]);

  const { mutateAsync: create, isLoading: isCreating } =
    useSalesReturnCreateMutation<Error>(graphQLClient);

  const { mutateAsync: update, isLoading: isUpdating } =
    useSalesReturnUpdateMutation<Error>(graphQLClient);

  const draftHandler = async (data: ISalesReturn) => {
    if (!id) {
      try {
        const formatData = salesReturnCreatePayloadFormatter(data, "draft");
        const { SalesReturnCreate } = await create({
          input: formatData as SalesReturnCreateInput,
        });
        enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
          variant: "success",
        });
        navigate(`/sales/return/${SalesReturnCreate.id}`);
        if (state && state.copied_id) {
          await createActivityLog({
            activity_type: ActivityType.Copy,
            document_type: ActivityLogDocumentType.SalesReturn,
            reference_id: SalesReturnCreate?.id,
            activity_detail: {
              copied_from: {
                id: state.copied_id,
                unique_id: state.copied_unique_id,
              },
              copied_to: {
                id: SalesReturnCreate.id,
                unique_id: SalesReturnCreate.unique_id,
              },
            },
          });
        }
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.SalesReturn,
          reference_id: SalesReturnCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "draft",
            updated_fields: {
              net_amount: SalesReturnCreate.net_amount,
            },
          },
        });
      } catch (err) {
        enqueueSnackbar(`${t("button.save_draft")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    } else {
      try {
        const formatData = salesReturnUpdatePayloadFormatter(data, "draft");
        const { SalesReturnUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          input: formatData as SalesReturnUpdateInput,
        });
        enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
          variant: "success",
        });
        await refetch();

        const formattedDirtyFields = Object.keys(dirtyFields);

        if (formattedDirtyFields.length > 0) {
          await createActivityLog({
            activity_type: ActivityType.Edit,
            document_type: ActivityLogDocumentType.SalesReturn,
            reference_id: SalesReturnUpdate?.id,
            activity_detail: {
              updated_fields: {
                net_amount:
                  netAmount?.toFixed(2) ===
                  SalesReturnUpdate.net_amount?.toFixed(2)
                    ? undefined
                    : SalesReturnUpdate.net_amount,
              },
            },
          });
        }
      } catch (err) {
        console.error(err);
        enqueueSnackbar(`${t("button.save_draft")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const waitApproveHandler = async (data: ISalesReturn) => {
    if (!id) {
      try {
        const formatData = salesReturnCreatePayloadFormatter(
          data,
          "wait_approve",
          isApproval ? 1 : 0
        );
        const { SalesReturnCreate } = await create({
          input: formatData as SalesReturnCreateInput,
        });
        enqueueSnackbar(`${t("button.send") + t("button.approve")}สำเร็จ`, {
          variant: "success",
        });
        navigate(`/sales/return/${SalesReturnCreate.id}`);
        if (state && state.copied_id) {
          await createActivityLog({
            activity_type: ActivityType.Copy,
            document_type: ActivityLogDocumentType.SalesReturn,
            reference_id: SalesReturnCreate?.id,
            activity_detail: {
              copied_from: {
                id: state.copied_id,
                unique_id: state.copied_unique_id,
              },
              copied_to: {
                id: SalesReturnCreate.id,
                unique_id: SalesReturnCreate.unique_id,
              },
            },
          });
        }
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.SalesReturn,
          reference_id: SalesReturnCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "wait_approve",
            updated_fields: {
              net_amount: SalesReturnCreate.net_amount,
            },
          },
        });
      } catch (err) {
        console.error(err);
        enqueueSnackbar(`${t("button.send") + t("button.approve")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    } else {
      try {
        const formatData = salesReturnUpdatePayloadFormatter(
          data,
          "wait_approve",
          isApproval ? 1 : 0
        );
        const { SalesReturnUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          input: formatData as SalesReturnUpdateInput,
        });
        await refetch();
        const formattedDirtyFields = Object.keys(dirtyFields);
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.SalesReturn,
          reference_id: SalesReturnUpdate?.id,
          activity_detail: {
            secondary_operation:
              formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
            prev_status: data.aggrid_status,
            curr_status: "wait_approve",
            updated_fields: {
              net_amount:
                netAmount?.toFixed(2) ===
                SalesReturnUpdate.net_amount?.toFixed(2)
                  ? undefined
                  : SalesReturnUpdate.net_amount,
            },
          },
        });
      } catch (err) {
        enqueueSnackbar(`${t("button.send") + t("button.approve")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const approveHandler = async (data: ISalesReturn) => {
    if (!id) {
      try {
        const reviewed_by = {
          user_id: authUser?.id,
          first_name: authUser?.first_name || "",
          last_name: authUser?.last_name || "",
          reviewed_date: dayjs(),
        };
        const formatData = salesReturnCreatePayloadFormatter(
          data,
          "approved",
          undefined,
          reviewed_by
        );

        if (
          formatData.reference_document_list &&
          formatData.reference_document_list.length > 0
        ) {
          const isValid = await validateSalesItemQty(
            formatData.item_list,
            SalesReferenceDocumentType.SalesReturn
          );
          if (!isValid) {
            let message = "จำนวนสินค้าเกินจำนวนจากใบสั่งขาย";
            if (
              formatData.reference_document_list[0].document_type ===
              SalesReferenceDocumentType.CashSales
            ) {
              message = "จำนวนสินค้าเกินจำนวนจากใบขายสด";
            }
            enqueueSnackbar(message, {
              variant: "error",
            });
            return;
          }
        }

        const { SalesReturnCreate } = await create({
          input: formatData as SalesReturnCreateInput,
        });
        enqueueSnackbar(`${t("button.approve")}สำเร็จ`, {
          variant: "success",
        });
        navigate(`/sales/return/${SalesReturnCreate.id}`);
        if (state && state.copied_id) {
          await createActivityLog({
            activity_type: ActivityType.Copy,
            document_type: ActivityLogDocumentType.SalesReturn,
            reference_id: SalesReturnCreate?.id,
            activity_detail: {
              copied_from: {
                id: state.copied_id,
                unique_id: state.copied_unique_id,
              },
              copied_to: {
                id: SalesReturnCreate.id,
                unique_id: SalesReturnCreate.unique_id,
              },
            },
          });
        }
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.SalesReturn,
          reference_id: SalesReturnCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "approved",
          },
        });
      } catch (err) {
        console.error(err);
        enqueueSnackbar(`${t("button.approve")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    } else {
      try {
        let changingStatus = "wait_approve";
        let currentApprovalList: IApprovalTemplate[] = [];
        if (approvalStep && approvalStep > approvalTemplates.length - 1) {
          changingStatus = "approved";
          currentApprovalList = approvalTemplates as IApprovalTemplate[];
        }

        if (!isApproval) {
          changingStatus = "approved";
        }

        let currentApprovalStep = (approvalStep || 0) + 1;

        if (data.aggrid_status === "not_approved") {
          currentApprovalStep = approvalStep || 0;
        }

        const reviewed_by = {
          user_id: authUser?.id,
          first_name: authUser?.first_name || "",
          last_name: authUser?.last_name || "",
          reviewed_date: dayjs(),
        };

        const formatData = salesReturnUpdatePayloadFormatter(
          data,
          changingStatus,
          currentApprovalStep,
          currentApprovalList,
          false,
          reviewed_by
        );
        const { SalesReturnUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          input: formatData as SalesReturnUpdateInput,
        });
        enqueueSnackbar(`${t("button.approve")}สำเร็จ`, {
          variant: "success",
        });

        await refetch();

        if (isApproval) {
          if (changingStatus === "approved") {
            await createActivityLog({
              activity_type: ActivityType.StatusChange,
              document_type: ActivityLogDocumentType.SalesReturn,
              reference_id: SalesReturnUpdate?.id,
              activity_detail: {
                secondary_operation: ActivityType.Approve,
                message: [`ลำดับที่ ${approvalStep}`],
                prev_status: "wait_approve",
                curr_status: "approved",
              },
            });
          } else {
            await createActivityLog({
              activity_type: ActivityType.Approve,
              document_type: ActivityLogDocumentType.SalesReturn,
              reference_id: SalesReturnUpdate?.id,
              activity_detail: {
                message: [`ลำดับที่ ${approvalStep}`],
              },
            });
          }
        } else {
          const formattedDirtyFields = Object.keys(dirtyFields);
          await createActivityLog({
            activity_type: ActivityType.StatusChange,
            document_type: ActivityLogDocumentType.SalesReturn,
            reference_id: SalesReturnUpdate?.id,
            activity_detail: {
              secondary_operation:
                formattedDirtyFields?.length > 0
                  ? ActivityType.Edit
                  : undefined,
              prev_status: data.aggrid_status,
              curr_status: "approved",
              updated_fields:
                formattedDirtyFields?.length > 0
                  ? formattedDirtyFields
                  : undefined,
            },
          });
        }
      } catch (err) {
        console.error(err);
        enqueueSnackbar(`${t("button.approve")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const notApproveHandler = async (data: ISalesReturn) => {
    try {
      const flag_status =
        data?.flag_status && data.flag_status.length > 0
          ? data.flag_status.includes("not_approved")
            ? [...data.flag_status]
            : [...data.flag_status, "not_approved"]
          : ["not_approved"];

      const { SalesReturnUpdate } = await update({
        input: {
          // item_price_list_id: data.item_price_list_id as number,
          flag_status: flag_status,
        },
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      });

      await refetch();

      const formattedDirtyFields = Object.keys(dirtyFields);

      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.SalesReturn,
        reference_id: SalesReturnUpdate?.id,
        activity_detail: {
          secondary_operation:
            formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
          prev_status: data.aggrid_status,
          curr_status: "not_approved",
          updated_fields:
            formattedDirtyFields?.length > 0 ? formattedDirtyFields : undefined,
        },
      });

      enqueueSnackbar(`${t("button.not_approve")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`${t("button.not_approve")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const editClickHandler = () => {
    setDisabled(false);
    setIsEdit(true);
  };

  const cancelEditHandler = () => {
    setIsEdit(false);
    setDisabled(true);
    reset();
  };

  const editHandler = async (data: ISalesReturn, isNotApprove?: boolean) => {
    try {
      const formatData = salesReturnUpdatePayloadFormatter(
        data,
        data?.main_status || "",
        approvalStep,
        approverList,
        isNotApprove
      );
      const { SalesReturnUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        input: formatData as SalesReturnUpdateInput,
      });
      enqueueSnackbar(`${t("sentence.edit")}สำเร็จ`, {
        variant: "success",
      });
      await refetch();

      const formattedDirtyFields = Object.keys(dirtyFields);

      if (formattedDirtyFields.length > 0) {
        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.SalesReturn,
          reference_id: SalesReturnUpdate?.id,
          activity_detail: {
            secondary_operation:
              formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
            updated_fields: {
              net_amount:
                netAmount?.toFixed(2) ===
                SalesReturnUpdate.net_amount?.toFixed(2)
                  ? undefined
                  : SalesReturnUpdate.net_amount,
            },
          },
        });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`${t("sentence.edit")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const externalReferenceHandler = async (data: IExternalProps) => {
    try {
      await update({
        input: {
          external_reference_no: data.external_reference_no,
        },
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      });
      enqueueSnackbar(`${t("button.save")}สำเร็จ`, {
        variant: "success",
      });
      await refetch();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`${t("button.save")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const cancelHandler = async () => {
    try {
      const data = getValues();
      const flag_status =
        data?.flag_status && data.flag_status.length > 0
          ? data.flag_status.includes("cancelled")
            ? [...data.flag_status]
            : [...data.flag_status, "cancelled"]
          : ["cancelled"];

      const { SalesReturnUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        input: {
          // item_price_list_id: data.item_price_list_id as number,
          flag_status: flag_status,
        },
      });

      enqueueSnackbar(`${t("button.cancel")}สำเร็จ`, {
        variant: "success",
      });

      await refetch();

      const formattedDirtyFields = Object.keys(dirtyFields);
      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.SalesReturn,
        reference_id: SalesReturnUpdate?.id,
        activity_detail: {
          secondary_operation:
            formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
          prev_status: data.aggrid_status,
          curr_status: "cancelled",
          updated_fields:
            formattedDirtyFields?.length > 0 ? formattedDirtyFields : undefined,
        },
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`${t("button.cancel")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const renderButton = (status: string | undefined) => {
    switch (status) {
      case "draft":
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating || isUpdating}
              onClick={handleSubmit(draftHandler)}
            />
            {isApproval ? (
              <CustomizedButton
                title={t("button.send")}
                variant="contained"
                disabled={isCreating || isUpdating}
                onClick={handleSubmit(waitApproveHandler)}
              />
            ) : (
              <CustomizedButton
                title={t("button.approve")}
                variant="contained"
                disabled={isCreating || isUpdating}
                onClick={handleSubmit(approveHandler)}
              />
            )}
          </>
        );
      case "wait_approve":
        if (isEdit) {
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={cancelEditHandler}
                disabled={isUpdating}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={() => {
                  handleSubmit((data) => editHandler(data))();
                  setIsEdit(false);
                  setDisabled(true);
                }}
                disabled={isUpdating}
              />
            </>
          );
        } else {
          if (isHaveApproval && permissions?.sales_return?.update) {
            return (
              <>
                <CustomizedButton
                  variant="outlined"
                  title={t("button.not_approve")}
                  disabled={isUpdating}
                  onClick={handleSubmit(notApproveHandler)}
                />
                <CustomizedButton
                  title={t("button.approve")}
                  variant="contained"
                  onClick={handleSubmit(approveHandler)}
                  disabled={isUpdating}
                />
              </>
            );
          } else {
            return;
          }
        }
      case "not_approved":
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save")}
              disabled={isUpdating}
              onClick={handleSubmit((data) => editHandler(data, true))}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              onClick={handleSubmit(waitApproveHandler)}
              disabled={isUpdating}
            />
          </>
        );
      case "approved":
      case "cancelled":
      case "finished":
        if (isEdit) {
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isUpdating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={() => {
                  handleSubmit((data) => editHandler(data))();
                  setIsEdit(false);
                  setDisabled(true);
                }}
                disabled={isUpdating}
              />
            </>
          );
        }
        return;
      default:
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating || isUpdating}
              onClick={handleSubmit(draftHandler)}
            />
            {isApproval ? (
              <CustomizedButton
                title={t("button.send")}
                variant="contained"
                disabled={isCreating || isUpdating}
                onClick={handleSubmit(waitApproveHandler)}
              />
            ) : (
              <CustomizedButton
                title={t("button.approve")}
                variant="contained"
                disabled={isCreating || isUpdating}
                onClick={handleSubmit(approveHandler)}
              />
            )}
          </>
        );
    }
  };

  useSalesError(errors);

  if (
    isOptionLoading ||
    (id && (isLoadingData || isLoading || itemListLoading))
  ) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <SalesReturnHeader
        editClickHandler={editClickHandler}
        externalReferenceHandler={externalReferenceHandler}
        cancelHandler={cancelHandler}
        approverList={approverList as IApprovalTemplate[]}
        approvalStep={approvalStep}
      />
      <ReturnDetail />
      <CustomerInfo forceDisabled />
      <AddressInfo forceDisabled />
      <SalesInfo forceDisabled />
      <SalesItemList
        documentType="sales_return"
        referenceItemList={referenceItemList}
      />
      {/* <Promotion /> */}
      <SalesFooter documentType="sales_return" />
      <BottomNavbar>
        {(!disabled || status === "wait_approve") && (
          <Stack direction="row" spacing={1} alignItems="center">
            {renderButton(status)}
          </Stack>
        )}
      </BottomNavbar>
    </>
  );
};

export default DocumentInfoTab;
