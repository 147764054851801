import * as Yup from "yup";
import {
  Box,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  // CircularProgress,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { useTranslation } from "react-i18next";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import { useModal } from "../../../hooks/use-modal";
import LocationModal from "../../../components/UI/Modal/LocationModal";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import CheckedBranchModal from "../../../components/UI/Modal/CheckedBranchModal";
import { IBranch } from "../../../types/Setting/Branch";
import { useInventoryLocation } from "../../../hooks/use-inventory-location";
// import { characterRegExp } from "../../../utils/Global";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider } from "react-hook-form";
import { useStateContext } from "../../../contexts/auth-context";

interface IDefault {
  value: string;
}

const validation = Yup.object().shape({
  value: Yup.string().required("กรุณาระบุ"),
  // .matches(characterRegExp, "ห้ามมีอักขระพิเศษ"),
});

const LocationSetting = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<IBranch>();
  const [branchIds, setBranchsIds] = useState<string[]>([]);
  const [branchIdsSnapshot, setBranchsIdsSnapshot] = useState<string[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<any[]>();
  const [title, setTitle] = useState<string>("");
  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.inventory.index"),
      to: "/setting/inventory",
    },
    {
      name: t("setting.inventory.location.index"),
    },
  ];

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  // const handleExpandClick = () => {
  //   setExpanded((oldExpanded) =>
  //     oldExpanded.length === 0 ? ["1", "5", "6", "7"] : []
  //   );
  // };

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const {
    modal: branchModal,
    openModalHandler: openBranchModal,
    closeModalHandler: closeBranchModal,
  } = useModal();

  const finishBranchSelect = (data: IBranch) => {
    setSelectedBranch(data);
    closeBranchModal();
  };

  const buttonSx = { padding: "2px 10px", height: "fit-content" };

  const methods = useForm<IDefault>({
    defaultValues: {
      value: "",
    },
    resolver: yupResolver(validation),
  });

  const { reset, setValue } = methods;

  const getMergePrefixDocumentId = (selectedLocation: any, value?: string) => {
    if (
      (selectedLocation && selectedLocation.length === 0) ||
      selectedLocation === undefined
    ) {
      return "";
    } else if (selectedLocation && selectedLocation.length === 1) {
      return selectedLocation[0].name + "-" + (value ?? "");
    } else if (selectedLocation && selectedLocation.length === 2) {
      return selectedLocation[1].name + "-" + (value ?? "");
    } else if (selectedLocation && selectedLocation.length === 3) {
      return selectedLocation[2].name + "-" + (value ?? "");
    } else if (selectedLocation && selectedLocation.length === 4) {
      return selectedLocation[3].name + "-" + (value ?? "");
    }
    return "";
  };

  const handleOpen = ({
    title,
    location,
  }: {
    title: string;
    location?: any;
  }) => {
    if (title === "addLocation") {
      const new_location = getMergePrefixDocumentId(location) || "";
      setValue("value", new_location);
    } else {
      setValue("value", location[location.length - 1].name);
    }
    if (location) setSelectedLocation(location);
    setTitle(title);
    openModalHandler();
  };

  const handleClose = async () => {
    await refetch();
    closeModalHandler();
    setTimeout(() => {
      setTitle("");
      setSelectedLocation(undefined);
    }, 150);

    reset();
  };

  const {
    warehouses,
    refetch,
    handleSubmitCreate,
    handleSubmitUpdate,
    handleSubmitDelete,
  } = useInventoryLocation(selectedBranch?.id, selectedLocation, handleClose);

  const onSubmit = (data: IDefault) => {
    switch (title) {
      case "addLocation":
        handleSubmitCreate(data);
        break;
      case "editLocation":
        handleSubmitUpdate(data);
        break;
      case "deleteLocation":
        handleSubmitDelete();
        break;
      default:
        break;
    }
  };

  // if (selectedBranch && isLoading) {
  //   return (
  //     <Box
  //       sx={{
  //         height: "calc(100dvh - 300px)",
  //         width: "100%",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <CircularProgress />
  //     </Box>
  //   );
  // }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
        <Typography variant="h5">
          {t("setting.inventory.location.index")}
        </Typography>
        {/* <CustomizedButton
          title={t("button.add") + t("setting.inventory.location.index")}
          variant="contained"
          onClick={openModalHandler}
        /> */}
      </Box>
      <CustomizedBox margin={"1.5rem 0 0 0"}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <CustomizedTextField
              label={t("branch.index")}
              value={selectedBranch?.name}
              disabled
              InputProps={{
                endAdornment: (
                  <IconButton size="small" onClick={openBranchModal}>
                    <EditIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
        </Grid>
      </CustomizedBox>
      {selectedBranch && warehouses && warehouses.length > 0 && (
        <CustomizedBox>
          {/* <Box
          onClick={handleExpandClick}
          sx={{ cursor: "pointer", width: "fit-content", mb: 3 }}
          display={"flex"}
        >
          {expanded.length === 0 ? (
            <KeyboardArrowRightIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
          <Typography>
            {expanded.length === 0 ? "เปิดทั้งหมด" : "ปิดทั้งหมด"}
          </Typography>
        </Box> */}
          <TreeView
            aria-label="controlled"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            onNodeToggle={handleToggle}
          >
            {warehouses.map((warehouse) => (
              <TreeItem
                key={warehouse.unique_id}
                nodeId={`${warehouse.unique_id}`}
                label={
                  <>
                    {warehouse.name}{" "}
                    <label style={{ fontSize: "0.7rem" }}>
                      (รหัส: {warehouse.unique_id})
                    </label>
                  </>
                }
              >
                {permissions?.inventory_setting?.create && (
                  <ToggleButtonGroup aria-label="text alignment">
                    <ToggleButton
                      sx={buttonSx}
                      value="left"
                      aria-label="left aligned"
                      onClick={() =>
                        handleOpen({
                          title: "addLocation",
                          location: [warehouse],
                        })
                      }
                    >
                      เพิ่ม
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
                {warehouse.warehouse_level_1_list &&
                  warehouse.warehouse_level_1_list.length > 0 &&
                  warehouse.warehouse_level_1_list.map((locationLv1) => (
                    <TreeItem
                      key={`${warehouse.unique_id + locationLv1.id}`}
                      nodeId={`${warehouse.unique_id + locationLv1.id}`}
                      label={locationLv1.name}
                    >
                      {(permissions?.inventory_setting?.create ||
                        permissions?.inventory_setting?.update ||
                        permissions?.inventory_setting?.delete) && (
                        <ToggleButtonGroup aria-label="text alignment">
                          {permissions?.inventory_setting?.create && (
                            <ToggleButton
                              sx={buttonSx}
                              value="left"
                              aria-label="left aligned"
                              onClick={() =>
                                handleOpen({
                                  title: "addLocation",
                                  location: [warehouse, locationLv1],
                                })
                              }
                            >
                              เพิ่ม
                            </ToggleButton>
                          )}
                          {permissions?.inventory_setting?.update && (
                            <ToggleButton
                              sx={buttonSx}
                              value="center"
                              aria-label="centered"
                              onClick={() =>
                                handleOpen({
                                  title: "editLocation",
                                  location: [warehouse, locationLv1],
                                })
                              }
                            >
                              เปลี่ยนชื่อ
                            </ToggleButton>
                          )}
                          {permissions?.inventory_setting?.delete && (
                            <ToggleButton
                              sx={buttonSx}
                              value="right"
                              aria-label="right aligned"
                              onClick={() =>
                                handleOpen({
                                  title: "deleteLocation",
                                  location: [warehouse, locationLv1],
                                })
                              }
                            >
                              ลบ
                            </ToggleButton>
                          )}
                        </ToggleButtonGroup>
                      )}
                      {locationLv1.warehouse_level_2_list &&
                        locationLv1.warehouse_level_2_list.length > 0 &&
                        locationLv1.warehouse_level_2_list.map(
                          (locationLv2) => (
                            <TreeItem
                              key={`${
                                warehouse.unique_id +
                                locationLv1.id +
                                locationLv2.id
                              }`}
                              nodeId={`${
                                warehouse.unique_id +
                                locationLv1.id +
                                locationLv2.id
                              }`}
                              label={<>{locationLv2.name} </>}
                            >
                              {(permissions?.inventory_setting?.create ||
                                permissions?.inventory_setting?.update ||
                                permissions?.inventory_setting?.delete) && (
                                <ToggleButtonGroup aria-label="text alignment">
                                  {permissions?.inventory_setting?.create && (
                                    <ToggleButton
                                      sx={buttonSx}
                                      value="left"
                                      aria-label="left aligned"
                                      onClick={() =>
                                        handleOpen({
                                          title: "addLocation",
                                          location: [
                                            warehouse,
                                            locationLv1,
                                            locationLv2,
                                          ],
                                        })
                                      }
                                    >
                                      เพิ่ม
                                    </ToggleButton>
                                  )}
                                  {permissions?.inventory_setting?.update && (
                                    <ToggleButton
                                      sx={buttonSx}
                                      value="center"
                                      aria-label="centered"
                                      onClick={() =>
                                        handleOpen({
                                          title: "editLocation",
                                          location: [
                                            warehouse,
                                            locationLv1,
                                            locationLv2,
                                          ],
                                        })
                                      }
                                    >
                                      เปลี่ยนชื่อ
                                    </ToggleButton>
                                  )}
                                  {permissions?.inventory_setting?.delete && (
                                    <ToggleButton
                                      sx={buttonSx}
                                      value="right"
                                      aria-label="right aligned"
                                      onClick={() =>
                                        handleOpen({
                                          title: "deleteLocation",
                                          location: [
                                            warehouse,
                                            locationLv1,
                                            locationLv2,
                                          ],
                                        })
                                      }
                                    >
                                      ลบ
                                    </ToggleButton>
                                  )}
                                </ToggleButtonGroup>
                              )}
                              {locationLv2.warehouse_level_3_list &&
                                locationLv2.warehouse_level_3_list.length > 0 &&
                                locationLv2.warehouse_level_3_list.map(
                                  (locationLv3) => (
                                    <TreeItem
                                      key={`${
                                        warehouse.unique_id +
                                        locationLv1.id +
                                        locationLv2.id +
                                        locationLv3.id
                                      }`}
                                      nodeId={`${
                                        warehouse.unique_id +
                                        locationLv1.id +
                                        locationLv2.id +
                                        locationLv3.id
                                      }`}
                                      label={locationLv3.name}
                                    >
                                      {(permissions?.inventory_setting
                                        ?.create ||
                                        permissions?.inventory_setting
                                          ?.update ||
                                        permissions?.inventory_setting
                                          ?.delete) && (
                                        <ToggleButtonGroup aria-label="text alignment">
                                          {permissions?.inventory_setting
                                            ?.create && (
                                            <ToggleButton
                                              sx={buttonSx}
                                              value="left"
                                              aria-label="left aligned"
                                              onClick={() =>
                                                handleOpen({
                                                  title: "addLocation",
                                                  location: [
                                                    warehouse,
                                                    locationLv1,
                                                    locationLv2,
                                                    locationLv3,
                                                  ],
                                                })
                                              }
                                            >
                                              เพิ่ม
                                            </ToggleButton>
                                          )}
                                          {permissions?.inventory_setting
                                            ?.update && (
                                            <ToggleButton
                                              sx={buttonSx}
                                              value="center"
                                              aria-label="centered"
                                              onClick={() =>
                                                handleOpen({
                                                  title: "editLocation",
                                                  location: [
                                                    warehouse,
                                                    locationLv1,
                                                    locationLv2,
                                                    locationLv3,
                                                  ],
                                                })
                                              }
                                            >
                                              เปลี่ยนชื่อ
                                            </ToggleButton>
                                          )}
                                          {permissions?.inventory_setting
                                            ?.delete && (
                                            <ToggleButton
                                              sx={buttonSx}
                                              value="right"
                                              aria-label="right aligned"
                                              onClick={() =>
                                                handleOpen({
                                                  title: "deleteLocation",
                                                  location: [
                                                    warehouse,
                                                    locationLv1,
                                                    locationLv2,
                                                    locationLv3,
                                                  ],
                                                })
                                              }
                                            >
                                              ลบ
                                            </ToggleButton>
                                          )}
                                        </ToggleButtonGroup>
                                      )}
                                      {locationLv3.bin_location_list &&
                                        locationLv3.bin_location_list.length >
                                          0 &&
                                        locationLv3.bin_location_list.map(
                                          (binLocation) => (
                                            <TreeItem
                                              key={binLocation.name}
                                              nodeId={`${binLocation.name}`}
                                              label={binLocation.name}
                                            >
                                              {(permissions?.inventory_setting
                                                ?.create ||
                                                permissions?.inventory_setting
                                                  ?.update ||
                                                permissions?.inventory_setting
                                                  ?.delete) && (
                                                <ToggleButtonGroup aria-label="text alignment">
                                                  {permissions
                                                    ?.inventory_setting
                                                    ?.update && (
                                                    <ToggleButton
                                                      sx={buttonSx}
                                                      value="center"
                                                      aria-label="centered"
                                                      onClick={() =>
                                                        handleOpen({
                                                          title: "editLocation",
                                                          location: [
                                                            warehouse,
                                                            locationLv1,
                                                            locationLv2,
                                                            locationLv3,
                                                            binLocation,
                                                          ],
                                                        })
                                                      }
                                                    >
                                                      เปลี่ยนชื่อ
                                                    </ToggleButton>
                                                  )}
                                                  {permissions
                                                    ?.inventory_setting
                                                    ?.delete && (
                                                    <ToggleButton
                                                      sx={buttonSx}
                                                      value="right"
                                                      aria-label="right aligned"
                                                      onClick={() =>
                                                        handleOpen({
                                                          title:
                                                            "deleteLocation",
                                                          location: [
                                                            warehouse,
                                                            locationLv1,
                                                            locationLv2,
                                                            locationLv3,
                                                            binLocation,
                                                          ],
                                                        })
                                                      }
                                                    >
                                                      ลบ
                                                    </ToggleButton>
                                                  )}
                                                </ToggleButtonGroup>
                                              )}
                                            </TreeItem>
                                          )
                                        )}
                                    </TreeItem>
                                  )
                                )}
                            </TreeItem>
                          )
                        )}
                    </TreeItem>
                  ))}
              </TreeItem>
            ))}
          </TreeView>
        </CustomizedBox>
      )}
      <FormProvider {...methods}>
        <LocationModal
          title={title}
          open={modal}
          closeModalHandler={handleClose}
          onSubmit={onSubmit}
        />
      </FormProvider>
      <CheckedBranchModal
        branchIds={branchIds}
        branchIdsSnapshot={branchIdsSnapshot}
        closeBranchTable={closeBranchModal}
        setBranchIds={setBranchsIds}
        setBranchIdsSnapshot={setBranchsIdsSnapshot}
        showSelectBranch={branchModal}
        rowSelection="single"
        finishBranchSelect={finishBranchSelect}
      />
    </>
  );
};

export default LocationSetting;
