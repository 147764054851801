import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

import { useStateContext } from "../../../../contexts/auth-context";

import { Avatar, Box, Grid, Typography } from "@mui/material";

import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedLetterAvatar from "../../../Custom/CustomizedLetterAvatar";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";

import { IGoodsAdjustment } from "../../../../types/Inventory/GoodsAdjustment";
import { checkIsNotDraftOrEmptyStatus } from "../../../../utils/Global";
import { IApprovalTemplate } from "../../../../types/global";
import ApproverBox from "../../../UI/ApproverBox";

interface Props {
  isEdit: boolean;
  approverList: IApprovalTemplate[];
  approvalStep?: number | null;
}

const GoodsAdjustmentHeader = ({
  isEdit,
  approverList,
  approvalStep,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const {
    state: { authUser, permissions },
  } = useStateContext();

  const { control, setValue, getValues } = useFormContext<IGoodsAdjustment>();
  const aggridStatus = useWatch({ control, name: "aggrid_status" });

  const watchCreatedBy = useWatch({
    control,
    name: "created_by",
  });

  useEffect(() => {
    if (!id && authUser) {
      setValue("created_by", {
        user_unique_id: authUser?.unique_id,
        email: authUser?.email,
        first_name: authUser?.first_name,
        last_name: authUser?.last_name,
        img_url: authUser?.img_url,
      });
    }
  }, [authUser, id, setValue]);

  const isNotDraft = checkIsNotDraftOrEmptyStatus(aggridStatus || "");
  const uneditable = Boolean(id) && !permissions?.goods_adjust?.update;

  return (
    <>
      <Box maxWidth={1040} mt={2}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={12} md={6}>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Typography variant="h5">
                {id
                  ? getValues("unique_id")
                  : `${t("button.create")}${t(
                      "inventory.goods_adjustment.index"
                    )}`}
              </Typography>
              <CustomizedStatus status={aggridStatus} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={1.5} justifyContent={"flex-end"}>
              <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                <ControlledDatePicker
                  name="created_date"
                  control={control}
                  label={t(
                    "inventory.goods_adjustment.detail.header.created_date"
                  )}
                  viewMode={uneditable}
                  disabled
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                <ControlledDatePicker
                  name="posted_date"
                  control={control}
                  label={t(
                    "inventory.goods_adjustment.detail.header.posted_date"
                  )}
                  disabled={isNotDraft && !isEdit}
                  viewMode={uneditable}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center", mt: 1 }}>
          <Typography fontWeight="bold">{t("sentence.created_by")}</Typography>
          <CustomizedTooltip
            title={`${watchCreatedBy?.first_name} ${watchCreatedBy?.last_name}`}
            enterNextDelay={200}
          >
            {watchCreatedBy?.img_url && watchCreatedBy?.img_url.length > 0 ? (
              <Avatar
                alt={`avatar`}
                src={watchCreatedBy?.img_url ? watchCreatedBy?.img_url[0] : ""}
              />
            ) : (
              <CustomizedLetterAvatar
                name={`${watchCreatedBy?.first_name} ${watchCreatedBy?.last_name}`}
              />
            )}
          </CustomizedTooltip>
        </Box>
      </Box>
      <Box maxWidth={1650} mb={2}>
        {id && aggridStatus !== "draft" && approverList.length > 0 && (
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              mt: 1,
              whiteSpace: "nowrap",
              flexWrap: "wrap",
            }}
          >
            <Typography fontWeight={"bold"}>ผู้อนุมัติ</Typography>
            {approverList.map((approval, index) => {
              const formatApproval = approval as IApprovalTemplate;
              return (
                <ApproverBox
                  key={index}
                  approval={formatApproval}
                  index={index}
                  approvalStep={approvalStep}
                />
              );
            })}
          </Box>
        )}
      </Box>
    </>
  );
};

export default GoodsAdjustmentHeader;
