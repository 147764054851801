import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { FormProvider, useForm } from "react-hook-form";
import { Box, CircularProgress, IconButton, Stack } from "@mui/material";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ActivityLogSlideInPanel } from "../../../components/UI/SlideInPanel/ActivityLogSlideInPanel";
import { ActivityLogDocumentType } from "../../../generated/sales";
import PromotionHeader from "../../../components/Form/Marketing/Promotion/Header";
import PromotionInfo from "../../../components/Form/Marketing/Promotion/Info";
import PromotionPurchaseCondition from "../../../components/Form/Marketing/Promotion/PurchaseCondition";
import PromotionReceiveType from "../../../components/Form/Marketing/Promotion/ReceiveType";
import PromotionCoupon from "../../../components/Form/Marketing/Promotion/Coupon";
import PromotionCustomer from "../../../components/Form/Marketing/Promotion/Customer";
import PromotionSalesChannel from "../../../components/Form/Marketing/Promotion/SalesChannel";
import PromotionUsage from "../../../components/Form/Marketing/Promotion/Usage";
import {
  promotionSchema,
  promotionValidation,
} from "../../../components/Form/Marketing/Promotion/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import PromotionSetting from "../../../components/Form/Marketing/Promotion/Setting";
import { IPromotion } from "../../../types/Marketing/Promotion";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useDisable } from "../../../hooks/use-disable";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import {
  CreatePromotionSettingInput,
  PromotionSettingFindUniqueQuery,
  UpdatePromotionSettingInput,
  usePromotionSettingCreateMutation,
  usePromotionSettingDeleteMutation,
  usePromotionSettingFindUniqueQuery,
  usePromotionSettingUpdateMutation,
} from "../../../generated/marketing";
import {
  promotionCreatePayloadFormatter,
  promotionQueryFormatter,
  promotionUpdatePayloadFormatter,
} from "../../../utils/Formatter/Marketing/Promotion";
import { useStateContext } from "../../../contexts/auth-context";
import { useSnackbar } from "notistack";
import PromotionPriority from "../../../components/Form/Marketing/Promotion/Priority";
import { v4 as uuidv4 } from "uuid";

type Props = {
  type:
    | "discount_product"
    | "discount_fix_price"
    | "discount_total"
    | "discount_order"
    | "free_gift";
};

const PromotionContainer = ({ type }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [disabled, setDisabled] = useDisable();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm<IPromotion>({
    defaultValues: {
      ...promotionSchema,
      promotion_category: type,
      state_uuid: uuidv4(),
    },
    resolver: yupResolver<any>(promotionValidation),
  });

  const {
    state: { authUser },
  } = useStateContext();

  const { handleSubmit, reset, getValues } = methods;

  useEffect(() => {
    if (id) {
      setDisabled(true);
    }
    return () => {
      setDisabled(false);
    };
  }, [id, setDisabled]);

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("marketing");

  const { data, isLoading, isSuccess, refetch } =
    usePromotionSettingFindUniqueQuery<PromotionSettingFindUniqueQuery>(
      graphQLClient,
      {
        promotionSettingFindUniqueId: id ? parseInt(id) : 0,
      },
      {
        enabled: Boolean(id),
      }
    );

  const { mutateAsync: create, isLoading: isCreating } =
    usePromotionSettingCreateMutation<Error>(graphQLClient);

  const { mutateAsync: update, isLoading: isUpdating } =
    usePromotionSettingUpdateMutation<Error>(graphQLClient);

  const { mutateAsync: deletePromotion, isLoading: isDeleting } =
    usePromotionSettingDeleteMutation<Error>(graphQLClient);

  useEffect(() => {
    if (isSuccess) {
      const { PromotionSettingFindUnique } = data;
      const formatData = promotionQueryFormatter(PromotionSettingFindUnique);
      reset(formatData);
    }
  }, [data, isSuccess, reset]);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("marketing.index"),
      to: "/marketing",
    },
    {
      name: t("marketing.promotion.index"),
      to: "/marketing/promotion",
    },
    {
      name: id
        ? data?.PromotionSettingFindUnique?.unique_id || "-"
        : t("setting.index") + t(`marketing.promotion.type.${type}`),
    },
  ];

  const savePromotionHandler = async (data: IPromotion) => {
    if (!id) {
      try {
        const formatPayload = promotionCreatePayloadFormatter(data, authUser);
        const { PromotionSettingCreate } = await create({
          createPromotionSettingInput:
            formatPayload as CreatePromotionSettingInput,
        });
        navigate(
          `/marketing/promotion/discount-product/${PromotionSettingCreate.id}`
        );
        await refetch();
        enqueueSnackbar(
          `${t("button.create")}${t("marketing.promotion.index")}สำเร็จ`,
          {
            variant: "success",
          }
        );
      } catch (err) {
        const errorMessage = (err as any)?.message || "";
        let formattedErrorMessage = `${t("button.create")}${t(
          "marketing.promotion.index"
        )}ไม่สำเร็จ`;

        if (errorMessage?.includes("Duplicate unique id")) {
          formattedErrorMessage =
            "รหัสโปรโมชันภายในนี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
        } else if (errorMessage?.includes("Duplicate name")) {
          formattedErrorMessage =
            "ชื่อโปรโมชันนี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
        }

        enqueueSnackbar(formattedErrorMessage, {
          variant: "error",
        });
      }
    } else {
      try {
        const formatPayload = promotionUpdatePayloadFormatter(data, authUser);
        await update({
          updatePromotionSettingInput: {
            id: id ? parseInt(id) : 0,
            ...formatPayload,
          } as UpdatePromotionSettingInput,
        });
        await refetch();
        enqueueSnackbar("แก้ไขสำเร็จ", {
          variant: "success",
        });
        setIsEdit(false);
        setDisabled(true);
      } catch (err) {
        const errorMessage = (err as any)?.message || "";
        let formattedErrorMessage = "แก้ไขไม่สำเร็จ";
        if (errorMessage?.includes("Duplicate name")) {
          formattedErrorMessage =
            "ชื่อโปรโมชันนี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
        }
        enqueueSnackbar(formattedErrorMessage, {
          variant: "error",
        });
      }
    }
  };

  const editClickHandler = () => {
    setDisabled(false);
    setIsEdit(true);
  };

  const cancelEditHandler = () => {
    setIsEdit(false);
    setDisabled(true);
    reset();
  };

  const deleteHandler = async () => {
    try {
      await deletePromotion({
        promotionSettingDeleteId: getValues("id") || 0,
      });
      navigate("/marketing/promotion");
      enqueueSnackbar(`ลบสำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      if ((err as any)?.message?.includes("The promotion is being used")) {
        enqueueSnackbar("ลบไม่สำเร็จ เนื่องจากมีการอ้างอิงเอกสารอยู่", {
          variant: "error",
        });
        return;
      } else {
        enqueueSnackbar(`ลบไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const errorHandler = (err: any) => {
    enqueueSnackbar("กรุณากรอกข้อมูลที่จำเป็น", { variant: "error" });
  };

  const isMutating = isCreating || isUpdating || isDeleting;

  if (id && (isLoading || isMutating)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <FormProvider {...methods}>
      <Box display={"flex"} justifyContent={"space-between"} maxWidth={1040}>
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        {id && (
          <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
            <IconButton
              onClick={() => {
                setOpenDrawer(true);
              }}
              sx={{
                color: (theme) => theme.palette.primary.main,
                padding: 0,
                marginLeft: "4px",
              }}
            >
              <RestoreOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )}
      </Box>
      <PromotionHeader
        type={type}
        editClickHandler={editClickHandler}
        deleteHandler={deleteHandler}
      />
      <PromotionInfo />
      <PromotionSetting />
      <PromotionPurchaseCondition />
      <PromotionReceiveType />
      <PromotionCoupon />
      <PromotionCustomer />
      <PromotionSalesChannel />
      <PromotionPriority />
      <PromotionUsage />
      <BottomNavbar>
        {!disabled && (
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              title={t("button.cancel")}
              variant="outlined"
              onClick={
                isEdit
                  ? cancelEditHandler
                  : () => navigate("/marketing/promotion")
              }
            />
            <CustomizedButton
              title={t("button.confirm")}
              variant="contained"
              onClick={handleSubmit(savePromotionHandler, errorHandler)}
            />
          </Stack>
        )}
      </BottomNavbar>
      {id && (
        <ActivityLogSlideInPanel
          open={openDrawer}
          handleClose={() => setOpenDrawer(false)}
          documentId={id!}
          documentType={ActivityLogDocumentType.Promotion}
        />
      )}
    </FormProvider>
  );
};

export default PromotionContainer;
