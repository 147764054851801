import { Box, Grid, IconButton, Typography } from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { useFormContext, useWatch } from "react-hook-form";

import ControlledSelect from "../../../Controller/ControlledSelect";
import useInventoryControl from "../../../../hooks/Inventory/use-inventory-control";

import LabelInput from "../../../UI/LabelInput";
import {
  branchInfoFromSessionStorage,
  checkIsNotDraftOrEmptyStatus,
} from "../../../../utils/Global";
import { useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import {
  useWmsUniqueIdGenerateQuery,
  WmsUniqueIdGenerateQuery,
  WmsModelType,
} from "../../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { useStateContext } from "../../../../contexts/auth-context";

type Props = {
  isEdit: boolean;
};

const GoodsAdjustmentInfo = ({ isEdit }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  const {
    state: { permissions },
  } = useStateContext();

  const { branch_id, is_hq } = branchInfoFromSessionStorage();

  const watchSourceBranchId = useWatch({
    control,
    name: "branch_id",
  });

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const { id } = useParams();

  const graphQLClient = createGraphQLClientWithMiddleware("wms");

  const { refetch: refetchUniqueId } =
    useWmsUniqueIdGenerateQuery<WmsUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: WmsModelType.GoodsAdjust,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const { branchesOptions, warehousesOptions } =
    useInventoryControl(watchSourceBranchId);

  const isNotDraft = checkIsNotDraftOrEmptyStatus(status || "");
  const isFinished =
    ["finished", "cancelled"].includes(status) ||
    (Boolean(id) && !permissions?.goods_adjust?.update);

  return (
    <CustomizedBox margin={"0 0 1.5rem 0"}>
      <Typography color="primary.main" fontWeight={600} mb={2}>
        {t("sentence.detail")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid
          item
          xs={Boolean(id) ? 12 : 11}
          sm={Boolean(id) ? 12 : 11}
          md={4}
          lg={4}
          xl={4}
        >
          <Box sx={{ display: "flex" }}>
            <ControlledTextField
              name="unique_id"
              control={control}
              label={t(`sentence.unique_id`)}
              error={Boolean(errors.unique_id)}
              helperText={errors.unique_id?.message?.toString()}
              disabled={Boolean(id)}
              viewMode={isFinished}
              required
            />
          </Box>
        </Grid>
        <Grid item xs={1} sm={1} md={8} lg={8} xl={8}>
          {!Boolean(id) && (
            <CustomizedTooltip
              title={`เรียก${t(`sentence.unique_id`)}`}
              enterNextDelay={200}
            >
              <IconButton onClick={generateUniqueId} color="primary">
                <RestartAltOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {isFinished ? (
            <LabelInput
              label={t("inventory.goods_adjustment.detail.info.branch")}
              value={getValues("branch.name")}
            />
          ) : (
            <ControlledSelect
              control={control}
              name="branch_id"
              label={t("inventory.goods_adjustment.detail.info.branch")}
              options={
                is_hq === "true"
                  ? branchesOptions
                  : branchesOptions.filter(
                      (branch) => branch.value === parseInt(branch_id ?? "0")
                    )
              }
              disabled={isNotDraft}
              onChange={() => {
                setValue("warehouse_id", "");
              }}
              required
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {isFinished ? (
            <LabelInput
              label={t("inventory.goods_adjustment.detail.info.warehouse")}
              value={getValues("warehouse.name")}
            />
          ) : (
            <ControlledSelect
              control={control}
              name="warehouse_id"
              label={t("inventory.goods_adjustment.detail.info.warehouse")}
              options={warehousesOptions}
              disabled={isNotDraft || warehousesOptions.length === 0}
              required
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <ControlledTextField
            control={control}
            name="remark"
            label={t("inventory.remark")}
            viewMode={isFinished}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default GoodsAdjustmentInfo;
