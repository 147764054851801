import { useCallback, useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../../types/global";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../../../contexts/auth-context";

export const usePurchaseRequestOption = (
  status?: string | null,
  disabled?: boolean
) => {
  const {
    state: { permissions },
  } = useStateContext();

  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: "แก้ไข",
        disabled: !disabled,
      },
      {
        value: "คัดลอก",
        disabled: false,
      },
      {
        value: "ยกเลิก",
        disabled: false,
      },
    ],
    [disabled]
  );

  const editOption = defaultOptions[0];
  const copyOption = defaultOptions[1];
  const cancelOption = defaultOptions[2];

  const canUpdateAndCancel = useCallback(() => {
    return (
      permissions?.purchase_request?.update &&
      permissions?.purchase_request?.cancel
    );
  }, [permissions]);

  const getOptionsForStatus = useCallback(
    (status?: string | null) => {
      const isCreate = permissions?.purchase_request?.create;

      if (["draft", "not_approved"].includes(status || "")) {
        if (canUpdateAndCancel()) {
          return isCreate ? [copyOption, cancelOption] : [cancelOption];
        }
        return isCreate ? [copyOption] : [];
      }

      if (
        ["partially_ordered", "fully_ordered", "cancelled"].includes(
          status || ""
        )
      ) {
        return isCreate ? [copyOption] : [];
      }

      // Default case when no specific status matches
      return canUpdateAndCancel()
        ? isCreate
          ? defaultOptions
          : [editOption, cancelOption]
        : isCreate
        ? [copyOption]
        : [];
    },
    [
      canUpdateAndCancel,
      cancelOption,
      copyOption,
      defaultOptions,
      editOption,
      permissions?.purchase_request?.create,
    ]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    const options = getOptionsForStatus(status);
    setSelectedModifyOptions(options);
  }, [status, getOptionsForStatus]);

  return selectModifyOptions;
};

export const usePurchaseRequestCreate = (status?: string | null) => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      ...(permissions?.purchase_order?.create
        ? [
            {
              value: t("purchase.order.index"),
              disabled: false,
            },
          ]
        : []),
    ],
    [permissions?.purchase_order?.create, t]
  );

  const [disabled, setDisabled] = useState<boolean>(false);
  useEffect(() => {
    switch (status) {
      case "accepted":
      case "approved":
      case "finished":
      case "partially_ordered":
        setDisabled(false);
        break;
      default:
        setDisabled(true);
    }
  }, [status]);
  return {
    selectCreateOptions: defaultOptions,
    disabledCreateOptions: disabled,
  };
};
