import { useEffect, useState } from "react";
import { IHeaderTable } from "../../types/global";

export const usePromotionItemListHeader = (
  disabled: boolean,
  promotionCategory: string,
  tableType: "item_condition" | "redeem_item"
) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    const isFixedRedeemItemlist =
      tableType === "redeem_item" && promotionCategory === "discount_fix_price";

    const defaultHeaders: IHeaderTable[] = [
      {
        thaiLabel: "",
        width: 30,
        align: "center",
      },
      {
        thaiLabel: "ชื่อสินค้า",
        width: isFixedRedeemItemlist ? 340 : 520,
        align: "left",
      },
      isFixedRedeemItemlist && {
        thaiLabel: "ราคาคงที่",
        width: 150,
        align: "right",
        required: true,
      },
      {
        thaiLabel: "จำนวน",
        width: 150,
        align: "right",
        required: true,
      },
      {
        thaiLabel: "หน่วย",
        label: "Unit",
        width: 150,
        align: "left",
      },
    ].filter(Boolean) as IHeaderTable[];

    if (!disabled) {
      setHeaders([
        ...defaultHeaders,
        {
          label: "",
          thaiLabel: "",
          width: 30,
          align: "left",
        },
      ]);
    } else {
      setHeaders(defaultHeaders);
    }
  }, [disabled, promotionCategory, tableType]);

  return headers;
};
