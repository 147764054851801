import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { Box, Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import RoleTable from "../../../components/Table/Role";
import { useStateContext } from "../../../contexts/auth-context";

const Role = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: t("user.role.index"),
    },
  ];
  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
        <Typography variant="h5">{t("user.role.index")}</Typography>
        {permissions?.role?.create && (
          <CustomizedButton
            title={t("user.role.add_new_role")}
            variant="contained"
            onClick={() => navigate(`${pathname}/add?tab=inventory`)}
            addIcon
          />
        )}
      </Box>
      <RoleTable />
    </>
  );
};

export default Role;
