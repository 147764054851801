import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu, IGlobalSetting } from "../../../types/global";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import GlobalSettingTable from "../../../components/Table/Setting/Global";
import { useModal } from "../../../hooks/use-modal";
import GlobalSettingModal from "../../../components/UI/Modal/GlobalSettingModal";
import { useState } from "react";
import { useSnackbar } from "notistack";
import ConfirmationModal from "../../../components/UI/Modal/ConfirmationModal";
import { useSearchParams } from "react-router-dom";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  GeneralModelType,
  ZoneFindUniqueQuery,
  ZonesFindAllQuery,
  useZoneCreateMutation,
  useZoneDeleteMutation,
  useZoneFindUniqueQuery,
  useZoneUpdateMutation,
  useZonesFindAllQuery,
} from "../../../generated/general";
import { useStateContext } from "../../../contexts/auth-context";

const ZoneSetting = () => {
  const { t } = useTranslation();
  const [deletingId, setDeletingId] = useState<number>();
  let [searchParams, setSearchParams] = useSearchParams();
  const {
    state: { permissions },
  } = useStateContext();

  const id = searchParams.get("id");

  const setSearchParamsHandler = (key: string, value: string) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const removeSearchParamsHandler = (key: string) => {
    searchParams.delete(key);
    setSearchParams(searchParams);
  };

  const { modal, openModalHandler, closeModalHandler } = useModal(
    undefined,
    () => removeSearchParamsHandler("id")
  );

  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.business.index"),
      to: "/setting/business",
    },
    {
      name: t("setting.zone.index"),
    },
  ];

  const graphQLClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: rowData, refetch } =
    useZonesFindAllQuery<ZonesFindAllQuery>(graphQLClientGeneral);

  const { data, isLoading, isSuccess } =
    useZoneFindUniqueQuery<ZoneFindUniqueQuery>(
      graphQLClientGeneral,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: Boolean(id),
      }
    );

  const { mutateAsync: createZone } =
    useZoneCreateMutation<Error>(graphQLClientGeneral);

  const { mutateAsync: updateZone } =
    useZoneUpdateMutation<Error>(graphQLClientGeneral);

  const { mutateAsync: deleteZone } =
    useZoneDeleteMutation<Error>(graphQLClientGeneral);

  const onAddHandler = async (data: IGlobalSetting) => {
    try {
      await createZone({
        data,
      });
      enqueueSnackbar(`เพิ่ม${t("setting.zone.index")}สำเร็จ`, {
        variant: "success",
      });
      await refetch();
    } catch (err) {
      enqueueSnackbar(`เพิ่ม${t("setting.zone.index")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const onEditHandler = (id?: number) => {
    if (id) {
      setSearchParamsHandler("id", id.toString());
      openModalHandler();
    }
  };

  const onUpdateHandler = async (data: IGlobalSetting) => {
    try {
      const { id, ...otherData } = data;
      await updateZone({
        uniqueInput: {
          id: id,
        },
        data: otherData,
      });
      enqueueSnackbar(`แก้ไข${t("setting.zone.index")}สำเร็จ`, {
        variant: "success",
      });
      await refetch();
    } catch (err) {
      enqueueSnackbar(`แก้ไข${t("setting.zone.index")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const onDeleteHandler = (id?: number) => {
    if (id) {
      setDeletingId(id);
      openConfirmationHandler();
    }
  };

  const deleteActionHandler = async () => {
    try {
      await deleteZone({
        uniqueInput: {
          id: deletingId,
        },
      });
      setDeletingId(undefined);
      enqueueSnackbar(`ลบ${t("setting.zone.index")}สำเร็จ`, {
        variant: "success",
      });
      await refetch();
    } catch (err) {
      enqueueSnackbar(`ลบ${t("setting.zone.index")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const {
    modal: confirmation,
    openModalHandler: openConfirmationHandler,
    closeModalHandler: closeConfirmationHandler,
    submitModalHandler: submitConfirmationHandler,
  } = useModal(deleteActionHandler);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
        <Typography variant="h5">{t("setting.zone.index")}</Typography>
        {permissions?.zone?.create && (
          <CustomizedButton
            title={t("button.add") + t("setting.zone.index")}
            variant="contained"
            onClick={openModalHandler}
          />
        )}
      </Box>
      <GlobalSettingTable
        data={rowData?.ZonesFindAll || []}
        onEditHandler={onEditHandler}
        onDeleteHandler={onDeleteHandler}
        tableType="zone"
      />
      <GlobalSettingModal
        open={modal}
        closeModalHandler={closeModalHandler}
        onAddHandler={onAddHandler}
        onUpdateHandler={onUpdateHandler}
        data={data?.ZoneFindUnique}
        isLoading={isLoading}
        isSuccess={isSuccess}
        type={GeneralModelType.Zone}
      />
      <ConfirmationModal
        title={`ยืนยันการลบ${t("setting.zone.index")}`}
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default ZoneSetting;
