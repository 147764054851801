import { Box, Grid, IconButton, Typography } from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { useFormContext, useWatch } from "react-hook-form";

import ControlledSelect from "../../../Controller/ControlledSelect";
import useInventoryControl from "../../../../hooks/Inventory/use-inventory-control";

import LabelInput from "../../../UI/LabelInput";
import {
  branchInfoFromSessionStorage,
  checkIsNotDraftOrEmptyStatus,
} from "../../../../utils/Global";
import { useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import {
  useWmsUniqueIdGenerateQuery,
  WmsUniqueIdGenerateQuery,
  WmsModelType,
} from "../../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { useStateContext } from "../../../../contexts/auth-context";

const GoodsTransferInfo = () => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    clearErrors,
  } = useFormContext();
  const { branch_id, is_hq } = branchInfoFromSessionStorage();

  const {
    state: { permissions },
  } = useStateContext();

  const watchSourceBranchId = useWatch({
    control,
    name: "source_branch_id",
  });

  const watchDestinationBranchId = useWatch({
    control,
    name: "destination_branch_id",
  });

  const watchDestinationWarehouseId = useWatch({
    control,
    name: "destination_warehouse_id",
  });

  const watchConsignmentBinLocationId = useWatch({
    control,
    name: "consignment_bin_location_id",
  });

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const { id } = useParams();

  const graphQLClient = createGraphQLClientWithMiddleware("wms");

  const { refetch: refetchUniqueId } =
    useWmsUniqueIdGenerateQuery<WmsUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: WmsModelType.GoodsTransfer,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const {
    branchesOptions: sourceBranchesOptions,
    warehousesOptions: sourceWarehousesOptions,
  } = useInventoryControl(watchSourceBranchId);

  const {
    branchesOptions: destinationBranchesOptions,
    warehousesOptions: destinationWarehousesOptions,
    isConsignment: destinationIsConsignment,
    binLocationOptions: destinationBinLocationOptions,
  } = useInventoryControl(
    watchDestinationBranchId,
    watchDestinationWarehouseId
  );

  const isNotDraft = checkIsNotDraftOrEmptyStatus(status || "");
  const isFinished =
    ["finished", "cancelled"].includes(status) ||
    (Boolean(id) && !permissions?.goods_transfer?.update);
  useEffect(() => {
    if (
      destinationIsConsignment &&
      destinationBinLocationOptions &&
      destinationBinLocationOptions.length > 0 &&
      !watchConsignmentBinLocationId
    ) {
      setValue(
        "consignment_bin_location_id",
        destinationBinLocationOptions[0].value
      );
      clearErrors("consignment_bin_location_id");
    }
  }, [
    clearErrors,
    destinationBinLocationOptions,
    destinationIsConsignment,
    setValue,
    watchConsignmentBinLocationId,
  ]);

  useEffect(() => {
    if (destinationIsConsignment) {
      setValue("is_consignment", destinationIsConsignment);
    } else {
      setValue("is_consignment", false);
    }
  }, [destinationIsConsignment, setValue]);

  return (
    <CustomizedBox margin={"0 0 1.5rem 0"}>
      <Typography color="primary.main" fontWeight={600} mb={2}>
        {t("sentence.detail")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid
          item
          xs={Boolean(id) ? 12 : 11}
          sm={Boolean(id) ? 12 : 11}
          md={4}
          lg={4}
          xl={4}
        >
          <Box sx={{ display: "flex" }}>
            <ControlledTextField
              name="unique_id"
              control={control}
              label={t(`sentence.unique_id`)}
              error={Boolean(errors.unique_id)}
              helperText={errors.unique_id?.message?.toString()}
              disabled={Boolean(id)}
              viewMode={isFinished}
              required
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={Boolean(id) ? 12 : 1}
          sm={Boolean(id) ? 12 : 1}
          md={4}
          lg={4}
          xl={4}
        >
          {!Boolean(id) && (
            <CustomizedTooltip
              title={`เรียก${t(`sentence.unique_id`)}`}
              enterNextDelay={200}
            >
              <IconButton
                onClick={() => {
                  generateUniqueId();
                }}
                color="primary"
              >
                <RestartAltOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {isFinished ? (
            <LabelInput
              label={t("inventory.goods_transfer.detail.info.source_branch")}
              value={getValues("source_branch.name")}
            />
          ) : (
            <ControlledSelect
              control={control}
              name="source_branch_id"
              label={t("inventory.goods_transfer.detail.info.source_branch")}
              options={
                is_hq === "true"
                  ? sourceBranchesOptions
                  : sourceBranchesOptions.filter(
                      (branch) => branch.value === parseInt(branch_id ?? "0")
                    )
              }
              disabled={isNotDraft}
              onChange={() => {
                setValue("source_warehouse_id", "");
              }}
              required
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {isFinished ? (
            <LabelInput
              label={t(
                "inventory.goods_transfer.detail.info.destination_branch"
              )}
              value={getValues("destination_branch.name")}
            />
          ) : (
            <ControlledSelect
              control={control}
              name="destination_branch_id"
              label={t(
                "inventory.goods_transfer.detail.info.destination_branch"
              )}
              options={destinationBranchesOptions}
              disabled={isNotDraft}
              onChange={() => {
                setValue("destination_warehouse_id", "");
                setValue("consignment_bin_location_id", "");
              }}
              required
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {isFinished ? (
            <LabelInput
              label={t("inventory.goods_transfer.detail.info.source_warehouse")}
              value={getValues("source_warehouse.name")}
            />
          ) : (
            <ControlledSelect
              control={control}
              name="source_warehouse_id"
              label={t("inventory.goods_transfer.detail.info.source_warehouse")}
              options={sourceWarehousesOptions}
              disabled={isNotDraft || sourceWarehousesOptions.length === 0}
              required
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {isFinished ? (
            <LabelInput
              label={t(
                "inventory.goods_transfer.detail.info.destination_warehouse"
              )}
              value={getValues("destination_warehouse.name")}
            />
          ) : (
            <ControlledSelect
              control={control}
              name="destination_warehouse_id"
              label={t(
                "inventory.goods_transfer.detail.info.destination_warehouse"
              )}
              options={destinationWarehousesOptions}
              disabled={isNotDraft || destinationWarehousesOptions.length === 0}
              onChange={() => {
                if (watchConsignmentBinLocationId) {
                  setValue("consignment_bin_location_id", "");
                }
              }}
              required
            />
          )}
        </Grid>
        {destinationIsConsignment ? (
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {isFinished ? (
              <LabelInput
                label={t(
                  "inventory.goods_transfer.detail.info.consignment_bin_location_id"
                )}
                value={
                  destinationBinLocationOptions.find(
                    (bin) =>
                      bin.value === getValues("consignment_bin_location_id")
                  )?.label
                }
              />
            ) : (
              <ControlledSelect
                control={control}
                name="consignment_bin_location_id"
                label={t(
                  "inventory.goods_transfer.detail.info.consignment_bin_location_id"
                )}
                options={destinationBinLocationOptions}
                disabled={isNotDraft}
                required
                error={Boolean(errors.consignment_bin_location_id)}
                helperText={errors?.consignment_bin_location_id?.message?.toString()}
              />
            )}
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
        )}
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <ControlledTextField
            control={control}
            name="remark"
            label={t("inventory.remark")}
            viewMode={isFinished}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default GoodsTransferInfo;
