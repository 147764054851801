import { Box, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useConfirmation } from "../../../../hooks/use-confirmation";
import { IBreadcrumbsAndMenu, IMenuOption } from "../../../../types/global";
import CustomizedBreadcrumbs from "../../../Custom/CustomizedBreadcrumbs";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import { useState } from "react";
import Confirmation from "../../../UI/Confirmation";
import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { ActivityLogSlideInPanel } from "../../../UI/SlideInPanel/ActivityLogSlideInPanel";
import { ActivityLogDocumentType } from "../../../../generated/general";
import { useStateContext } from "../../../../contexts/auth-context";

interface Props {
  cancelHandler: () => void;
  // editHandler: () => void;
  aggridStatus: string;
}

const GoodsAdjustmentHeaderBreadcrumbs = ({
  cancelHandler,
  // editHandler,
  aggridStatus,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  // const isOpenEditButton = Boolean(
  //   aggridStatus && ["wait_approve", "not_approved"].includes(aggridStatus)

  const {
    state: { permissions },
  } = useStateContext();
  // );
  const options: IMenuOption[] =
    // isOpenEditButton
    //   ? [
    //       { value: t("button.edit"), disabled: false },
    //       { value: t("status.cancelled"), disabled: false },
    //     ]
    //   :
    [{ value: t("status.cancelled"), disabled: false }];

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(cancelHandler);

  const { getValues } = useFormContext();

  const disabledMenuOption =
    !id ||
    aggridStatus === "finished" ||
    aggridStatus === "cancelled" ||
    !permissions?.goods_adjust?.cancel;

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.goods_adjustment.index"),
      to: "/inventory/goods-adjustment",
    },
    {
      name:
        id && Boolean(id)
          ? getValues("unique_id")
          : t(`${t("button.create")}${t("inventory.goods_adjustment.index")}`),
    },
  ];

  return (
    <>
      {id && (
        <ActivityLogSlideInPanel
          open={openDrawer}
          handleClose={() => setOpenDrawer(false)}
          documentId={id!}
          documentType={ActivityLogDocumentType.GoodsAdjustment}
        />
      )}
      <Box
        maxWidth={1040}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: 1040,
        }}
      >
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {!disabledMenuOption && (
            <CustomizedMenuOptions
              fullWidth
              size="medium"
              label={t("button.option")}
              options={options}
              onSelect={(e) => {
                const value = e.target as HTMLElement;
                switch (value.innerText) {
                  // case `${t("button.edit")}`:
                  //   editHandler();
                  //   break;
                  case `${t("status.cancelled")}`:
                    openConfirmationHandler();
                    break;
                  default:
                }
              }}
              sx={{
                "& .MuiButton-endIcon": {
                  marginLeft: "4px",
                },
                paddingX: "24px",
                height: "34px",
                width: "119px",
                borderRadius: "8px",
                fontSize: "13px",
              }}
              disabled={!id}
            />
          )}
          <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
            <IconButton
              onClick={() => {
                setOpenDrawer(true);
              }}
              sx={{
                color: (theme) => theme.palette.grey[500],
                padding: 0,
                marginLeft: "4px",
              }}
            >
              <RestoreOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        </Box>
      </Box>
      <Confirmation
        title="ยืนยันหากต้องการยกเลิกเอกสาร"
        message="หากยกเลิกแล้ว จะไม่สามารถเปลี่ยนแปลงได้"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default GoodsAdjustmentHeaderBreadcrumbs;
