import { useCallback, useEffect } from "react";
import { IDefaultForm } from "../../../../types/global";
import { useWatch } from "react-hook-form";
import { Typography } from "@mui/material";
import { numberFormatter } from "../../../../utils/Formatter/Global";

interface Props {
  control: IDefaultForm["control"];
  index: number;
  disabled: IDefaultForm["disabled"];
  setValue: IDefaultForm["setValue"];
  field: any;
}

const PreVatAmountCell = ({
  control,
  field,
  index,
  disabled,
  setValue,
}: Props) => {
  const qty =
    useWatch({
      control,
      name: `item_list.${index}.qty`,
    }) || 0;

  const pricePerUnit = useWatch({
    control,
    name: `item_list.${index}.price_per_unit`,
  });

  const discount = useWatch({
    control,
    name: `item_list.${index}.discount`,
  });

  const promotion_discount = useWatch({
    control,
    name: `item_list.${index}.promotion_discount`,
  });

  const calculation = useCallback(
    (
      qty: number,
      price_per_unit: number,
      discount: number,
      promotion_discount: number
    ) => {
      const preVatAmount =
        qty * price_per_unit - qty * discount - (promotion_discount ?? 0);
      return preVatAmount < 0 ? 0 : preVatAmount;
    },
    []
  );

  useEffect(() => {
    const preVatAmount = calculation(
      qty,
      pricePerUnit,
      discount,
      promotion_discount
    );
    setValue(`item_list.${index}.pre_vat_amount`, preVatAmount);
  }, [
    calculation,
    discount,
    index,
    pricePerUnit,
    qty,
    setValue,
    promotion_discount,
  ]);

  if (disabled) {
    return (
      <Typography fontSize={14}>
        {numberFormatter(field.pre_vat_amount)}
      </Typography>
    );
  }

  return (
    <Typography fontSize={14} align="right">
      {numberFormatter(
        calculation(qty, pricePerUnit, discount, promotion_discount)
      )}
    </Typography>
  );
};

export default PreVatAmountCell;
