import { useNavigate, useParams } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";

import { ReactInstance, useEffect, useRef } from "react";

import PurchasePdfLayout from "../../../components/UI/PurchasePdf/PurchasePdfLayout";

import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

import { IPdfInfo } from "../../../types/global";
import { IPurchaseItemList } from "../../../types/Purchase";
import {
  PurchaseOrderFindUniqueQuery,
  usePurchaseOrderFindUniqueQuery,
} from "../../../generated/purchase";

import { formatDate } from "../../../utils/Date";
import { renderAddressString } from "../../../utils/Formatter/Global";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useReactToPrint } from "react-to-print";
import { usePDFMultiplePage } from "../../../hooks/use-pdf-multiple-page";
import PurchaseOrderListPdfTable from "../../../components/Table/Pdf/PurchaseOrder";
import {
  ApprovalTemplateFindByDocumentTypeQuery,
  useApprovalTemplateFindByDocumentTypeQuery,
} from "../../../generated/general";
import { useStateContext } from "../../../contexts/auth-context";

const PurchaseOrderPdf = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    state: { authUser, permissions },
  } = useStateContext();

  const componentRef = useRef<ReactInstance | null>(null);

  const graphQLClient = createGraphQLClientWithMiddleware("purchase");
  const graphQLClientGeneral = createGraphQLClientWithMiddleware("general");

  const { data, isLoading, isSuccess } =
    usePurchaseOrderFindUniqueQuery<PurchaseOrderFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      }
    );

  useEffect(() => {
    if (isSuccess && permissions?.purchase_order?.view_self) {
      const createdBy = data?.PurchaseOrderFindUnique?.created_by;
      const relatedUserList = data?.PurchaseOrderFindUnique?.related_user_list;
      if (
        createdBy?.user_unique_id === authUser?.unique_id ||
        relatedUserList?.find(
          (user) => user.user_unique_id === authUser?.unique_id
        )
      ) {
        return;
      } else {
        navigate(`/unauthorized`, {
          replace: true,
        });
      }
    }
  }, [
    authUser?.unique_id,
    data?.PurchaseOrderFindUnique?.created_by,
    data?.PurchaseOrderFindUnique?.related_user_list,
    isSuccess,
    navigate,
    permissions?.purchase_order?.view_self,
  ]);

  const { data: approvalTemplates, isLoading: isApprovalLoading } =
    useApprovalTemplateFindByDocumentTypeQuery<ApprovalTemplateFindByDocumentTypeQuery>(
      graphQLClientGeneral,
      {
        documentType: "purchase_order",
      }
    );

  const { paginatedItems, startNumbers } = usePDFMultiplePage(
    data?.PurchaseOrderFindUnique, //data
    "purchase_order", // document type
    136.47, // name and description row width
    640, // max height without footer
    500, // max height with footer
    226.484, // left header minimum height
    226.484, // right header minimum height
    66.531, // left footer minimum height (you don't need to specify this if the document doesn't have remark and tags)
    87.922 // right footer minimum height (you don't need to specify this if the document doesn't have summary)
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const highlightSection: IPdfInfo = {
    headers: [
      "เลขที่เอกสาร",
      "วันที่ออกเอกสาร",
      "ต้องการภายในวันที่",
      "วันประมาณการณ์สินค้า",
    ],
    info: [
      data?.PurchaseOrderFindUnique.unique_id || "-",
      data?.PurchaseOrderFindUnique.issue_date
        ? formatDate(data?.PurchaseOrderFindUnique.issue_date)
        : "-",
      data?.PurchaseOrderFindUnique.within_date
        ? formatDate(data?.PurchaseOrderFindUnique.within_date)
        : "-",
      data?.PurchaseOrderFindUnique.expected_date
        ? formatDate(data?.PurchaseOrderFindUnique.expected_date)
        : "-",
    ],
  };

  const leftSection: IPdfInfo = {
    headers: [
      "ผู้ขาย",
      "ที่อยู่",
      "เลขประจำตัวผู้เสียภาษี",
      "เบอร์โทรศัพท์@โทรสาร",
      "อีเมล",
      "ที่อยู่จัดส่งผู้รับ",
    ],
    info: [
      `${data?.PurchaseOrderFindUnique.vendor_unique_id} - ${data?.PurchaseOrderFindUnique.vendor_details.name}`,
      renderAddressString(
        data?.PurchaseOrderFindUnique.vendor_details.billing_address
      ) || "-",
      data?.PurchaseOrderFindUnique.vendor_details.identity_no || "-",
      data?.PurchaseOrderFindUnique.vendor_details.phone +
        "@" +
        data?.PurchaseOrderFindUnique.vendor_details.fax || "-",
      data?.PurchaseOrderFindUnique.vendor_details.email || "-",
      renderAddressString(data?.PurchaseOrderFindUnique.delivery_address),
    ],
  };

  const rightSection: IPdfInfo = {
    headers: ["เครดิต", "คลังปลายทาง", "อ้างอิงถึง", "หมายเลขอ้างอิง"],
    info: [
      (data?.PurchaseOrderFindUnique.credit_day?.toString() || "-") +
        (data?.PurchaseOrderFindUnique.credit_day?.toString() ? " วัน" : ""),
      `${
        data?.PurchaseOrderFindUnique.destination_warehouse?.unique_id || ""
      } - ${data?.PurchaseOrderFindUnique.destination_warehouse?.name || ""}`,
      data?.PurchaseOrderFindUnique?.reference_document_list
        ?.map((list) => list.document_unique_id)
        .join(", ") || "-",
      data?.PurchaseOrderFindUnique.reference_no || "-",
    ],
  };

  if (id && (isLoading || isApprovalLoading)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mx: "auto" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h5">ใบสั่งซื้อ</Typography>
        <CustomizedButton
          title="พิมพ์เอกสาร"
          variant="outlined"
          onClick={handlePrint}
        />
      </Box>
      <Box ref={componentRef} sx={{ m: "auto" }}>
        {paginatedItems.map((item, index) => (
          <PurchasePdfLayout
            highlightSection={highlightSection}
            leftSection={leftSection}
            rightSection={rightSection}
            name={"ใบสั่งซื้อ"}
            data={data?.PurchaseOrderFindUnique}
            summary={index === paginatedItems.length - 1}
            multiplePage={paginatedItems.length > 1}
            page={index + 1}
            allPages={paginatedItems.length}
            documentType="purchase_order"
            approvalTemplates={
              approvalTemplates?.ApprovalTemplateFindByDocumentType || []
            }
          >
            <PurchaseOrderListPdfTable
              itemList={(item as IPurchaseItemList[]) || []}
              startNumber={startNumbers[index]}
              lastTable={
                paginatedItems[paginatedItems.length - 1].length === 0
                  ? index === paginatedItems.length - 2
                  : index === paginatedItems.length - 1
              }
              totalAmount={
                data?.PurchaseOrderFindUnique.total_amount
                  ? data?.PurchaseOrderFindUnique.total_amount
                  : 0
              }
            />
          </PurchasePdfLayout>
        ))}
      </Box>
    </Box>
  );
};

export default PurchaseOrderPdf;
