import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Any: { input: any; output: any; }
  AnyAggridInput: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  Json: { input: any; output: any; }
};

export type AccessPermission = {
  __typename?: 'AccessPermission';
  cancel: Scalars['Boolean']['output'];
  create: Scalars['Boolean']['output'];
  delete: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
  view: Scalars['Boolean']['output'];
  view_self: Scalars['Boolean']['output'];
};

export type AccessPermissionInput = {
  cancel: Scalars['Boolean']['input'];
  create: Scalars['Boolean']['input'];
  delete: Scalars['Boolean']['input'];
  update: Scalars['Boolean']['input'];
  view: Scalars['Boolean']['input'];
  view_self: Scalars['Boolean']['input'];
};

export type ActivityLog = {
  __typename?: 'ActivityLog';
  activity_detail?: Maybe<ActivityLogDetailObject>;
  activity_type?: Maybe<ActivityType>;
  created_by?: Maybe<CreatorObject>;
  created_date?: Maybe<Scalars['DateTime']['output']>;
  document_type?: Maybe<ActivityLogDocumentType>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  reference_id?: Maybe<Scalars['Int']['output']>;
};

export type ActivityLogByRefereceIdInput = {
  document_type: ActivityLogDocumentType;
  reference_id: Scalars['Int']['input'];
};

export type ActivityLogCopyObject = {
  __typename?: 'ActivityLogCopyObject';
  id?: Maybe<Scalars['Int']['output']>;
  unique_id?: Maybe<Scalars['String']['output']>;
};

export type ActivityLogCopyObjectInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type ActivityLogCreateInput = {
  activity_detail: ActivityLogDetailObjectInput;
  activity_type: ActivityType;
  created_by: CreatorInputObject;
  document_type: ActivityLogDocumentType;
  reference_id: Scalars['Int']['input'];
};

export type ActivityLogDetailObject = {
  __typename?: 'ActivityLogDetailObject';
  added_related_employee?: Maybe<ActivityLogRelatedEmployeeObject>;
  attachment_list?: Maybe<Array<Scalars['String']['output']>>;
  copied_from?: Maybe<ActivityLogCopyObject>;
  copied_to?: Maybe<ActivityLogCopyObject>;
  curr_status?: Maybe<Scalars['String']['output']>;
  deleted_related_employee?: Maybe<ActivityLogRelatedEmployeeObject>;
  message?: Maybe<Array<Scalars['String']['output']>>;
  prev_status?: Maybe<Scalars['String']['output']>;
  updated_fields?: Maybe<Scalars['Json']['output']>;
};

export type ActivityLogDetailObjectInput = {
  added_related_employee?: InputMaybe<ActivityLogRelatedEmployeeObjectInput>;
  attachment_list?: InputMaybe<Array<Scalars['String']['input']>>;
  copied_from?: InputMaybe<ActivityLogCopyObjectInput>;
  copied_to?: InputMaybe<ActivityLogCopyObjectInput>;
  curr_status?: InputMaybe<Scalars['String']['input']>;
  deleted_related_employee?: InputMaybe<ActivityLogRelatedEmployeeObjectInput>;
  message?: InputMaybe<Array<Scalars['String']['input']>>;
  prev_status?: InputMaybe<Scalars['String']['input']>;
  secondary_operation?: InputMaybe<ActivityType>;
  updated_fields?: InputMaybe<Scalars['Json']['input']>;
};

export enum ActivityLogDocumentType {
  CashSales = 'cash_sales',
  CreditNote = 'credit_note',
  Customer = 'customer',
  CustomerDeposit = 'customer_deposit',
  DeliveryOrder = 'delivery_order',
  GoodsAdjustment = 'goods_adjustment',
  GoodsIssue = 'goods_issue',
  GoodsReceive = 'goods_receive',
  GoodsReturn = 'goods_return',
  GoodsTransfer = 'goods_transfer',
  Item = 'item',
  Promotion = 'promotion',
  PurchaseInvoice = 'purchase_invoice',
  PurchaseOrder = 'purchase_order',
  PurchaseRequest = 'purchase_request',
  PurchaseReturn = 'purchase_return',
  Quotation = 'quotation',
  Role = 'role',
  SalesInvoice = 'sales_invoice',
  SalesOrder = 'sales_order',
  SalesReturn = 'sales_return',
  User = 'user',
  Vendor = 'vendor'
}

export type ActivityLogFindManyAggrid = {
  __typename?: 'ActivityLogFindManyAggrid';
  count: Scalars['Int']['output'];
  results: Array<ActivityLog>;
};

export type ActivityLogRelatedEmployeeObject = {
  __typename?: 'ActivityLogRelatedEmployeeObject';
  first_name?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  user_unique_id?: Maybe<Scalars['String']['output']>;
};

export type ActivityLogRelatedEmployeeObjectInput = {
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  user_unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type ActivityLogUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export enum ActivityType {
  AddRelatedEmployee = 'add_related_employee',
  Approve = 'approve',
  Cancel = 'cancel',
  CancelPendingScan = 'cancel_pending_scan',
  Comment = 'comment',
  Copy = 'copy',
  Create = 'create',
  Delete = 'delete',
  DeleteRelatedEmployee = 'delete_related_employee',
  Document = 'document',
  Edit = 'edit',
  Image = 'image',
  StatusChange = 'status_change'
}

export enum AdditionalDiscountType {
  Baht = 'baht',
  Percent = 'percent',
  Promotion = 'promotion'
}

export enum AggridArrayFilterType {
  Array = 'array'
}

export type AggridBooleanFilterModelInput = {
  filter?: InputMaybe<Scalars['String']['input']>;
  filterType?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AggridBooleanFilterModelType>;
};

export enum AggridBooleanFilterModelType {
  Equals = 'equals',
  NotEqual = 'notEqual'
}

export type AggridDateFilterModelInput = {
  filter?: InputMaybe<Scalars['String']['input']>;
  filterTo?: InputMaybe<Scalars['String']['input']>;
  filterType?: InputMaybe<AggridDateFilterType>;
  type?: InputMaybe<AggridDateFilterModelType>;
};

export enum AggridDateFilterModelType {
  Blank = 'blank',
  Empty = 'empty',
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  InRange = 'inRange',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  NotBlank = 'notBlank',
  NotEqual = 'notEqual'
}

export enum AggridDateFilterType {
  Date = 'date'
}

export type AggridFloatFilterModelInput = {
  condition1?: InputMaybe<AggridFloatFilterModelInputCondition>;
  condition2?: InputMaybe<AggridFloatFilterModelInputCondition>;
  filter?: InputMaybe<Scalars['Float']['input']>;
  filterTo?: InputMaybe<Scalars['Float']['input']>;
  filterType?: InputMaybe<AggridNumberFilterType>;
  operator?: InputMaybe<AggridOperatorFilter>;
  type?: InputMaybe<AggridNumberFilterModelType>;
};

export type AggridFloatFilterModelInputCondition = {
  filter?: InputMaybe<Scalars['Float']['input']>;
  filterTo?: InputMaybe<Scalars['Float']['input']>;
  filterType?: InputMaybe<AggridNumberFilterType>;
  type?: InputMaybe<AggridNumberFilterModelType>;
};

export type AggridGenericArrayFilterModelInput = {
  filterType?: InputMaybe<AggridArrayFilterType>;
  type?: InputMaybe<AggridGenericArrayFilterModelType>;
  values?: InputMaybe<Scalars['Any']['input']>;
};

export enum AggridGenericArrayFilterModelType {
  Equals = 'equals',
  Has = 'has',
  HasEvery = 'hasEvery',
  HasSome = 'hasSome',
  IsEmpty = 'isEmpty'
}

export enum AggridISimpleFilterModelType {
  Blank = 'blank',
  Contains = 'contains',
  Empty = 'empty',
  EndsWith = 'endsWith',
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  InRange = 'inRange',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  NotBlank = 'notBlank',
  NotContains = 'notContains',
  NotEqual = 'notEqual',
  StartsWith = 'startsWith'
}

export type AggridIntFilterModelInput = {
  condition1?: InputMaybe<AggridIntFilterModelInputCondition>;
  condition2?: InputMaybe<AggridIntFilterModelInputCondition>;
  filter?: InputMaybe<Scalars['Int']['input']>;
  filterTo?: InputMaybe<Scalars['Int']['input']>;
  filterType?: InputMaybe<AggridNumberFilterType>;
  operator?: InputMaybe<AggridOperatorFilter>;
  type?: InputMaybe<AggridNumberFilterModelType>;
};

export type AggridIntFilterModelInputCondition = {
  filter?: InputMaybe<Scalars['Int']['input']>;
  filterTo?: InputMaybe<Scalars['Int']['input']>;
  filterType?: InputMaybe<AggridNumberFilterType>;
  type?: InputMaybe<AggridNumberFilterModelType>;
};

export enum AggridNumberFilterModelType {
  Blank = 'blank',
  Empty = 'empty',
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  InRange = 'inRange',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  NotBlank = 'notBlank',
  NotEqual = 'notEqual'
}

export enum AggridNumberFilterType {
  Number = 'number'
}

export enum AggridObjectArrayFilterModelType {
  Every = 'every',
  None = 'none',
  Some = 'some'
}

export enum AggridObjectArrayFilterType {
  ObjectArray = 'objectArray'
}

export enum AggridOperatorFilter {
  And = 'AND',
  Or = 'OR'
}

export type AggridSetSingleFilterModelInput = {
  condition1?: InputMaybe<AggridSetSingleFilterModelInputCondition>;
  condition2?: InputMaybe<AggridSetSingleFilterModelInputCondition>;
  filterType?: InputMaybe<Scalars['String']['input']>;
  operator?: InputMaybe<AggridOperatorFilter>;
  values?: InputMaybe<Array<InputMaybe<Scalars['Any']['input']>>>;
};

export type AggridSetSingleFilterModelInputCondition = {
  filterType?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AggridSetSingleFilterModelType>;
  values?: InputMaybe<Array<InputMaybe<Scalars['Any']['input']>>>;
};

export enum AggridSetSingleFilterModelType {
  Blank = 'blank',
  Contains = 'contains',
  Equals = 'equals',
  NotBlank = 'notBlank',
  NotContains = 'notContains',
  NotEqual = 'notEqual'
}

export type AggridSortModelItem = {
  colId?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortOrder>;
};

export type AggridSortModelItemInput = {
  colId: Scalars['String']['input'];
  sort: SortOrder;
};

export type AggridStringFilterModelInput = {
  condition1?: InputMaybe<AggridStringFilterModelInputCondition>;
  condition2?: InputMaybe<AggridStringFilterModelInputCondition>;
  filter?: InputMaybe<Scalars['String']['input']>;
  filterTo?: InputMaybe<Scalars['String']['input']>;
  filterType?: InputMaybe<AggridTextFilterType>;
  mode?: InputMaybe<AggridStringModeOption>;
  operator?: InputMaybe<AggridOperatorFilter>;
  type?: InputMaybe<AggridStringFilterModelType>;
};

export type AggridStringFilterModelInputCondition = {
  filter?: InputMaybe<Scalars['String']['input']>;
  filterTo?: InputMaybe<Scalars['String']['input']>;
  filterType?: InputMaybe<AggridTextFilterType>;
  mode?: InputMaybe<AggridStringModeOption>;
  type?: InputMaybe<AggridStringFilterModelType>;
};

export enum AggridStringFilterModelType {
  Blank = 'blank',
  Contains = 'contains',
  Empty = 'empty',
  EndsWith = 'endsWith',
  Equals = 'equals',
  NotBlank = 'notBlank',
  NotContains = 'notContains',
  NotEqual = 'notEqual',
  StartsWith = 'startsWith'
}

export enum AggridStringModeOption {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type AggridTemplateInput = {
  endRow?: InputMaybe<Scalars['Int']['input']>;
  filterModel?: InputMaybe<Scalars['Any']['input']>;
  sortModel?: InputMaybe<Array<AggridSortModelItemInput>>;
  startRow?: InputMaybe<Scalars['Int']['input']>;
};

export enum AggridTextFilterType {
  Text = 'text'
}

export type ApprovalProgress = {
  __typename?: 'ApprovalProgress';
  approval_template?: Maybe<ApprovalTemplate>;
  approval_template_id: Scalars['Int']['output'];
  approver: User;
  approver_id: Scalars['Int']['output'];
  created_date: Scalars['DateTime']['output'];
  document_id: Scalars['Int']['output'];
  document_type: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  step_number: Scalars['Int']['output'];
  updated_date?: Maybe<Scalars['DateTime']['output']>;
};

export type ApprovalTemplate = {
  __typename?: 'ApprovalTemplate';
  approver_id_list?: Maybe<Array<Scalars['String']['output']>>;
  approver_list?: Maybe<Array<User>>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  required_approval: Scalars['Int']['output'];
  step_number: Scalars['Int']['output'];
  workflow_document_type: Scalars['String']['output'];
  workflow_step_number: Scalars['Int']['output'];
  workflow_template?: Maybe<WorkflowTemplate>;
};

export enum AvailableFor {
  Erp = 'erp',
  ErpPos = 'erp_pos'
}

export type BinLocation = {
  __typename?: 'BinLocation';
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  warehouse_level_3: WarehouseLevel3;
};

export type Branch = {
  __typename?: 'Branch';
  address: Scalars['Json']['output'];
  created_date?: Maybe<Scalars['DateTime']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  is_hq: Scalars['Boolean']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  short_name: Scalars['String']['output'];
  status: Scalars['Int']['output'];
  unique_id: Scalars['String']['output'];
  user_id_list: Array<Scalars['Int']['output']>;
  user_list?: Maybe<Array<Maybe<BranchUser>>>;
  zone?: Maybe<Scalars['String']['output']>;
};

export type BranchUser = {
  __typename?: 'BranchUser';
  branch?: Maybe<Branch>;
  branch_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type BundleItemDetail = {
  __typename?: 'BundleItemDetail';
  bundle_item: Item;
  bundle_item_id: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  item: Item;
  item_sku_detail?: Maybe<ItemSkuDetail>;
  item_unique_id: Scalars['String']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  qty: Scalars['Int']['output'];
  ratio_percentage?: Maybe<Scalars['Float']['output']>;
  sku_desc?: Maybe<Scalars['String']['output']>;
  sku_name: Scalars['String']['output'];
  sorting_order?: Maybe<Scalars['Int']['output']>;
  special_price?: Maybe<Scalars['Float']['output']>;
};

export type CashSales = {
  __typename?: 'CashSales';
  account_id?: Maybe<Scalars['String']['output']>;
  additional_discount?: Maybe<Scalars['Float']['output']>;
  additional_discount_type?: Maybe<AdditionalDiscountType>;
  aggrid_status?: Maybe<Scalars['String']['output']>;
  approval_step?: Maybe<Scalars['Int']['output']>;
  approver_list?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  branch: Branch;
  branch_id: Scalars['Int']['output'];
  contact_person_list?: Maybe<Array<Scalars['Json']['output']>>;
  created_by: Scalars['Json']['output'];
  created_date: Scalars['DateTime']['output'];
  credit_day?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_details?: Maybe<Scalars['Json']['output']>;
  customer_unique_id?: Maybe<Scalars['String']['output']>;
  deduction_document_list?: Maybe<Array<SalesDeduction>>;
  deduction_total_amount?: Maybe<Scalars['Float']['output']>;
  delivery_date?: Maybe<Scalars['DateTime']['output']>;
  due_date?: Maybe<Scalars['DateTime']['output']>;
  external_reference_no?: Maybe<Scalars['String']['output']>;
  flag_status?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  is_original_print?: Maybe<Scalars['Json']['output']>;
  issue_date?: Maybe<Scalars['DateTime']['output']>;
  item_list?: Maybe<Array<SalesItem>>;
  item_price_list?: Maybe<ItemPriceList>;
  item_price_list_id?: Maybe<Scalars['Int']['output']>;
  last_updated_by?: Maybe<Scalars['Json']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status?: Maybe<Scalars['String']['output']>;
  need_tax_invoice?: Maybe<Scalars['Boolean']['output']>;
  net_amount?: Maybe<Scalars['Float']['output']>;
  paid_net_amount?: Maybe<Scalars['Float']['output']>;
  paid_total_amount?: Maybe<Scalars['Float']['output']>;
  payment_list?: Maybe<Array<SalesPayment>>;
  payment_total_amount?: Maybe<Scalars['Float']['output']>;
  post_discount_amount?: Maybe<Scalars['Float']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_vat_type?: Maybe<PriceVatType>;
  promotion_list?: Maybe<Array<PromotionAppliedLog>>;
  reference_document_list?: Maybe<Array<Scalars['Json']['output']>>;
  reference_no?: Maybe<Scalars['String']['output']>;
  related_user_list?: Maybe<Array<Scalars['Json']['output']>>;
  remark?: Maybe<Scalars['String']['output']>;
  reviewer_list?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  sales_channel?: Maybe<SalesChannel>;
  sales_channel_id?: Maybe<Scalars['Int']['output']>;
  sales_contact?: Maybe<Array<Scalars['Json']['output']>>;
  sales_type?: Maybe<Scalars['String']['output']>;
  shipping_cost?: Maybe<Scalars['Float']['output']>;
  sub_status?: Maybe<Scalars['String']['output']>;
  tag_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  tag_list?: Maybe<Array<Tag>>;
  tax_invoice_date?: Maybe<Scalars['DateTime']['output']>;
  tax_invoice_no?: Maybe<Scalars['String']['output']>;
  template_remark_id?: Maybe<Scalars['String']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
  unique_id: Scalars['String']['output'];
  vat_0_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_7_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_amount?: Maybe<Scalars['Float']['output']>;
  vat_exempted_amount?: Maybe<Scalars['Float']['output']>;
  withholding_tax_amount?: Maybe<Scalars['Float']['output']>;
};

export type ContactAddressImportDataInput = {
  address_list?: InputMaybe<Array<Scalars['Json']['input']>>;
  unique_id: Scalars['String']['input'];
};

export type ContactAddressImportInput = {
  data: Array<ContactAddressImportDataInput>;
  import_type?: InputMaybe<ContactImportType>;
  priority: Scalars['Int']['input'];
  user_unique_id: Scalars['String']['input'];
};

export type ContactAttributeImportDataInput = {
  attribute_list?: InputMaybe<Array<Scalars['Json']['input']>>;
  unique_id: Scalars['String']['input'];
};

export type ContactAttributeImportInput = {
  data: Array<ContactAttributeImportDataInput>;
  import_type?: InputMaybe<ContactImportType>;
  priority: Scalars['Int']['input'];
  user_unique_id: Scalars['String']['input'];
};

export type ContactChannelImportDataInput = {
  contact_channel_list?: InputMaybe<Array<Scalars['Json']['input']>>;
  unique_id: Scalars['String']['input'];
};

export type ContactChannelImportInput = {
  data: Array<ContactChannelImportDataInput>;
  import_type?: InputMaybe<ContactImportType>;
  priority: Scalars['Int']['input'];
  user_unique_id: Scalars['String']['input'];
};

export type ContactImportInput = {
  data: Array<Scalars['Any']['input']>;
  import_field_type?: InputMaybe<ContactImportTypeInternal>;
  import_mode: ContactImportMode;
  import_type: ContactImportTypeInternal;
  priority: Scalars['Int']['input'];
  user_unique_id: Scalars['String']['input'];
};

export enum ContactImportMode {
  Create = 'create',
  Update = 'update'
}

export type ContactImportResult = {
  __typename?: 'ContactImportResult';
  detail?: Maybe<Scalars['Json']['output']>;
  jobId?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  timestamp?: Maybe<Scalars['DateTime']['output']>;
};

export enum ContactImportType {
  Customer = 'customer',
  Vendor = 'vendor'
}

export enum ContactImportTypeInternal {
  ContactAddress = 'contact_address',
  ContactAttribute = 'contact_attribute',
  ContactChannel = 'contact_channel',
  ContactPerson = 'contact_person',
  Customer = 'customer',
  Vendor = 'vendor'
}

export type ContactPersonImportDataInput = {
  contact_person_list?: InputMaybe<Array<Scalars['Json']['input']>>;
  unique_id: Scalars['String']['input'];
};

export type ContactPersonImportInput = {
  data: Array<ContactPersonImportDataInput>;
  import_type?: InputMaybe<ContactImportType>;
  priority: Scalars['Int']['input'];
  user_unique_id: Scalars['String']['input'];
};

export enum ContactReportType {
  Customer = 'customer',
  CustomerAddress = 'customer_address',
  CustomerAttribute = 'customer_attribute',
  CustomerContactChannel = 'customer_contact_channel',
  CustomerContactPerson = 'customer_contact_person',
  Vendor = 'vendor',
  VendorAddress = 'vendor_address',
  VendorAttribute = 'vendor_attribute',
  VendorContactChannel = 'vendor_contact_channel',
  VendorContactPerson = 'vendor_contact_person'
}

export enum ContactSubscriptionType {
  Import = 'import',
  Report = 'report'
}

export enum CouponType {
  ManyTime = 'many_time',
  OneTime = 'one_time'
}

export type CreatorInputObject = {
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  img_url?: InputMaybe<Array<Scalars['String']['input']>>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  user_unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type CreatorObject = {
  __typename?: 'CreatorObject';
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  img_url?: Maybe<Array<Scalars['String']['output']>>;
  last_name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  user_unique_id?: Maybe<Scalars['String']['output']>;
};

export enum CrmModelType {
  Customer = 'customer',
  Vendor = 'vendor'
}

export type Customer = {
  __typename?: 'Customer';
  address_list: Array<Scalars['Json']['output']>;
  attachment_list: Array<Scalars['Json']['output']>;
  attribute_list: Array<Scalars['Json']['output']>;
  branch_unique_id?: Maybe<Scalars['String']['output']>;
  business_type?: Maybe<Scalars['String']['output']>;
  business_type_detail?: Maybe<Scalars['String']['output']>;
  contact_channel_list: Array<Scalars['Json']['output']>;
  contact_person_list: Array<Scalars['Json']['output']>;
  created_date: Scalars['DateTime']['output'];
  credit_day?: Maybe<Scalars['Int']['output']>;
  credit_limit?: Maybe<Scalars['Float']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  img_url: Array<Scalars['String']['output']>;
  inactive_date?: Maybe<Scalars['DateTime']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  member_detail?: Maybe<Scalars['Json']['output']>;
  name: Scalars['String']['output'];
  nick_name?: Maybe<Scalars['String']['output']>;
  remark?: Maybe<Scalars['String']['output']>;
  remark_status?: Maybe<Scalars['String']['output']>;
  sales_channel?: Maybe<Scalars['String']['output']>;
  sales_person_list: Array<Scalars['Json']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  source_country?: Maybe<Scalars['String']['output']>;
  status: Scalars['Int']['output'];
  tag_id_list: Array<Scalars['Int']['output']>;
  tag_list: Array<Tag>;
  taxpayer_id?: Maybe<Scalars['String']['output']>;
  title_name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unique_id: Scalars['String']['output'];
  zone?: Maybe<Scalars['String']['output']>;
};

export type CustomerAggrid = {
  __typename?: 'CustomerAggrid';
  count: Scalars['Int']['output'];
  results: Array<Customer>;
};

export type CustomerCreateInput = {
  address_list: Array<Scalars['Json']['input']>;
  attachment_list: Array<Scalars['Json']['input']>;
  attribute_list: Array<Scalars['Json']['input']>;
  branch_unique_id?: InputMaybe<Scalars['String']['input']>;
  business_type?: InputMaybe<Scalars['String']['input']>;
  business_type_detail?: InputMaybe<Scalars['String']['input']>;
  contact_channel_list: Array<Scalars['Json']['input']>;
  contact_person_list: Array<Scalars['Json']['input']>;
  credit_day?: InputMaybe<Scalars['Int']['input']>;
  credit_limit?: InputMaybe<Scalars['Float']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  img_url: Array<Scalars['String']['input']>;
  inactive_date?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  member_detail?: InputMaybe<Scalars['Json']['input']>;
  name: Scalars['String']['input'];
  nick_name?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  remark_status?: InputMaybe<Scalars['String']['input']>;
  sales_channel?: InputMaybe<Scalars['String']['input']>;
  sales_person_list: Array<Scalars['Json']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  source_country?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['Int']['input'];
  tag_id_list: Array<Scalars['Int']['input']>;
  taxpayer_id?: InputMaybe<Scalars['String']['input']>;
  title_name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerDeposit = {
  __typename?: 'CustomerDeposit';
  additional_discount?: Maybe<Scalars['Float']['output']>;
  additional_discount_type?: Maybe<AdditionalDiscountType>;
  aggrid_status?: Maybe<Scalars['String']['output']>;
  branch?: Maybe<Branch>;
  branch_id: Scalars['Int']['output'];
  contact_person_list?: Maybe<Array<Scalars['Json']['output']>>;
  created_by: Scalars['Json']['output'];
  created_date: Scalars['DateTime']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  customer_details?: Maybe<Scalars['Json']['output']>;
  customer_unique_id?: Maybe<Scalars['String']['output']>;
  deposit_list?: Maybe<Array<CustomerDepositItem>>;
  due_date: Scalars['DateTime']['output'];
  external_reference_no?: Maybe<Scalars['String']['output']>;
  flag_status?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  is_original_print?: Maybe<Scalars['Json']['output']>;
  issue_date: Scalars['DateTime']['output'];
  last_updated_by?: Maybe<Scalars['Json']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status?: Maybe<Scalars['String']['output']>;
  need_tax_invoice?: Maybe<Scalars['Boolean']['output']>;
  net_amount?: Maybe<Scalars['Float']['output']>;
  paid_net_amount?: Maybe<Scalars['Float']['output']>;
  paid_total_amount?: Maybe<Scalars['Float']['output']>;
  payment_list?: Maybe<Array<SalesPayment>>;
  payment_total_amount?: Maybe<Scalars['Float']['output']>;
  post_discount_amount?: Maybe<Scalars['Float']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_vat_type?: Maybe<PriceVatType>;
  reference_no?: Maybe<Scalars['String']['output']>;
  related_user_list?: Maybe<Array<Scalars['Json']['output']>>;
  remaining_deposit_amount?: Maybe<Scalars['Float']['output']>;
  remark?: Maybe<Scalars['String']['output']>;
  sub_status?: Maybe<Scalars['String']['output']>;
  tag_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  tag_list?: Maybe<Array<Tag>>;
  tax_invoice_date?: Maybe<Scalars['DateTime']['output']>;
  tax_invoice_no?: Maybe<Scalars['String']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
  unique_id: Scalars['String']['output'];
  vat_0_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_7_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_amount?: Maybe<Scalars['Float']['output']>;
  vat_exempted_amount?: Maybe<Scalars['Float']['output']>;
  withholding_tax_amount?: Maybe<Scalars['Float']['output']>;
};

export type CustomerDepositItem = {
  __typename?: 'CustomerDepositItem';
  customer_deposit?: Maybe<CustomerDeposit>;
  customer_deposit_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  reference_document_id?: Maybe<Scalars['String']['output']>;
  remark?: Maybe<Scalars['String']['output']>;
  vat_percentage?: Maybe<Scalars['String']['output']>;
  withholding_tax_type?: Maybe<Scalars['String']['output']>;
  withholding_tax_value?: Maybe<Scalars['Float']['output']>;
};

export enum CustomerEligibility {
  All = 'all',
  SpecificCustomers = 'specific_customers',
  SpecificGroups = 'specific_groups'
}

export type CustomerImportDataInput = {
  address_list?: InputMaybe<Array<Scalars['Json']['input']>>;
  branch_unique_id?: InputMaybe<Scalars['String']['input']>;
  business_type?: InputMaybe<Scalars['String']['input']>;
  business_type_detail?: InputMaybe<Scalars['String']['input']>;
  contact_channel_list?: InputMaybe<Array<Scalars['Json']['input']>>;
  credit_day?: InputMaybe<Scalars['Int']['input']>;
  credit_limit?: InputMaybe<Scalars['Float']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  img_url?: InputMaybe<Array<Scalars['String']['input']>>;
  inactive_date?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  member_detail?: InputMaybe<Scalars['Json']['input']>;
  name: Scalars['String']['input'];
  nick_name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  remark_status?: InputMaybe<Scalars['String']['input']>;
  sales_channel?: InputMaybe<Scalars['String']['input']>;
  sales_person_unique_id?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  source_country?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  tag_list?: InputMaybe<Array<Scalars['String']['input']>>;
  taxpayer_id?: InputMaybe<Scalars['String']['input']>;
  title_name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  unique_id: Scalars['String']['input'];
  zone?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerImportInput = {
  data: Array<CustomerImportDataInput>;
  import_mode: ContactImportMode;
  priority: Scalars['Int']['input'];
  user_unique_id: Scalars['String']['input'];
};

export type CustomerUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerUpdateInput = {
  address_list: Array<Scalars['Json']['input']>;
  attachment_list: Array<Scalars['Json']['input']>;
  attribute_list: Array<Scalars['Json']['input']>;
  branch_unique_id?: InputMaybe<Scalars['String']['input']>;
  business_type?: InputMaybe<Scalars['String']['input']>;
  business_type_detail?: InputMaybe<Scalars['String']['input']>;
  contact_channel_list: Array<Scalars['Json']['input']>;
  contact_person_list: Array<Scalars['Json']['input']>;
  credit_day?: InputMaybe<Scalars['Int']['input']>;
  credit_limit?: InputMaybe<Scalars['Float']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  img_url: Array<Scalars['String']['input']>;
  inactive_date?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  member_detail?: InputMaybe<Scalars['Json']['input']>;
  name: Scalars['String']['input'];
  nick_name?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  remark_status?: InputMaybe<Scalars['String']['input']>;
  sales_channel?: InputMaybe<Scalars['String']['input']>;
  sales_person_list: Array<Scalars['Json']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  source_country?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['Int']['input'];
  tag_id_list: Array<Scalars['Int']['input']>;
  taxpayer_id?: InputMaybe<Scalars['String']['input']>;
  title_name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerView = {
  __typename?: 'CustomerView';
  address_list?: Maybe<Array<Scalars['Json']['output']>>;
  business_type?: Maybe<Scalars['String']['output']>;
  contact_channel_list?: Maybe<Array<Scalars['Json']['output']>>;
  contact_person_list?: Maybe<Array<Scalars['Json']['output']>>;
  credit_day?: Maybe<Scalars['Int']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sales_person_list?: Maybe<Array<Scalars['Json']['output']>>;
  status?: Maybe<Scalars['Int']['output']>;
  tag_list?: Maybe<Array<Scalars['String']['output']>>;
  taxpayer_id?: Maybe<Scalars['String']['output']>;
  unique_id: Scalars['String']['output'];
};

export type CustomerViewAggrid = {
  __typename?: 'CustomerViewAggrid';
  count: Scalars['Int']['output'];
  results: Array<CustomerView>;
};

export type DeliveryAttribute = {
  __typename?: 'DeliveryAttribute';
  height?: Maybe<Scalars['Float']['output']>;
  height_uom?: Maybe<Unit>;
  height_uom_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  item: Item;
  item_id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  length?: Maybe<Scalars['Float']['output']>;
  length_uom?: Maybe<Unit>;
  length_uom_id?: Maybe<Scalars['Int']['output']>;
  thick?: Maybe<Scalars['Float']['output']>;
  thick_uom?: Maybe<Unit>;
  thick_uom_id?: Maybe<Scalars['Int']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
  volume_uom?: Maybe<Unit>;
  volume_uom_id?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weight_uom?: Maybe<Unit>;
  weight_uom_id?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
  width_uom?: Maybe<Unit>;
  width_uom_id?: Maybe<Scalars['Int']['output']>;
};

export type DeliveryOrder = {
  __typename?: 'DeliveryOrder';
  aggrid_status?: Maybe<Scalars['String']['output']>;
  branch: Branch;
  branch_id: Scalars['Int']['output'];
  created_by?: Maybe<CreatorObject>;
  created_date: Scalars['DateTime']['output'];
  customer_details?: Maybe<Scalars['Json']['output']>;
  customer_unique_id?: Maybe<Scalars['String']['output']>;
  delivery_date?: Maybe<Scalars['DateTime']['output']>;
  delivery_detail?: Maybe<Scalars['Json']['output']>;
  delivery_type?: Maybe<Scalars['String']['output']>;
  destination_warehouse?: Maybe<Warehouse>;
  destination_warehouse_id?: Maybe<Scalars['Int']['output']>;
  exported_date?: Maybe<Scalars['DateTime']['output']>;
  flag_status?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  issue_date?: Maybe<Scalars['DateTime']['output']>;
  last_updated_by?: Maybe<CreatorObject>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status?: Maybe<Scalars['String']['output']>;
  reference_document_list?: Maybe<Array<ReferenceSalesDocument>>;
  reference_no?: Maybe<Scalars['String']['output']>;
  related_user_list?: Maybe<Array<Scalars['Json']['output']>>;
  remark?: Maybe<Scalars['String']['output']>;
  sub_status?: Maybe<Scalars['String']['output']>;
  tag_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  tag_list?: Maybe<Array<Tag>>;
  template_remark_id?: Maybe<Scalars['String']['output']>;
  trace_entry_list: Array<TraceEntry>;
  trace_entry_unique_id_list: Array<Scalars['String']['output']>;
  tracking_no?: Maybe<Scalars['String']['output']>;
  unique_id: Scalars['String']['output'];
};

export enum GenderType {
  Female = 'female',
  Male = 'male'
}

export enum InventoryDocumentType {
  GoodsAdjust = 'goods_adjust',
  GoodsIssue = 'goods_issue',
  GoodsReceive = 'goods_receive',
  GoodsReturn = 'goods_return',
  GoodsTransfer = 'goods_transfer'
}

export type InventoryReferenceLineItem = {
  __typename?: 'InventoryReferenceLineItem';
  line_item_document_type: Scalars['String']['output'];
  line_item_unique_id: Scalars['String']['output'];
};

export type InventoryReferenceLineItemInput = {
  line_item_document_type: Scalars['String']['input'];
  line_item_unique_id: Scalars['String']['input'];
};

export type Item = {
  __typename?: 'Item';
  attribute_list: Array<Scalars['Json']['output']>;
  barcode: Scalars['String']['output'];
  brand?: Maybe<Scalars['String']['output']>;
  bundle_item_detail_list: Array<BundleItemDetail>;
  category_group?: Maybe<Scalars['String']['output']>;
  created_by?: Maybe<Scalars['Json']['output']>;
  created_date: Scalars['DateTime']['output'];
  customer_insurance_duration?: Maybe<Scalars['Int']['output']>;
  deliver_uom?: Maybe<Unit>;
  deliver_uom_id?: Maybe<Scalars['Int']['output']>;
  delivery_attribute?: Maybe<DeliveryAttribute>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  img_url: Array<Scalars['String']['output']>;
  inactive_date?: Maybe<Scalars['DateTime']['output']>;
  insurance_detail?: Maybe<Scalars['String']['output']>;
  insurance_name?: Maybe<Scalars['String']['output']>;
  is_active: Scalars['Int']['output'];
  is_purchasable?: Maybe<Scalars['Boolean']['output']>;
  is_saleable?: Maybe<Scalars['Boolean']['output']>;
  is_stockable?: Maybe<Scalars['Boolean']['output']>;
  item_variant_key_list: Array<ItemVariantKey>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_category?: Maybe<Scalars['String']['output']>;
  max_stock_qty?: Maybe<Scalars['Float']['output']>;
  min_purchase_request_qty?: Maybe<Scalars['Int']['output']>;
  min_stock_qty?: Maybe<Scalars['Float']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  pre_sale?: Maybe<Scalars['Boolean']['output']>;
  purchase_standard_price?: Maybe<Scalars['Float']['output']>;
  purchase_uom?: Maybe<Unit>;
  purchase_uom_id?: Maybe<Scalars['Int']['output']>;
  purchase_vat_type?: Maybe<Scalars['String']['output']>;
  remark_status?: Maybe<Scalars['String']['output']>;
  reorder_point?: Maybe<Scalars['Float']['output']>;
  replenishment_stock?: Maybe<Scalars['Boolean']['output']>;
  sale_price?: Maybe<Scalars['Float']['output']>;
  sale_vat_type?: Maybe<Scalars['String']['output']>;
  sales_uom?: Maybe<Unit>;
  sales_uom_id?: Maybe<Scalars['Int']['output']>;
  segment?: Maybe<Scalars['String']['output']>;
  seller_insurance_duration?: Maybe<Scalars['Int']['output']>;
  seller_unique_id: Scalars['String']['output'];
  selling_point_list: Array<Scalars['Json']['output']>;
  series?: Maybe<Scalars['String']['output']>;
  set_attribute?: Maybe<Scalars['Json']['output']>;
  sku_detail_list: Array<ItemSkuDetail>;
  sku_list: Array<ItemSkuValue>;
  special_price?: Maybe<Scalars['Float']['output']>;
  stock_uom?: Maybe<Unit>;
  stock_uom_id?: Maybe<Scalars['Int']['output']>;
  sub_category?: Maybe<Scalars['String']['output']>;
  tag_id_list: Array<Scalars['Int']['output']>;
  tag_list: Array<Tag>;
  tracability?: Maybe<Tracability>;
  type?: Maybe<ItemType>;
  unique_id: Scalars['String']['output'];
  uom_group?: Maybe<UomGroup>;
  uom_group_id?: Maybe<Scalars['Int']['output']>;
  vendor_contact?: Maybe<Scalars['Json']['output']>;
};

export type ItemAdjustment = {
  __typename?: 'ItemAdjustment';
  adjusted_price?: Maybe<Scalars['Float']['output']>;
  current_price?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  item_name: Scalars['String']['output'];
  item_price_list?: Maybe<ItemPriceList>;
  item_price_list_id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  reference_unique_id?: Maybe<Scalars['String']['output']>;
  sku_name: Scalars['String']['output'];
};

export type ItemDeleteOutput = {
  __typename?: 'ItemDeleteOutput';
  active_document_list?: Maybe<Array<Scalars['String']['output']>>;
  is_deletable?: Maybe<Scalars['Boolean']['output']>;
  item?: Maybe<Item>;
};

export type ItemPriceList = {
  __typename?: 'ItemPriceList';
  adjusted_item_list?: Maybe<Array<Maybe<ItemAdjustment>>>;
  adjusted_price?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  is_active: Scalars['Int']['output'];
  is_increase: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  sales_channel?: Maybe<SalesChannel>;
  sales_channel_id?: Maybe<Scalars['Int']['output']>;
  start_date?: Maybe<Scalars['DateTime']['output']>;
  type: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
};

export type ItemSkuDetail = {
  __typename?: 'ItemSkuDetail';
  available_qty?: Maybe<Scalars['Int']['output']>;
  barcode?: Maybe<Scalars['String']['output']>;
  bundle_item_detail_list: Array<BundleItemDetail>;
  id: Scalars['Int']['output'];
  img_url: Array<Scalars['String']['output']>;
  item: Item;
  item_sku: ItemSkuValue;
  item_sku_qty?: Maybe<Array<ItemSkuQty>>;
  item_unique_id: Scalars['String']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  purchase_ordered_qty?: Maybe<Scalars['Int']['output']>;
  purchase_standard_price?: Maybe<Scalars['Float']['output']>;
  sale_committed_qty?: Maybe<Scalars['Int']['output']>;
  sale_price?: Maybe<Scalars['Float']['output']>;
  sku_name: Scalars['String']['output'];
  special_price?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  stock_qty?: Maybe<Scalars['Int']['output']>;
  total_sku_qty?: Maybe<Scalars['Json']['output']>;
};

export type ItemSkuQty = {
  __typename?: 'ItemSkuQty';
  available_qty: Scalars['Int']['output'];
  barcode: Scalars['String']['output'];
  bin_id?: Maybe<Scalars['Int']['output']>;
  bin_location?: Maybe<BinLocation>;
  bin_name?: Maybe<Scalars['String']['output']>;
  expired_date?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  item_sku_detail?: Maybe<ItemSkuDetail>;
  item_unique_id?: Maybe<Scalars['String']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  lot_date?: Maybe<Scalars['DateTime']['output']>;
  manufactured_date?: Maybe<Scalars['DateTime']['output']>;
  purchase_ordered_qty: Scalars['Int']['output'];
  sale_committed_qty: Scalars['Int']['output'];
  serial_no?: Maybe<Scalars['String']['output']>;
  sku_name: Scalars['String']['output'];
  stock_qty: Scalars['Int']['output'];
  transit_qty: Scalars['Int']['output'];
  warehouse?: Maybe<Warehouse>;
  warehouse_id?: Maybe<Scalars['Int']['output']>;
};

export enum ItemSkuQtyScalarFieldEnum {
  BinId = 'bin_id',
  Id = 'id',
  SkuName = 'sku_name',
  WarehouseId = 'warehouse_id'
}

export type ItemSkuValue = {
  __typename?: 'ItemSkuValue';
  id: Scalars['Int']['output'];
  item?: Maybe<Item>;
  item_unique_id?: Maybe<Scalars['String']['output']>;
  item_variant_value: Array<ItemVariantValue>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  sku_detail?: Maybe<ItemSkuDetail>;
  sku_name: Scalars['String']['output'];
  variant_key_id: Scalars['Int']['output'];
  variant_value_id: Scalars['Int']['output'];
};

export enum ItemType {
  Bundle = 'bundle',
  Normal = 'normal',
  Service = 'service',
  Variant = 'variant'
}

export type ItemVariantKey = {
  __typename?: 'ItemVariantKey';
  id: Scalars['Int']['output'];
  item: Item;
  item_unique_id: Scalars['String']['output'];
  item_variant_value_list: Array<ItemVariantValue>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  variant_key_name: Scalars['String']['output'];
};

export type ItemVariantValue = {
  __typename?: 'ItemVariantValue';
  id: Scalars['Int']['output'];
  item_sku: Array<ItemSkuValue>;
  item_unique_id: Scalars['String']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  variant_key: ItemVariantKey;
  variant_key_name: Scalars['String']['output'];
  variant_value_name: Scalars['String']['output'];
};

export enum MarketingModelType {
  PromotionSetting = 'promotion_setting'
}

export type Mutation = {
  __typename?: 'Mutation';
  ActivityLogCreate?: Maybe<ActivityLog>;
  CustomerCreate: Customer;
  CustomerDelete: Customer;
  CustomerUpdate: Customer;
  ImportContactAddress: ContactImportResult;
  ImportContactAttribute: ContactImportResult;
  ImportContactChannel: ContactImportResult;
  ImportContactPerson: ContactImportResult;
  ImportCustomer: ContactImportResult;
  ImportVendor: ContactImportResult;
  VendorCreate: Vendor;
  VendorDelete: Vendor;
  VendorUpdate: Vendor;
};


export type MutationActivityLogCreateArgs = {
  data: ActivityLogCreateInput;
};


export type MutationCustomerCreateArgs = {
  data: CustomerCreateInput;
};


export type MutationCustomerDeleteArgs = {
  uniqueInput: CustomerUniqueInput;
};


export type MutationCustomerUpdateArgs = {
  data: CustomerUpdateInput;
  uniqueInput: CustomerUniqueInput;
};


export type MutationImportContactAddressArgs = {
  importInput: ContactAddressImportInput;
};


export type MutationImportContactAttributeArgs = {
  importInput: ContactAttributeImportInput;
};


export type MutationImportContactChannelArgs = {
  importInput: ContactChannelImportInput;
};


export type MutationImportContactPersonArgs = {
  importInput: ContactPersonImportInput;
};


export type MutationImportCustomerArgs = {
  importInput: CustomerImportInput;
};


export type MutationImportVendorArgs = {
  importInput: VendorImportInput;
};


export type MutationVendorCreateArgs = {
  data: VendorCreateInput;
};


export type MutationVendorDeleteArgs = {
  uniqueInput: VendorUniqueInput;
};


export type MutationVendorUpdateArgs = {
  data: VendorUpdateInput;
  uniqueInput: VendorUniqueInput;
};

export enum PriceVatType {
  ExcludeVat = 'exclude_vat',
  IncludeVat = 'include_vat'
}

export type PromotionAppliedLog = {
  __typename?: 'PromotionAppliedLog';
  cash_sales?: Maybe<CashSales>;
  cash_sales_id?: Maybe<Scalars['Int']['output']>;
  conditional_item_id_list: Array<Scalars['Int']['output']>;
  coupon_code: Scalars['String']['output'];
  discount_rate?: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  promotion_id: Scalars['Int']['output'];
  promotion_unique_id?: Maybe<Scalars['String']['output']>;
  receive_type?: Maybe<ReceiveType>;
  redeem_item_list: Array<Scalars['Json']['output']>;
  total_discount_amount?: Maybe<Scalars['Decimal']['output']>;
};

export type PromotionAppliedLogUpsertInput = {
  conditional_item_id_list: Array<Scalars['Int']['input']>;
  coupon_code: Scalars['String']['input'];
  discount_rate?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  promotion_id: Scalars['Int']['input'];
  promotion_unique_id?: InputMaybe<Scalars['String']['input']>;
  receive_type?: InputMaybe<ReceiveType>;
  redeem_item_list: Array<Scalars['Json']['input']>;
  total_discount_amount?: InputMaybe<Scalars['Decimal']['input']>;
};

export enum PromotionCategory {
  DiscountFixPrice = 'discount_fix_price',
  DiscountOrder = 'discount_order',
  DiscountProduct = 'discount_product',
  FreeGift = 'free_gift'
}

export enum PromotionStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export enum PromotionType {
  AllPromotions = 'all_promotions',
  SingleCategoryPromotion = 'single_category_promotion',
  SinglePromotion = 'single_promotion'
}

export enum PurchaseCondition {
  AllItems = 'all_items',
  SpecificItems = 'specific_items'
}

export enum PurchaseDocumentType {
  PurchaseOrder = 'purchase_order',
  PurchaseRequest = 'purchase_request',
  PurchaseReturn = 'purchase_return'
}

export type PurchaseItem = {
  __typename?: 'PurchaseItem';
  barcode: Scalars['String']['output'];
  discount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  item_img_url?: Maybe<Array<Scalars['String']['output']>>;
  item_name: Scalars['String']['output'];
  item_sku_desc?: Maybe<Scalars['String']['output']>;
  item_sku_name: Scalars['String']['output'];
  item_unique_id: Scalars['String']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  po_qty?: Maybe<Scalars['Float']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_per_unit?: Maybe<Scalars['Float']['output']>;
  qty: Scalars['Float']['output'];
  qty_issued?: Maybe<Scalars['Float']['output']>;
  qty_ordered?: Maybe<Scalars['Float']['output']>;
  qty_received?: Maybe<Scalars['Float']['output']>;
  reference_document_type: PurchaseDocumentType;
  reference_line_item?: Maybe<ReferencePurchaseLineItem>;
  reference_unique_id: Scalars['String']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  stock_qty?: Maybe<Scalars['Float']['output']>;
  tracability: TraceAbilityEnum;
  unique_id: Scalars['String']['output'];
  uom_id: Scalars['Int']['output'];
  uom_name: Scalars['String']['output'];
  vat_percentage?: Maybe<Scalars['String']['output']>;
  withholding_tax_type?: Maybe<Scalars['String']['output']>;
  withholding_tax_value?: Maybe<Scalars['Float']['output']>;
};

export type PurchaseOrder = {
  __typename?: 'PurchaseOrder';
  additional_discount?: Maybe<Scalars['Float']['output']>;
  additional_discount_type?: Maybe<AdditionalDiscountType>;
  aggrid_status?: Maybe<Scalars['String']['output']>;
  approval_step?: Maybe<Scalars['Int']['output']>;
  approver_list?: Maybe<Array<Scalars['Json']['output']>>;
  branch: Branch;
  branch_id: Scalars['Int']['output'];
  created_by?: Maybe<CreatorObject>;
  created_date: Scalars['DateTime']['output'];
  credit_day?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  currency_display_name?: Maybe<Scalars['String']['output']>;
  delivery_address?: Maybe<Scalars['Json']['output']>;
  destination_warehouse?: Maybe<Warehouse>;
  destination_warehouse_id?: Maybe<Scalars['Int']['output']>;
  exchange_rate?: Maybe<Scalars['Float']['output']>;
  expected_date?: Maybe<Scalars['DateTime']['output']>;
  flag_status?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  issue_date?: Maybe<Scalars['DateTime']['output']>;
  item_list?: Maybe<Array<PurchaseItem>>;
  last_updated_by?: Maybe<CreatorObject>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status?: Maybe<Scalars['String']['output']>;
  net_amount?: Maybe<Scalars['Float']['output']>;
  post_discount_amount?: Maybe<Scalars['Float']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_vat_type?: Maybe<PriceVatType>;
  reference_document_id?: Maybe<Scalars['String']['output']>;
  reference_document_list?: Maybe<Array<PurchaseReferenceDocument>>;
  reference_no?: Maybe<Scalars['String']['output']>;
  related_user_list?: Maybe<Array<Scalars['Json']['output']>>;
  remark?: Maybe<Scalars['String']['output']>;
  reviewer_list?: Maybe<Array<Scalars['Json']['output']>>;
  sub_status?: Maybe<Scalars['String']['output']>;
  tag_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  tag_list?: Maybe<Array<Tag>>;
  template_remark_id?: Maybe<Scalars['String']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
  unique_id: Scalars['String']['output'];
  vat_0_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_7_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_amount?: Maybe<Scalars['Float']['output']>;
  vat_exempted_amount?: Maybe<Scalars['Float']['output']>;
  vendor_details?: Maybe<Scalars['Json']['output']>;
  vendor_unique_id?: Maybe<Scalars['String']['output']>;
  withholding_tax_amount?: Maybe<Scalars['Float']['output']>;
  within_date?: Maybe<Scalars['DateTime']['output']>;
};

export type PurchaseReferenceDocument = {
  __typename?: 'PurchaseReferenceDocument';
  document_id?: Maybe<Scalars['Int']['output']>;
  document_type?: Maybe<PurchaseReferenceType>;
  document_unique_id?: Maybe<Scalars['String']['output']>;
};

export type PurchaseReferenceDocumentInput = {
  document_id?: InputMaybe<Scalars['Int']['input']>;
  document_type?: InputMaybe<PurchaseReferenceType>;
  document_unique_id?: InputMaybe<Scalars['String']['input']>;
};

export enum PurchaseReferenceType {
  PurchaseOrder = 'purchase_order',
  PurchaseRequest = 'purchase_request',
  PurchaseReturn = 'purchase_return',
  SalesOrder = 'sales_order'
}

export type PurchaseRequest = {
  __typename?: 'PurchaseRequest';
  accepted_remark?: Maybe<Scalars['String']['output']>;
  aggrid_status?: Maybe<Scalars['String']['output']>;
  approval_step?: Maybe<Scalars['Int']['output']>;
  approver_list?: Maybe<Array<Scalars['Json']['output']>>;
  attachment_list?: Maybe<Array<Scalars['Json']['output']>>;
  branch: Branch;
  branch_id: Scalars['Int']['output'];
  created_by?: Maybe<CreatorObject>;
  created_date: Scalars['DateTime']['output'];
  delivery_address?: Maybe<Scalars['Json']['output']>;
  expected_date?: Maybe<Scalars['DateTime']['output']>;
  flag_status?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  issue_date?: Maybe<Scalars['DateTime']['output']>;
  item_list?: Maybe<Array<PurchaseItem>>;
  last_updated_by?: Maybe<CreatorObject>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status?: Maybe<Scalars['String']['output']>;
  reference_document_list?: Maybe<Array<PurchaseReferenceDocument>>;
  reference_no?: Maybe<Scalars['String']['output']>;
  related_user_list?: Maybe<Array<Scalars['Json']['output']>>;
  remark?: Maybe<Scalars['String']['output']>;
  requester?: Maybe<Scalars['Json']['output']>;
  reviewer_list?: Maybe<Array<Scalars['Json']['output']>>;
  sub_status?: Maybe<Scalars['String']['output']>;
  tag_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  tag_list?: Maybe<Array<Tag>>;
  template_remark_id?: Maybe<Scalars['String']['output']>;
  unique_id: Scalars['String']['output'];
  within_date?: Maybe<Scalars['DateTime']['output']>;
};

export type PurchaseReturn = {
  __typename?: 'PurchaseReturn';
  additional_discount?: Maybe<Scalars['Float']['output']>;
  additional_discount_type?: Maybe<AdditionalDiscountType>;
  aggrid_status?: Maybe<Scalars['String']['output']>;
  approval_step?: Maybe<Scalars['Int']['output']>;
  approver_list?: Maybe<Array<Scalars['Json']['output']>>;
  branch: Branch;
  branch_id: Scalars['Int']['output'];
  created_by?: Maybe<CreatorObject>;
  created_date: Scalars['DateTime']['output'];
  credit_day?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  currency_display_name?: Maybe<Scalars['String']['output']>;
  delivery_address?: Maybe<Scalars['Json']['output']>;
  delivery_date?: Maybe<Scalars['DateTime']['output']>;
  exchange_rate?: Maybe<Scalars['Float']['output']>;
  flag_status?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  issue_date?: Maybe<Scalars['DateTime']['output']>;
  item_list?: Maybe<Array<PurchaseItem>>;
  last_updated_by?: Maybe<CreatorObject>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status?: Maybe<Scalars['String']['output']>;
  net_amount?: Maybe<Scalars['Float']['output']>;
  post_discount_amount?: Maybe<Scalars['Float']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_vat_type?: Maybe<PriceVatType>;
  reason_to_return?: Maybe<Scalars['String']['output']>;
  reference_document_list?: Maybe<Array<PurchaseReferenceDocument>>;
  reference_no?: Maybe<Scalars['String']['output']>;
  related_user_list?: Maybe<Array<Scalars['Json']['output']>>;
  remark?: Maybe<Scalars['String']['output']>;
  reviewer_list?: Maybe<Array<Scalars['Json']['output']>>;
  sub_status?: Maybe<Scalars['String']['output']>;
  tag_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  tag_list?: Maybe<Array<Tag>>;
  template_remark_id?: Maybe<Scalars['String']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
  unique_id: Scalars['String']['output'];
  vat_0_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_7_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_amount?: Maybe<Scalars['Float']['output']>;
  vat_exempted_amount?: Maybe<Scalars['Float']['output']>;
  vendor_details?: Maybe<Scalars['Json']['output']>;
  vendor_unique_id?: Maybe<Scalars['String']['output']>;
  withholding_tax_amount?: Maybe<Scalars['Float']['output']>;
};

export type Query = {
  __typename?: 'Query';
  ActivityLogFindManyAggrid: ActivityLogFindManyAggrid;
  ActivityLogFindManyByReferenceId: Array<ActivityLog>;
  ActivityLogFindUnique?: Maybe<ActivityLog>;
  CurrentImportRequest?: Maybe<Scalars['Json']['output']>;
  CurrentReportRequest?: Maybe<Scalars['Json']['output']>;
  CustomerFindUnique?: Maybe<Customer>;
  CustomersFindAll: Array<Customer>;
  CustomersFindManyAggrid: CustomerAggrid;
  CustomersView: CustomerViewAggrid;
  GenerateReport: ReportResult;
  GenerateReportNoQueue?: Maybe<Scalars['String']['output']>;
  HistoryImportRequest?: Maybe<Scalars['Json']['output']>;
  HistoryReportRequest?: Maybe<Scalars['Json']['output']>;
  UniqueIdGenerate: Scalars['String']['output'];
  UniqueIdIsExist: Scalars['Boolean']['output'];
  ValidateContactImport: ValidateContactImportResult;
  ValidateCustomerIsInUse?: Maybe<ValidateCustomerIsInUseResult>;
  ValidateVendorIsInUse?: Maybe<ValidateVendorIsInUseResult>;
  VendorFindUnique?: Maybe<Vendor>;
  VendorsFindAll: Array<Vendor>;
  VendorsFindManyAggrid: VendorAggrid;
  VendorsView: VendorViewAggrid;
};


export type QueryActivityLogFindManyAggridArgs = {
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']['input']>;
};


export type QueryActivityLogFindManyByReferenceIdArgs = {
  findManyInput: ActivityLogByRefereceIdInput;
};


export type QueryActivityLogFindUniqueArgs = {
  uniqueInput?: InputMaybe<ActivityLogUniqueInput>;
};


export type QueryCurrentImportRequestArgs = {
  userUniqueId: Scalars['String']['input'];
};


export type QueryCustomerFindUniqueArgs = {
  uniqueInput: CustomerUniqueInput;
};


export type QueryCustomersFindManyAggridArgs = {
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']['input']>;
};


export type QueryCustomersViewArgs = {
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']['input']>;
};


export type QueryGenerateReportArgs = {
  input?: InputMaybe<ReportGenerateInput>;
};


export type QueryGenerateReportNoQueueArgs = {
  input?: InputMaybe<ReportGenerateInput>;
};


export type QueryHistoryImportRequestArgs = {
  userUniqueId: Scalars['String']['input'];
};


export type QueryUniqueIdGenerateArgs = {
  modelType: CrmModelType;
};


export type QueryUniqueIdIsExistArgs = {
  modelType: CrmModelType;
  uniqueId: Scalars['String']['input'];
};


export type QueryValidateContactImportArgs = {
  validateInput: ValidateContactImportInput;
};


export type QueryValidateCustomerIsInUseArgs = {
  validateCustomerInput?: InputMaybe<ValidateCustomerInput>;
};


export type QueryValidateVendorIsInUseArgs = {
  validateVendorInput?: InputMaybe<ValidateVendorInput>;
};


export type QueryVendorFindUniqueArgs = {
  uniqueInput: VendorUniqueInput;
};


export type QueryVendorsFindManyAggridArgs = {
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']['input']>;
};


export type QueryVendorsViewArgs = {
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']['input']>;
};

export type Quotation = {
  __typename?: 'Quotation';
  accepted_date?: Maybe<Scalars['DateTime']['output']>;
  accepted_remark?: Maybe<Scalars['String']['output']>;
  account_id?: Maybe<Scalars['String']['output']>;
  additional_discount?: Maybe<Scalars['Float']['output']>;
  additional_discount_type?: Maybe<AdditionalDiscountType>;
  aggrid_status?: Maybe<Scalars['String']['output']>;
  approval_step?: Maybe<Scalars['Int']['output']>;
  approver_list?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  attachment_list?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  branch: Branch;
  branch_id: Scalars['Int']['output'];
  contact_person_list?: Maybe<Array<Scalars['Json']['output']>>;
  contact_unique_id?: Maybe<Scalars['String']['output']>;
  created_by: Scalars['Json']['output'];
  created_date: Scalars['DateTime']['output'];
  credit_day?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_details?: Maybe<Scalars['Json']['output']>;
  customer_unique_id?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['DateTime']['output']>;
  flag_status?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  issue_date?: Maybe<Scalars['DateTime']['output']>;
  item_list?: Maybe<Array<Maybe<SalesItem>>>;
  item_price_list?: Maybe<ItemPriceList>;
  item_price_list_id?: Maybe<Scalars['Int']['output']>;
  last_updated_by?: Maybe<Scalars['Json']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status?: Maybe<Scalars['String']['output']>;
  net_amount?: Maybe<Scalars['Float']['output']>;
  post_discount_amount?: Maybe<Scalars['Float']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_vat_type?: Maybe<PriceVatType>;
  reference_no?: Maybe<Scalars['String']['output']>;
  related_user_list?: Maybe<Array<Scalars['Json']['output']>>;
  remark?: Maybe<Scalars['String']['output']>;
  reviewer_list?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  sales_channel?: Maybe<SalesChannel>;
  sales_channel_id?: Maybe<Scalars['Int']['output']>;
  sales_contact?: Maybe<Array<Scalars['Json']['output']>>;
  sales_type?: Maybe<Scalars['String']['output']>;
  shipping_cost?: Maybe<Scalars['Float']['output']>;
  sub_status?: Maybe<Scalars['String']['output']>;
  tag_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  tag_list?: Maybe<Array<Maybe<Tag>>>;
  template_remark_id?: Maybe<Scalars['String']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
  unique_id: Scalars['String']['output'];
  vat_0_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_7_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_amount?: Maybe<Scalars['Float']['output']>;
  vat_exempted_amount?: Maybe<Scalars['Float']['output']>;
  withholding_tax_amount?: Maybe<Scalars['Float']['output']>;
};

export enum ReceiveType {
  Amount = 'amount',
  Percent = 'percent'
}

export type ReferencePurchaseLineItem = {
  __typename?: 'ReferencePurchaseLineItem';
  line_item_document_type: Scalars['String']['output'];
  line_item_document_unique_id: Scalars['String']['output'];
  line_item_unique_id: Scalars['String']['output'];
};

export type ReferencePurchaseLineItemInput = {
  line_item_document_type: Scalars['String']['input'];
  line_item_document_unique_id: Scalars['String']['input'];
  line_item_unique_id: Scalars['String']['input'];
};

export type ReferenceSalesDocument = {
  __typename?: 'ReferenceSalesDocument';
  document_id?: Maybe<Scalars['Int']['output']>;
  document_type?: Maybe<SalesReferenceDocumentType>;
  document_unique_id?: Maybe<Scalars['String']['output']>;
};

export type ReferenceSalesDocumentInput = {
  document_id?: InputMaybe<Scalars['Int']['input']>;
  document_type?: InputMaybe<SalesReferenceDocumentType>;
  document_unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type ReferenceSalesLineItem = {
  __typename?: 'ReferenceSalesLineItem';
  line_item_document_type: Scalars['String']['output'];
  line_item_unique_id: Scalars['String']['output'];
};

export type ReferenceSalesLineItemInput = {
  line_item_document_type: Scalars['String']['input'];
  line_item_unique_id: Scalars['String']['input'];
};

export type ReportGenerateInput = {
  end_date?: InputMaybe<Scalars['DateTime']['input']>;
  report_type: ContactReportType;
  start_date?: InputMaybe<Scalars['DateTime']['input']>;
  user_unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type ReportResult = {
  __typename?: 'ReportResult';
  detail?: Maybe<Scalars['Json']['output']>;
  jobId?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  timestamp?: Maybe<Scalars['DateTime']['output']>;
};

export type RolePermission = {
  __typename?: 'RolePermission';
  approval?: Maybe<AccessPermission>;
  branch?: Maybe<AccessPermission>;
  cash_sales?: Maybe<AccessPermission>;
  company?: Maybe<AccessPermission>;
  contact?: Maybe<AccessPermission>;
  contact_export?: Maybe<AccessPermission>;
  contact_setting?: Maybe<AccessPermission>;
  customer?: Maybe<AccessPermission>;
  customer_deposit?: Maybe<AccessPermission>;
  delivery_order?: Maybe<AccessPermission>;
  employee?: Maybe<AccessPermission>;
  goods_adjust?: Maybe<AccessPermission>;
  goods_issue?: Maybe<AccessPermission>;
  goods_receive?: Maybe<AccessPermission>;
  goods_return?: Maybe<AccessPermission>;
  goods_transfer?: Maybe<AccessPermission>;
  inventory?: Maybe<AccessPermission>;
  inventory_export?: Maybe<AccessPermission>;
  inventory_report?: Maybe<AccessPermission>;
  inventory_setting?: Maybe<AccessPermission>;
  item?: Maybe<AccessPermission>;
  logistic?: Maybe<AccessPermission>;
  logistic_export?: Maybe<AccessPermission>;
  logistic_report?: Maybe<AccessPermission>;
  logistic_setting?: Maybe<AccessPermission>;
  marketing?: Maybe<AccessPermission>;
  marketing_export?: Maybe<AccessPermission>;
  price_list?: Maybe<AccessPermission>;
  promotion?: Maybe<AccessPermission>;
  purchase?: Maybe<AccessPermission>;
  purchase_export?: Maybe<AccessPermission>;
  purchase_order?: Maybe<AccessPermission>;
  purchase_report?: Maybe<AccessPermission>;
  purchase_request?: Maybe<AccessPermission>;
  purchase_return?: Maybe<AccessPermission>;
  quotation?: Maybe<AccessPermission>;
  role?: Maybe<AccessPermission>;
  sales?: Maybe<AccessPermission>;
  sales_export?: Maybe<AccessPermission>;
  sales_invoice?: Maybe<AccessPermission>;
  sales_order?: Maybe<AccessPermission>;
  sales_report?: Maybe<AccessPermission>;
  sales_return?: Maybe<AccessPermission>;
  sales_setting?: Maybe<AccessPermission>;
  setting?: Maybe<AccessPermission>;
  user_account?: Maybe<AccessPermission>;
  user_export?: Maybe<AccessPermission>;
  vendor?: Maybe<AccessPermission>;
  zone?: Maybe<AccessPermission>;
};

export type RolePermissionInput = {
  approval?: InputMaybe<AccessPermissionInput>;
  branch?: InputMaybe<AccessPermissionInput>;
  cash_sales?: InputMaybe<AccessPermissionInput>;
  company?: InputMaybe<AccessPermissionInput>;
  contact?: InputMaybe<AccessPermissionInput>;
  contact_export?: InputMaybe<AccessPermissionInput>;
  contact_setting?: InputMaybe<AccessPermissionInput>;
  customer?: InputMaybe<AccessPermissionInput>;
  customer_deposit?: InputMaybe<AccessPermissionInput>;
  delivery_order?: InputMaybe<AccessPermissionInput>;
  employee?: InputMaybe<AccessPermissionInput>;
  goods_adjust?: InputMaybe<AccessPermissionInput>;
  goods_issue?: InputMaybe<AccessPermissionInput>;
  goods_receive?: InputMaybe<AccessPermissionInput>;
  goods_return?: InputMaybe<AccessPermissionInput>;
  goods_transfer?: InputMaybe<AccessPermissionInput>;
  inventory?: InputMaybe<AccessPermissionInput>;
  inventory_export?: InputMaybe<AccessPermissionInput>;
  inventory_report?: InputMaybe<AccessPermissionInput>;
  inventory_setting?: InputMaybe<AccessPermissionInput>;
  item?: InputMaybe<AccessPermissionInput>;
  logistic?: InputMaybe<AccessPermissionInput>;
  logistic_export?: InputMaybe<AccessPermissionInput>;
  logistic_report?: InputMaybe<AccessPermissionInput>;
  logistic_setting?: InputMaybe<AccessPermissionInput>;
  marketing?: InputMaybe<AccessPermissionInput>;
  marketing_export?: InputMaybe<AccessPermissionInput>;
  price_list?: InputMaybe<AccessPermissionInput>;
  promotion?: InputMaybe<AccessPermissionInput>;
  purchase?: InputMaybe<AccessPermissionInput>;
  purchase_export?: InputMaybe<AccessPermissionInput>;
  purchase_order?: InputMaybe<AccessPermissionInput>;
  purchase_report?: InputMaybe<AccessPermissionInput>;
  purchase_request?: InputMaybe<AccessPermissionInput>;
  purchase_return?: InputMaybe<AccessPermissionInput>;
  quotation?: InputMaybe<AccessPermissionInput>;
  role?: InputMaybe<AccessPermissionInput>;
  sales?: InputMaybe<AccessPermissionInput>;
  sales_export?: InputMaybe<AccessPermissionInput>;
  sales_invoice?: InputMaybe<AccessPermissionInput>;
  sales_order?: InputMaybe<AccessPermissionInput>;
  sales_report?: InputMaybe<AccessPermissionInput>;
  sales_return?: InputMaybe<AccessPermissionInput>;
  sales_setting?: InputMaybe<AccessPermissionInput>;
  setting?: InputMaybe<AccessPermissionInput>;
  user_account?: InputMaybe<AccessPermissionInput>;
  user_export?: InputMaybe<AccessPermissionInput>;
  vendor?: InputMaybe<AccessPermissionInput>;
  zone?: InputMaybe<AccessPermissionInput>;
};

export type SalesChannel = {
  __typename?: 'SalesChannel';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  item_price_list?: Maybe<Array<Maybe<ItemPriceList>>>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_en: Scalars['String']['output'];
  name_th: Scalars['String']['output'];
  quotation_list?: Maybe<Array<Maybe<Quotation>>>;
  unique_id: Scalars['String']['output'];
};

export enum SalesChannelType {
  All = 'all',
  SpecificChannels = 'specific_channels',
  SpecificSales = 'specific_sales'
}

export type SalesDeduction = {
  __typename?: 'SalesDeduction';
  branch_id: Scalars['Int']['output'];
  credit_balance?: Maybe<Scalars['Float']['output']>;
  deduction_amount?: Maybe<Scalars['Float']['output']>;
  deduction_issue_date?: Maybe<Scalars['DateTime']['output']>;
  document_type?: Maybe<Scalars['String']['output']>;
  document_unique_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  reference_document_type: SalesReferenceDocumentType;
  reference_unique_id: Scalars['String']['output'];
  remark?: Maybe<Scalars['String']['output']>;
};

export type SalesInvoice = {
  __typename?: 'SalesInvoice';
  additional_discount?: Maybe<Scalars['Float']['output']>;
  additional_discount_type?: Maybe<AdditionalDiscountType>;
  aggrid_status?: Maybe<Scalars['String']['output']>;
  approval_step?: Maybe<Scalars['Int']['output']>;
  approver_list?: Maybe<Array<Scalars['Json']['output']>>;
  branch?: Maybe<Branch>;
  branch_id: Scalars['Int']['output'];
  contact_person_list?: Maybe<Array<Scalars['Json']['output']>>;
  created_by: Scalars['Json']['output'];
  created_date: Scalars['DateTime']['output'];
  credit_day?: Maybe<Scalars['Int']['output']>;
  currency: Scalars['String']['output'];
  customer_details: Scalars['Json']['output'];
  customer_unique_id: Scalars['String']['output'];
  deduction_document_list?: Maybe<Array<SalesDeduction>>;
  deduction_total_amount?: Maybe<Scalars['Float']['output']>;
  due_date: Scalars['DateTime']['output'];
  external_reference_no?: Maybe<Scalars['String']['output']>;
  flag_status?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  is_original_print?: Maybe<Scalars['Json']['output']>;
  issue_date: Scalars['DateTime']['output'];
  item_list?: Maybe<Array<SalesItem>>;
  item_price_list?: Maybe<ItemPriceList>;
  item_price_list_id?: Maybe<Scalars['Int']['output']>;
  last_updated_by?: Maybe<Scalars['Json']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status?: Maybe<Scalars['String']['output']>;
  need_tax_invoice?: Maybe<Scalars['Boolean']['output']>;
  net_amount?: Maybe<Scalars['Float']['output']>;
  paid_net_amount?: Maybe<Scalars['Float']['output']>;
  paid_total_amount?: Maybe<Scalars['Float']['output']>;
  payment_list?: Maybe<Array<SalesPayment>>;
  payment_total_amount?: Maybe<Scalars['Float']['output']>;
  post_discount_amount?: Maybe<Scalars['Float']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_vat_type: PriceVatType;
  reference_document_list?: Maybe<Array<Scalars['Json']['output']>>;
  reference_no?: Maybe<Scalars['String']['output']>;
  related_user_list?: Maybe<Array<Scalars['Json']['output']>>;
  remark?: Maybe<Scalars['String']['output']>;
  reviewer_list?: Maybe<Array<Scalars['Json']['output']>>;
  sales_channel?: Maybe<SalesChannel>;
  sales_channel_id?: Maybe<Scalars['Int']['output']>;
  sales_contact?: Maybe<Array<Scalars['Json']['output']>>;
  sales_type?: Maybe<Scalars['String']['output']>;
  sub_status?: Maybe<Scalars['String']['output']>;
  tag_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  tag_list?: Maybe<Array<Tag>>;
  tax_invoice_date?: Maybe<Scalars['DateTime']['output']>;
  tax_invoice_no?: Maybe<Scalars['String']['output']>;
  template_remark_id?: Maybe<Scalars['String']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
  unique_id: Scalars['String']['output'];
  vat_0_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_7_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_amount?: Maybe<Scalars['Float']['output']>;
  vat_exempted_amount?: Maybe<Scalars['Float']['output']>;
  withholding_tax_amount?: Maybe<Scalars['Float']['output']>;
};

export type SalesItem = {
  __typename?: 'SalesItem';
  barcode?: Maybe<Scalars['String']['output']>;
  branch_id: Scalars['Int']['output'];
  discount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  item_id?: Maybe<Scalars['Int']['output']>;
  item_img_url?: Maybe<Array<Scalars['String']['output']>>;
  item_name?: Maybe<Scalars['String']['output']>;
  item_sku_desc?: Maybe<Scalars['String']['output']>;
  item_sku_name: Scalars['String']['output'];
  item_unique_id: Scalars['String']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_per_unit?: Maybe<Scalars['Float']['output']>;
  promotion_discount?: Maybe<Scalars['Float']['output']>;
  qty: Scalars['Float']['output'];
  qty_invoiced?: Maybe<Scalars['Float']['output']>;
  qty_issued?: Maybe<Scalars['Float']['output']>;
  qty_received?: Maybe<Scalars['Float']['output']>;
  qty_returned?: Maybe<Scalars['Float']['output']>;
  qty_shipped?: Maybe<Scalars['Float']['output']>;
  qty_to_ship?: Maybe<Scalars['Float']['output']>;
  reference_document_type: SalesReferenceDocumentType;
  reference_line_item?: Maybe<ReferenceSalesLineItem>;
  reference_unique_id: Scalars['String']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  so_qty?: Maybe<Scalars['Float']['output']>;
  stock_qty?: Maybe<Scalars['Float']['output']>;
  tracability: TraceAbilityEnum;
  unique_id: Scalars['String']['output'];
  uom_id?: Maybe<Scalars['Int']['output']>;
  uom_name?: Maybe<Scalars['String']['output']>;
  vat_percentage?: Maybe<Scalars['String']['output']>;
  volume: Scalars['Float']['output'];
  weight: Scalars['Float']['output'];
  withholding_tax_type?: Maybe<Scalars['String']['output']>;
  withholding_tax_value?: Maybe<Scalars['Float']['output']>;
};

export enum SalesModelType {
  CashSales = 'cash_sales',
  CashSalesTax = 'cash_sales_tax',
  CustomerDeposit = 'customer_deposit',
  CustomerDepositTax = 'customer_deposit_tax',
  Quotation = 'quotation',
  SalesInvoice = 'sales_invoice',
  SalesInvoiceTax = 'sales_invoice_tax',
  SalesOrder = 'sales_order',
  SalesReturn = 'sales_return'
}

export type SalesOrder = {
  __typename?: 'SalesOrder';
  account_id?: Maybe<Scalars['String']['output']>;
  additional_discount?: Maybe<Scalars['Float']['output']>;
  additional_discount_type?: Maybe<AdditionalDiscountType>;
  aggrid_status?: Maybe<Scalars['String']['output']>;
  approval_step?: Maybe<Scalars['Int']['output']>;
  approver_list?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  branch: Branch;
  branch_id: Scalars['Int']['output'];
  contact_person_list?: Maybe<Array<Scalars['Json']['output']>>;
  created_by: Scalars['Json']['output'];
  created_date: Scalars['DateTime']['output'];
  credit_day?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_details?: Maybe<Scalars['Json']['output']>;
  customer_unique_id?: Maybe<Scalars['String']['output']>;
  delivery_date?: Maybe<Scalars['DateTime']['output']>;
  delivery_status?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['DateTime']['output']>;
  external_reference_no?: Maybe<Scalars['String']['output']>;
  flag_status?: Maybe<Array<Scalars['String']['output']>>;
  good_issue_status?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  issue_date?: Maybe<Scalars['DateTime']['output']>;
  item_list?: Maybe<Array<SalesItem>>;
  item_price_list?: Maybe<ItemPriceList>;
  item_price_list_id?: Maybe<Scalars['Int']['output']>;
  last_updated_by?: Maybe<Scalars['Json']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status?: Maybe<Scalars['String']['output']>;
  net_amount?: Maybe<Scalars['Float']['output']>;
  payments_receipt_status?: Maybe<Scalars['String']['output']>;
  post_discount_amount?: Maybe<Scalars['Float']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_vat_type?: Maybe<PriceVatType>;
  reference_document_list?: Maybe<Array<Scalars['Json']['output']>>;
  reference_no?: Maybe<Scalars['String']['output']>;
  related_user_list?: Maybe<Array<Scalars['Json']['output']>>;
  remark?: Maybe<Scalars['String']['output']>;
  reviewer_list?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  sales_channel?: Maybe<SalesChannel>;
  sales_channel_id?: Maybe<Scalars['Int']['output']>;
  sales_contact?: Maybe<Array<Scalars['Json']['output']>>;
  sales_invoice_status?: Maybe<Scalars['String']['output']>;
  sales_type?: Maybe<Scalars['String']['output']>;
  shipping_cost?: Maybe<Scalars['Float']['output']>;
  sub_status?: Maybe<Scalars['String']['output']>;
  tag_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  tag_list?: Maybe<Array<Tag>>;
  template_remark_id?: Maybe<Scalars['String']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
  unique_id: Scalars['String']['output'];
  vat_0_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_7_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_amount?: Maybe<Scalars['Float']['output']>;
  vat_exempted_amount?: Maybe<Scalars['Float']['output']>;
  withholding_tax_amount?: Maybe<Scalars['Float']['output']>;
};

export type SalesPayment = {
  __typename?: 'SalesPayment';
  branch_id: Scalars['Int']['output'];
  cheque_date?: Maybe<Scalars['DateTime']['output']>;
  cheque_no?: Maybe<Scalars['String']['output']>;
  cheque_provider?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  is_withholding_tax?: Maybe<Scalars['Boolean']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  payment_amount?: Maybe<Scalars['Float']['output']>;
  payment_channel_id?: Maybe<Scalars['Int']['output']>;
  payment_channel_name?: Maybe<Scalars['String']['output']>;
  payment_channel_payment_name?: Maybe<Scalars['String']['output']>;
  payment_channel_unique_id?: Maybe<Scalars['String']['output']>;
  payment_date?: Maybe<Scalars['DateTime']['output']>;
  reference_document_type: SalesReferenceDocumentType;
  reference_unique_id: Scalars['String']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  withholding_tax_amount?: Maybe<Scalars['Float']['output']>;
  withholding_tax_rate?: Maybe<Scalars['String']['output']>;
};

export enum SalesReferenceDocumentType {
  CashSales = 'cash_sales',
  CustomerDeposit = 'customer_deposit',
  Quotation = 'quotation',
  SalesInvoice = 'sales_invoice',
  SalesOrder = 'sales_order',
  SalesReturn = 'sales_return',
  TaxInvoice = 'tax_invoice'
}

export type SalesReturn = {
  __typename?: 'SalesReturn';
  additional_discount?: Maybe<Scalars['Float']['output']>;
  additional_discount_type?: Maybe<AdditionalDiscountType>;
  aggrid_status?: Maybe<Scalars['String']['output']>;
  approval_step?: Maybe<Scalars['Int']['output']>;
  approver_list?: Maybe<Array<Scalars['Json']['output']>>;
  branch?: Maybe<Branch>;
  branch_id: Scalars['Int']['output'];
  contact_person_list?: Maybe<Array<Scalars['Json']['output']>>;
  created_by: Scalars['Json']['output'];
  created_date: Scalars['DateTime']['output'];
  credit_day?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_details?: Maybe<Scalars['Json']['output']>;
  customer_unique_id?: Maybe<Scalars['String']['output']>;
  due_date: Scalars['DateTime']['output'];
  external_reference_no?: Maybe<Scalars['String']['output']>;
  flag_status?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  is_effect_stock?: Maybe<Scalars['Boolean']['output']>;
  issue_date: Scalars['DateTime']['output'];
  item_list?: Maybe<Array<SalesItem>>;
  item_price_list?: Maybe<ItemPriceList>;
  item_price_list_id?: Maybe<Scalars['Int']['output']>;
  last_updated_by?: Maybe<Scalars['Json']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status?: Maybe<Scalars['String']['output']>;
  net_amount?: Maybe<Scalars['Float']['output']>;
  post_discount_amount?: Maybe<Scalars['Float']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_vat_type?: Maybe<PriceVatType>;
  reason_to_return?: Maybe<Scalars['String']['output']>;
  reference_document_list?: Maybe<Array<Scalars['Json']['output']>>;
  reference_no?: Maybe<Scalars['String']['output']>;
  related_user_list?: Maybe<Array<Scalars['Json']['output']>>;
  remark?: Maybe<Scalars['String']['output']>;
  reviewer_list?: Maybe<Array<Scalars['Json']['output']>>;
  sales_channel?: Maybe<SalesChannel>;
  sales_channel_id?: Maybe<Scalars['Int']['output']>;
  sales_contact?: Maybe<Array<Scalars['Json']['output']>>;
  sales_type?: Maybe<Scalars['String']['output']>;
  sub_status?: Maybe<Scalars['String']['output']>;
  tag_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  tag_list?: Maybe<Array<Tag>>;
  template_remark_id?: Maybe<Scalars['String']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
  unique_id: Scalars['String']['output'];
  vat_0_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_7_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_amount?: Maybe<Scalars['Float']['output']>;
  vat_exempted_amount?: Maybe<Scalars['Float']['output']>;
  withholding_tax_amount?: Maybe<Scalars['Float']['output']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type Subscription = {
  __typename?: 'Subscription';
  SubscriptionImportReport?: Maybe<SubscriptionStatus>;
};

export type SubscriptionImportResult = {
  __typename?: 'SubscriptionImportResult';
  import?: Maybe<Scalars['Json']['output']>;
  import_field_type?: Maybe<ContactImportTypeInternal>;
  import_type?: Maybe<ContactImportTypeInternal>;
};

export type SubscriptionReportResult = {
  __typename?: 'SubscriptionReportResult';
  report?: Maybe<Scalars['Json']['output']>;
  report_type?: Maybe<ContactReportType>;
};

export type SubscriptionStatus = {
  __typename?: 'SubscriptionStatus';
  import_result?: Maybe<SubscriptionImportResult>;
  report_result?: Maybe<SubscriptionReportResult>;
  status?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<ContactSubscriptionType>;
};

export type Tag = {
  __typename?: 'Tag';
  document_type: TagDocumentType;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
};

export enum TagDocumentType {
  Batch = 'batch',
  CashSales = 'cash_sales',
  Contact = 'contact',
  CreditNote = 'credit_note',
  Customer = 'customer',
  CustomerDeposit = 'customer_deposit',
  DeliveryOrder = 'delivery_order',
  DeliveryTrip = 'delivery_trip',
  Employee = 'employee',
  Event = 'event',
  GoodsAdjust = 'goods_adjust',
  GoodsIssue = 'goods_issue',
  GoodsReceive = 'goods_receive',
  GoodsTransfer = 'goods_transfer',
  Inventory = 'inventory',
  Item = 'item',
  PaymentMade = 'payment_made',
  PaymentReceive = 'payment_receive',
  Project = 'project',
  Promotion = 'promotion',
  Purchase = 'purchase',
  PurchaseInvoice = 'purchase_invoice',
  PurchaseOrder = 'purchase_order',
  PurchaseRequest = 'purchase_request',
  PurchaseReturn = 'purchase_return',
  Quotation = 'quotation',
  Role = 'role',
  Sale = 'sale',
  SalesInvoice = 'sales_invoice',
  SalesOrder = 'sales_order',
  SalesReturn = 'sales_return',
  Serial = 'serial',
  Task = 'task',
  Vendor = 'vendor'
}

export enum Tracability {
  Lot = 'lot',
  Normal = 'normal',
  Serial = 'serial'
}

export enum TraceAbilityEnum {
  Lot = 'lot',
  Normal = 'normal',
  Serial = 'serial'
}

export type TraceEntry = {
  __typename?: 'TraceEntry';
  additional_cost?: Maybe<Scalars['Float']['output']>;
  barcode?: Maybe<Scalars['String']['output']>;
  cost_price?: Maybe<Scalars['Float']['output']>;
  created_date: Scalars['DateTime']['output'];
  delivery_order_unique_id?: Maybe<Scalars['String']['output']>;
  destination_bin_location?: Maybe<BinLocation>;
  destination_bin_location_id?: Maybe<Scalars['Int']['output']>;
  destination_scanned_by?: Maybe<Scalars['Json']['output']>;
  destination_scanned_date?: Maybe<Scalars['DateTime']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  document_item_qty?: Maybe<Scalars['Int']['output']>;
  expired_date?: Maybe<Scalars['DateTime']['output']>;
  goods_adjust_unique_id?: Maybe<Scalars['String']['output']>;
  goods_issue_unique_id?: Maybe<Scalars['String']['output']>;
  goods_receive_unique_id?: Maybe<Scalars['String']['output']>;
  goods_return_unique_id?: Maybe<Scalars['String']['output']>;
  goods_transfer_unique_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  is_stockable: Scalars['Boolean']['output'];
  item_img_url: Array<Scalars['String']['output']>;
  item_name: Scalars['String']['output'];
  item_unique_id: Scalars['String']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  lot_date?: Maybe<Scalars['DateTime']['output']>;
  manufactured_date?: Maybe<Scalars['DateTime']['output']>;
  po_reference_unique_id?: Maybe<Scalars['String']['output']>;
  posted_date?: Maybe<Scalars['DateTime']['output']>;
  posted_qty?: Maybe<Scalars['Int']['output']>;
  qty: Scalars['Int']['output'];
  reference_line_item?: Maybe<InventoryReferenceLineItem>;
  reference_unique_id?: Maybe<Scalars['String']['output']>;
  remark?: Maybe<Scalars['String']['output']>;
  scanned_by?: Maybe<Scalars['Json']['output']>;
  serial_no?: Maybe<Scalars['String']['output']>;
  sku_name: Scalars['String']['output'];
  source_bin_location?: Maybe<BinLocation>;
  source_bin_location_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  stock_qty?: Maybe<Scalars['Int']['output']>;
  tracability: Tracability;
  type: InventoryDocumentType;
  unique_id: Scalars['String']['output'];
  uom?: Maybe<Unit>;
  uom_id?: Maybe<Scalars['Int']['output']>;
};

export type TraceEntryCreateInput = {
  additional_cost?: InputMaybe<Scalars['Float']['input']>;
  barcode?: InputMaybe<Scalars['String']['input']>;
  cost_price?: InputMaybe<Scalars['Float']['input']>;
  delivery_order_unique_id?: InputMaybe<Scalars['String']['input']>;
  destination_bin_location_id?: InputMaybe<Scalars['Int']['input']>;
  destination_scanned_by?: InputMaybe<Scalars['Json']['input']>;
  destination_scanned_date?: InputMaybe<Scalars['DateTime']['input']>;
  discount?: InputMaybe<Scalars['Float']['input']>;
  document_item_qty?: InputMaybe<Scalars['Int']['input']>;
  expired_date?: InputMaybe<Scalars['DateTime']['input']>;
  goods_adjust_unique_id?: InputMaybe<Scalars['String']['input']>;
  goods_issue_unique_id?: InputMaybe<Scalars['String']['input']>;
  goods_receive_unique_id?: InputMaybe<Scalars['String']['input']>;
  goods_return_unique_id?: InputMaybe<Scalars['String']['input']>;
  goods_transfer_unique_id?: InputMaybe<Scalars['String']['input']>;
  is_stockable?: InputMaybe<Scalars['Boolean']['input']>;
  item_img_url?: InputMaybe<Array<Scalars['String']['input']>>;
  item_name: Scalars['String']['input'];
  item_unique_id: Scalars['String']['input'];
  lot_date?: InputMaybe<Scalars['DateTime']['input']>;
  manufactured_date?: InputMaybe<Scalars['DateTime']['input']>;
  po_reference_unique_id?: InputMaybe<Scalars['String']['input']>;
  posted_date?: InputMaybe<Scalars['DateTime']['input']>;
  posted_qty?: InputMaybe<Scalars['Int']['input']>;
  qty: Scalars['Int']['input'];
  reference_line_item?: InputMaybe<InventoryReferenceLineItemInput>;
  reference_unique_id?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  scanned_by?: InputMaybe<Scalars['Json']['input']>;
  serial_no?: InputMaybe<Scalars['String']['input']>;
  sku_name: Scalars['String']['input'];
  source_bin_location_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  stock_qty?: InputMaybe<Scalars['Int']['input']>;
  tracability: Tracability;
  type?: InputMaybe<InventoryDocumentType>;
  unique_id: Scalars['String']['input'];
  uom_id?: InputMaybe<Scalars['Int']['input']>;
};

export type Unit = {
  __typename?: 'Unit';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_en?: Maybe<Scalars['String']['output']>;
  name_th: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
};

export enum UnitScalarField {
  NameTh = 'name_th',
  UniqueId = 'unique_id'
}

export type UomConversion = {
  __typename?: 'UomConversion';
  base_uom_rate: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  target_uom: Unit;
  target_uom_id: Scalars['Int']['output'];
  target_uom_rate: Scalars['Float']['output'];
  uom_group: UomGroup;
  uom_group_id: Scalars['Int']['output'];
};

export type UomGroup = {
  __typename?: 'UomGroup';
  base_uom: Unit;
  base_uom_id: Scalars['Int']['output'];
  convertable_uom_list: Array<Unit>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
  uom_conversion_list: Array<UomConversion>;
};

export enum UsageLimitType {
  Limited = 'limited',
  NoLimit = 'no_limit'
}

export type User = {
  __typename?: 'User';
  company_name?: Maybe<Scalars['String']['output']>;
  created_date?: Maybe<Scalars['DateTime']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  employee_type?: Maybe<Scalars['String']['output']>;
  first_name: Scalars['String']['output'];
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  img_url?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  inactive_date?: Maybe<Scalars['DateTime']['output']>;
  is_sales_employee?: Maybe<Scalars['Boolean']['output']>;
  last_login_branch?: Maybe<Scalars['Int']['output']>;
  last_name: Scalars['String']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  nick_name?: Maybe<Scalars['String']['output']>;
  original_branch: Scalars['Int']['output'];
  original_branch_object?: Maybe<Branch>;
  phone?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  remark_status?: Maybe<Scalars['String']['output']>;
  role_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  start_date?: Maybe<Scalars['DateTime']['output']>;
  status: Scalars['Int']['output'];
  title_name?: Maybe<Scalars['String']['output']>;
  unique_id: Scalars['String']['output'];
  user_permission?: Maybe<UserPermission>;
};

export type UserPermission = {
  __typename?: 'UserPermission';
  approval?: Maybe<AccessPermission>;
  branch?: Maybe<AccessPermission>;
  cash_sales?: Maybe<AccessPermission>;
  company?: Maybe<AccessPermission>;
  contact?: Maybe<AccessPermission>;
  contact_export?: Maybe<AccessPermission>;
  contact_setting?: Maybe<AccessPermission>;
  customer?: Maybe<AccessPermission>;
  customer_deposit?: Maybe<AccessPermission>;
  delivery_order?: Maybe<AccessPermission>;
  employee?: Maybe<AccessPermission>;
  goods_adjust?: Maybe<AccessPermission>;
  goods_issue?: Maybe<AccessPermission>;
  goods_receive?: Maybe<AccessPermission>;
  goods_return?: Maybe<AccessPermission>;
  goods_transfer?: Maybe<AccessPermission>;
  id: Scalars['Int']['output'];
  inventory?: Maybe<AccessPermission>;
  inventory_export?: Maybe<AccessPermission>;
  inventory_report?: Maybe<AccessPermission>;
  inventory_setting?: Maybe<AccessPermission>;
  is_custom: Scalars['Boolean']['output'];
  item?: Maybe<AccessPermission>;
  logistic?: Maybe<AccessPermission>;
  logistic_export?: Maybe<AccessPermission>;
  logistic_report?: Maybe<AccessPermission>;
  logistic_setting?: Maybe<AccessPermission>;
  marketing?: Maybe<AccessPermission>;
  marketing_export?: Maybe<AccessPermission>;
  price_list?: Maybe<AccessPermission>;
  promotion?: Maybe<AccessPermission>;
  purchase?: Maybe<AccessPermission>;
  purchase_export?: Maybe<AccessPermission>;
  purchase_order?: Maybe<AccessPermission>;
  purchase_report?: Maybe<AccessPermission>;
  purchase_request?: Maybe<AccessPermission>;
  purchase_return?: Maybe<AccessPermission>;
  quotation?: Maybe<AccessPermission>;
  role?: Maybe<AccessPermission>;
  sales?: Maybe<AccessPermission>;
  sales_export?: Maybe<AccessPermission>;
  sales_invoice?: Maybe<AccessPermission>;
  sales_order?: Maybe<AccessPermission>;
  sales_report?: Maybe<AccessPermission>;
  sales_return?: Maybe<AccessPermission>;
  sales_setting?: Maybe<AccessPermission>;
  setting?: Maybe<AccessPermission>;
  user_account?: Maybe<AccessPermission>;
  user_export?: Maybe<AccessPermission>;
  user_id: Scalars['Int']['output'];
  vendor?: Maybe<AccessPermission>;
  zone?: Maybe<AccessPermission>;
};

export type UserPermissionInput = {
  approval?: InputMaybe<AccessPermissionInput>;
  branch?: InputMaybe<AccessPermissionInput>;
  cash_sales?: InputMaybe<AccessPermissionInput>;
  company?: InputMaybe<AccessPermissionInput>;
  contact?: InputMaybe<AccessPermissionInput>;
  contact_export?: InputMaybe<AccessPermissionInput>;
  contact_setting?: InputMaybe<AccessPermissionInput>;
  customer?: InputMaybe<AccessPermissionInput>;
  customer_deposit?: InputMaybe<AccessPermissionInput>;
  delivery_order?: InputMaybe<AccessPermissionInput>;
  employee?: InputMaybe<AccessPermissionInput>;
  goods_adjust?: InputMaybe<AccessPermissionInput>;
  goods_issue?: InputMaybe<AccessPermissionInput>;
  goods_receive?: InputMaybe<AccessPermissionInput>;
  goods_return?: InputMaybe<AccessPermissionInput>;
  goods_transfer?: InputMaybe<AccessPermissionInput>;
  inventory?: InputMaybe<AccessPermissionInput>;
  inventory_export?: InputMaybe<AccessPermissionInput>;
  inventory_report?: InputMaybe<AccessPermissionInput>;
  inventory_setting?: InputMaybe<AccessPermissionInput>;
  is_custom: Scalars['Boolean']['input'];
  item?: InputMaybe<AccessPermissionInput>;
  logistic?: InputMaybe<AccessPermissionInput>;
  logistic_export?: InputMaybe<AccessPermissionInput>;
  logistic_report?: InputMaybe<AccessPermissionInput>;
  logistic_setting?: InputMaybe<AccessPermissionInput>;
  marketing?: InputMaybe<AccessPermissionInput>;
  marketing_export?: InputMaybe<AccessPermissionInput>;
  price_list?: InputMaybe<AccessPermissionInput>;
  promotion?: InputMaybe<AccessPermissionInput>;
  purchase?: InputMaybe<AccessPermissionInput>;
  purchase_export?: InputMaybe<AccessPermissionInput>;
  purchase_order?: InputMaybe<AccessPermissionInput>;
  purchase_report?: InputMaybe<AccessPermissionInput>;
  purchase_request?: InputMaybe<AccessPermissionInput>;
  purchase_return?: InputMaybe<AccessPermissionInput>;
  quotation?: InputMaybe<AccessPermissionInput>;
  role?: InputMaybe<AccessPermissionInput>;
  sales?: InputMaybe<AccessPermissionInput>;
  sales_export?: InputMaybe<AccessPermissionInput>;
  sales_invoice?: InputMaybe<AccessPermissionInput>;
  sales_order?: InputMaybe<AccessPermissionInput>;
  sales_report?: InputMaybe<AccessPermissionInput>;
  sales_return?: InputMaybe<AccessPermissionInput>;
  sales_setting?: InputMaybe<AccessPermissionInput>;
  setting?: InputMaybe<AccessPermissionInput>;
  user_account?: InputMaybe<AccessPermissionInput>;
  user_export?: InputMaybe<AccessPermissionInput>;
  vendor?: InputMaybe<AccessPermissionInput>;
  zone?: InputMaybe<AccessPermissionInput>;
};

export type ValidateContactImportInput = {
  branch_unique_id_list?: InputMaybe<Array<Scalars['String']['input']>>;
  contact_attribute_list?: InputMaybe<Array<Scalars['String']['input']>>;
  contact_channel_type_list?: InputMaybe<Array<Scalars['String']['input']>>;
  contact_group_list?: InputMaybe<Array<Scalars['String']['input']>>;
  contact_type_list?: InputMaybe<Array<Scalars['String']['input']>>;
  contact_unique_id_list?: InputMaybe<Array<Scalars['String']['input']>>;
  import_mode: ContactImportMode;
  import_type: ContactImportType;
  sales_channel_list?: InputMaybe<Array<Scalars['String']['input']>>;
  sales_person_unique_id_list?: InputMaybe<Array<Scalars['String']['input']>>;
  source_list?: InputMaybe<Array<Scalars['String']['input']>>;
  tag_list?: InputMaybe<Array<Scalars['String']['input']>>;
  zone_list?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ValidateContactImportResult = {
  __typename?: 'ValidateContactImportResult';
  existing_contact_unique_id_list?: Maybe<Array<Scalars['String']['output']>>;
  import_type: ContactImportType;
  missing_branch_unique_id_list?: Maybe<Array<Scalars['String']['output']>>;
  missing_contact_attribute_list?: Maybe<Array<Scalars['String']['output']>>;
  missing_contact_group_list?: Maybe<Array<Scalars['String']['output']>>;
  missing_contact_type_list?: Maybe<Array<Scalars['String']['output']>>;
  missing_contact_unique_id_list?: Maybe<Array<Scalars['String']['output']>>;
  missing_sales_channel_list?: Maybe<Array<Scalars['String']['output']>>;
  missing_sales_person_unique_id_list?: Maybe<Array<Scalars['String']['output']>>;
  missing_source_list?: Maybe<Array<Scalars['String']['output']>>;
  missing_tag_list?: Maybe<Array<Scalars['String']['output']>>;
  missing_zone_list?: Maybe<Array<Scalars['String']['output']>>;
};

export type ValidateCustomerInput = {
  unique_id: Scalars['String']['input'];
};

export type ValidateCustomerIsInUseResult = {
  __typename?: 'ValidateCustomerIsInUseResult';
  cash_sales?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  customer_deposit?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  quotation?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sales_invoice?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sales_order?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sales_return?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ValidateVendorInput = {
  unique_id: Scalars['String']['input'];
};

export type ValidateVendorIsInUseResult = {
  __typename?: 'ValidateVendorIsInUseResult';
  purchase_order?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  purchase_return?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type Vendor = {
  __typename?: 'Vendor';
  address_list: Array<Scalars['Json']['output']>;
  attachment_list: Array<Scalars['Json']['output']>;
  attribute_list: Array<Scalars['Json']['output']>;
  branch_unique_id?: Maybe<Scalars['String']['output']>;
  business_type?: Maybe<Scalars['String']['output']>;
  business_type_detail?: Maybe<Scalars['String']['output']>;
  contact_channel_list: Array<Scalars['Json']['output']>;
  contact_person_list: Array<Scalars['Json']['output']>;
  created_by?: Maybe<Scalars['Json']['output']>;
  created_date: Scalars['DateTime']['output'];
  credit_day?: Maybe<Scalars['Int']['output']>;
  credit_limit?: Maybe<Scalars['Float']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  img_url: Array<Scalars['String']['output']>;
  inactive_date?: Maybe<Scalars['DateTime']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  member_detail?: Maybe<Scalars['Json']['output']>;
  name: Scalars['String']['output'];
  nick_name?: Maybe<Scalars['String']['output']>;
  remark?: Maybe<Scalars['String']['output']>;
  remark_status?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  source_country?: Maybe<Scalars['String']['output']>;
  status: Scalars['Int']['output'];
  tag_id_list: Array<Scalars['Int']['output']>;
  tag_list: Array<Tag>;
  taxpayer_id?: Maybe<Scalars['String']['output']>;
  title_name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unique_id: Scalars['String']['output'];
  zone?: Maybe<Scalars['String']['output']>;
};

export type VendorAggrid = {
  __typename?: 'VendorAggrid';
  count: Scalars['Int']['output'];
  results: Array<Vendor>;
};

export type VendorCreateInput = {
  address_list: Array<Scalars['Json']['input']>;
  attachment_list: Array<Scalars['Json']['input']>;
  attribute_list: Array<Scalars['Json']['input']>;
  branch_unique_id?: InputMaybe<Scalars['String']['input']>;
  business_type?: InputMaybe<Scalars['String']['input']>;
  business_type_detail?: InputMaybe<Scalars['String']['input']>;
  contact_channel_list: Array<Scalars['Json']['input']>;
  contact_person_list: Array<Scalars['Json']['input']>;
  created_by?: InputMaybe<Scalars['Json']['input']>;
  credit_day?: InputMaybe<Scalars['Int']['input']>;
  credit_limit?: InputMaybe<Scalars['Float']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  img_url: Array<Scalars['String']['input']>;
  inactive_date?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  member_detail?: InputMaybe<Scalars['Json']['input']>;
  name: Scalars['String']['input'];
  nick_name?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  remark_status?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  source_country?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['Int']['input'];
  tag_id_list: Array<Scalars['Int']['input']>;
  taxpayer_id?: InputMaybe<Scalars['String']['input']>;
  title_name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
};

export type VendorImportDataInput = {
  address_list?: InputMaybe<Array<Scalars['Json']['input']>>;
  branch_unique_id?: InputMaybe<Scalars['String']['input']>;
  business_type?: InputMaybe<Scalars['String']['input']>;
  business_type_detail?: InputMaybe<Scalars['String']['input']>;
  contact_channel_list?: InputMaybe<Array<Scalars['Json']['input']>>;
  credit_day?: InputMaybe<Scalars['Int']['input']>;
  credit_limit?: InputMaybe<Scalars['Float']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  img_url?: InputMaybe<Array<Scalars['String']['input']>>;
  inactive_date?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  member_detail?: InputMaybe<Scalars['Json']['input']>;
  name: Scalars['String']['input'];
  nick_name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  remark_status?: InputMaybe<Scalars['String']['input']>;
  sales_person_unique_id?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  source_country?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  tag_list?: InputMaybe<Array<Scalars['String']['input']>>;
  taxpayer_id?: InputMaybe<Scalars['String']['input']>;
  title_name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  unique_id: Scalars['String']['input'];
  zone?: InputMaybe<Scalars['String']['input']>;
};

export type VendorImportInput = {
  data: Array<VendorImportDataInput>;
  import_mode: ContactImportMode;
  priority: Scalars['Int']['input'];
  user_unique_id: Scalars['String']['input'];
};

export type VendorUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type VendorUpdateInput = {
  address_list: Array<Scalars['Json']['input']>;
  attachment_list: Array<Scalars['Json']['input']>;
  attribute_list: Array<Scalars['Json']['input']>;
  branch_unique_id?: InputMaybe<Scalars['String']['input']>;
  business_type?: InputMaybe<Scalars['String']['input']>;
  business_type_detail?: InputMaybe<Scalars['String']['input']>;
  contact_channel_list: Array<Scalars['Json']['input']>;
  contact_person_list: Array<Scalars['Json']['input']>;
  created_by?: InputMaybe<Scalars['Json']['input']>;
  credit_day?: InputMaybe<Scalars['Int']['input']>;
  credit_limit?: InputMaybe<Scalars['Float']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  img_url: Array<Scalars['String']['input']>;
  inactive_date?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  member_detail?: InputMaybe<Scalars['Json']['input']>;
  name: Scalars['String']['input'];
  nick_name?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  remark_status?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  source_country?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['Int']['input'];
  tag_id_list: Array<Scalars['Int']['input']>;
  taxpayer_id?: InputMaybe<Scalars['String']['input']>;
  title_name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
};

export type VendorView = {
  __typename?: 'VendorView';
  address_list?: Maybe<Array<Scalars['Json']['output']>>;
  business_type?: Maybe<Scalars['String']['output']>;
  contact_channel_list?: Maybe<Array<Scalars['Json']['output']>>;
  credit_day?: Maybe<Scalars['Int']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  tag_list?: Maybe<Array<Scalars['String']['output']>>;
  taxpayer_id?: Maybe<Scalars['String']['output']>;
  unique_id: Scalars['String']['output'];
};

export type VendorViewAggrid = {
  __typename?: 'VendorViewAggrid';
  count: Scalars['Int']['output'];
  results: Array<VendorView>;
};

export type Warehouse = {
  __typename?: 'Warehouse';
  address?: Maybe<Scalars['Json']['output']>;
  branch: Branch;
  branch_id: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  is_consignment?: Maybe<Scalars['Boolean']['output']>;
  is_same_as_branch?: Maybe<Scalars['Boolean']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
  user: User;
  user_id: Scalars['Int']['output'];
  warehouse_level_1_list: Array<WarehouseLevel1>;
};

export type WarehouseLevel1 = {
  __typename?: 'WarehouseLevel1';
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  warehouse: Warehouse;
  warehouse_level_2_list?: Maybe<Array<WarehouseLevel2>>;
};

export type WarehouseLevel2 = {
  __typename?: 'WarehouseLevel2';
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  warehouse_level_1: WarehouseLevel1;
  warehouse_level_3_list?: Maybe<Array<WarehouseLevel3>>;
};

export type WarehouseLevel3 = {
  __typename?: 'WarehouseLevel3';
  bin_location_list?: Maybe<Array<BinLocation>>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  warehouse_level_2: WarehouseLevel2;
};

export type WorkflowTemplate = {
  __typename?: 'WorkflowTemplate';
  approval_template_list?: Maybe<Array<ApprovalTemplate>>;
  document_type: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status: Scalars['String']['output'];
  step_number: Scalars['Int']['output'];
  sub_status?: Maybe<Scalars['String']['output']>;
};

export enum UomConversionScalarField {
  BaseUomRate = 'base_uom_rate',
  Id = 'id',
  TargetUomRate = 'target_uom_rate'
}

export type CrmUniqueIdGenerateQueryVariables = Exact<{
  modelType: CrmModelType;
}>;


export type CrmUniqueIdGenerateQuery = { __typename?: 'Query', UniqueIdGenerate: string };

export type CustomerCreateMutationVariables = Exact<{
  data: CustomerCreateInput;
}>;


export type CustomerCreateMutation = { __typename?: 'Mutation', CustomerCreate: { __typename?: 'Customer', id: number, unique_id: string, branch_unique_id?: string | null, img_url: Array<string>, created_date: any, business_type?: string | null, business_type_detail?: string | null, title_name?: string | null, name: string, last_name?: string | null, nick_name?: string | null, gender?: string | null, source_country?: string | null, taxpayer_id?: string | null, type?: string | null, source?: string | null, group?: string | null, zone?: string | null, sales_channel?: string | null, contact_channel_list: Array<any>, status: number, remark_status?: string | null, tag_id_list: Array<number>, inactive_date?: any | null, address_list: Array<any>, contact_person_list: Array<any>, credit_limit?: number | null, credit_day?: number | null, attribute_list: Array<any>, attachment_list: Array<any>, sales_person_list: Array<any>, member_detail?: any | null, remark?: string | null, tag_list: Array<{ __typename?: 'Tag', document_type: TagDocumentType, id: number, name: string }> } };

export type CustomerDeleteMutationVariables = Exact<{
  uniqueInput: CustomerUniqueInput;
}>;


export type CustomerDeleteMutation = { __typename?: 'Mutation', CustomerDelete: { __typename?: 'Customer', id: number } };

export type CustomerFindUniqueQueryVariables = Exact<{
  uniqueInput: CustomerUniqueInput;
}>;


export type CustomerFindUniqueQuery = { __typename?: 'Query', CustomerFindUnique?: { __typename?: 'Customer', id: number, unique_id: string, branch_unique_id?: string | null, img_url: Array<string>, created_date: any, business_type?: string | null, business_type_detail?: string | null, title_name?: string | null, name: string, last_name?: string | null, nick_name?: string | null, gender?: string | null, source_country?: string | null, taxpayer_id?: string | null, type?: string | null, source?: string | null, group?: string | null, zone?: string | null, sales_channel?: string | null, contact_channel_list: Array<any>, status: number, remark_status?: string | null, tag_id_list: Array<number>, inactive_date?: any | null, address_list: Array<any>, contact_person_list: Array<any>, credit_limit?: number | null, credit_day?: number | null, attribute_list: Array<any>, attachment_list: Array<any>, sales_person_list: Array<any>, member_detail?: any | null, remark?: string | null, tag_list: Array<{ __typename?: 'Tag', document_type: TagDocumentType, id: number, name: string }> } | null };

export type CustomerUpdateMutationVariables = Exact<{
  uniqueInput: CustomerUniqueInput;
  data: CustomerUpdateInput;
}>;


export type CustomerUpdateMutation = { __typename?: 'Mutation', CustomerUpdate: { __typename?: 'Customer', id: number, unique_id: string, branch_unique_id?: string | null, img_url: Array<string>, created_date: any, business_type?: string | null, business_type_detail?: string | null, title_name?: string | null, name: string, last_name?: string | null, nick_name?: string | null, gender?: string | null, source_country?: string | null, taxpayer_id?: string | null, type?: string | null, source?: string | null, group?: string | null, zone?: string | null, sales_channel?: string | null, contact_channel_list: Array<any>, status: number, remark_status?: string | null, tag_id_list: Array<number>, inactive_date?: any | null, address_list: Array<any>, contact_person_list: Array<any>, credit_limit?: number | null, credit_day?: number | null, attribute_list: Array<any>, attachment_list: Array<any>, sales_person_list: Array<any>, member_detail?: any | null, remark?: string | null, tag_list: Array<{ __typename?: 'Tag', document_type: TagDocumentType, id: number, name: string }> } };

export type GenerateContactReportQueryVariables = Exact<{
  input?: InputMaybe<ReportGenerateInput>;
}>;


export type GenerateContactReportQuery = { __typename?: 'Query', GenerateReportNoQueue?: string | null };

export type ImportContactAddressMutationVariables = Exact<{
  importInput: ContactAddressImportInput;
}>;


export type ImportContactAddressMutation = { __typename?: 'Mutation', ImportContactAddress: { __typename?: 'ContactImportResult', success: boolean, timestamp?: any | null, status?: string | null, message?: string | null, jobId?: number | null, detail?: any | null } };

export type ImportContactAttributeMutationVariables = Exact<{
  importInput: ContactAttributeImportInput;
}>;


export type ImportContactAttributeMutation = { __typename?: 'Mutation', ImportContactAttribute: { __typename?: 'ContactImportResult', success: boolean, timestamp?: any | null, status?: string | null, message?: string | null, jobId?: number | null, detail?: any | null } };

export type ImportContactChannelMutationVariables = Exact<{
  importInput: ContactChannelImportInput;
}>;


export type ImportContactChannelMutation = { __typename?: 'Mutation', ImportContactChannel: { __typename?: 'ContactImportResult', success: boolean, timestamp?: any | null, status?: string | null, message?: string | null, jobId?: number | null, detail?: any | null } };

export type ImportContactPersonMutationVariables = Exact<{
  importInput: ContactPersonImportInput;
}>;


export type ImportContactPersonMutation = { __typename?: 'Mutation', ImportContactPerson: { __typename?: 'ContactImportResult', success: boolean, timestamp?: any | null, status?: string | null, message?: string | null, jobId?: number | null, detail?: any | null } };

export type ImportCustomerMutationVariables = Exact<{
  importInput: CustomerImportInput;
}>;


export type ImportCustomerMutation = { __typename?: 'Mutation', ImportCustomer: { __typename?: 'ContactImportResult', success: boolean, timestamp?: any | null, status?: string | null, message?: string | null, jobId?: number | null, detail?: any | null } };

export type ImportVendorMutationVariables = Exact<{
  importInput: VendorImportInput;
}>;


export type ImportVendorMutation = { __typename?: 'Mutation', ImportVendor: { __typename?: 'ContactImportResult', success: boolean, timestamp?: any | null, status?: string | null, message?: string | null, jobId?: number | null, detail?: any | null } };

export type ValidateContactImportQueryVariables = Exact<{
  validateInput: ValidateContactImportInput;
}>;


export type ValidateContactImportQuery = { __typename?: 'Query', ValidateContactImport: { __typename?: 'ValidateContactImportResult', existing_contact_unique_id_list?: Array<string> | null, missing_contact_unique_id_list?: Array<string> | null, missing_branch_unique_id_list?: Array<string> | null, missing_source_list?: Array<string> | null, missing_contact_type_list?: Array<string> | null, missing_contact_group_list?: Array<string> | null, missing_contact_attribute_list?: Array<string> | null, missing_zone_list?: Array<string> | null, missing_sales_channel_list?: Array<string> | null, missing_tag_list?: Array<string> | null, missing_sales_person_unique_id_list?: Array<string> | null, import_type: ContactImportType } };

export type VendorCreateMutationVariables = Exact<{
  data: VendorCreateInput;
}>;


export type VendorCreateMutation = { __typename?: 'Mutation', VendorCreate: { __typename?: 'Vendor', id: number, unique_id: string, branch_unique_id?: string | null, img_url: Array<string>, created_by?: any | null, created_date: any, business_type?: string | null, business_type_detail?: string | null, title_name?: string | null, name: string, last_name?: string | null, nick_name?: string | null, gender?: string | null, source_country?: string | null, taxpayer_id?: string | null, type?: string | null, source?: string | null, group?: string | null, zone?: string | null, contact_channel_list: Array<any>, status: number, remark_status?: string | null, tag_id_list: Array<number>, inactive_date?: any | null, address_list: Array<any>, contact_person_list: Array<any>, credit_limit?: number | null, credit_day?: number | null, attribute_list: Array<any>, attachment_list: Array<any>, member_detail?: any | null, remark?: string | null, tag_list: Array<{ __typename?: 'Tag', document_type: TagDocumentType, id: number, name: string }> } };

export type VendorDeleteMutationVariables = Exact<{
  uniqueInput: VendorUniqueInput;
}>;


export type VendorDeleteMutation = { __typename?: 'Mutation', VendorDelete: { __typename?: 'Vendor', id: number } };

export type VendorFindUniqueQueryVariables = Exact<{
  uniqueInput: VendorUniqueInput;
}>;


export type VendorFindUniqueQuery = { __typename?: 'Query', VendorFindUnique?: { __typename?: 'Vendor', id: number, unique_id: string, branch_unique_id?: string | null, img_url: Array<string>, created_date: any, business_type?: string | null, business_type_detail?: string | null, title_name?: string | null, name: string, last_name?: string | null, nick_name?: string | null, gender?: string | null, source_country?: string | null, taxpayer_id?: string | null, type?: string | null, source?: string | null, group?: string | null, zone?: string | null, contact_channel_list: Array<any>, status: number, remark_status?: string | null, tag_id_list: Array<number>, inactive_date?: any | null, address_list: Array<any>, contact_person_list: Array<any>, credit_limit?: number | null, credit_day?: number | null, attribute_list: Array<any>, attachment_list: Array<any>, member_detail?: any | null, remark?: string | null, tag_list: Array<{ __typename?: 'Tag', document_type: TagDocumentType, id: number, name: string }> } | null };

export type VendorUpdateMutationVariables = Exact<{
  uniqueInput: VendorUniqueInput;
  data: VendorUpdateInput;
}>;


export type VendorUpdateMutation = { __typename?: 'Mutation', VendorUpdate: { __typename?: 'Vendor', id: number, unique_id: string, branch_unique_id?: string | null, img_url: Array<string>, created_by?: any | null, created_date: any, business_type?: string | null, business_type_detail?: string | null, title_name?: string | null, name: string, last_name?: string | null, nick_name?: string | null, gender?: string | null, source_country?: string | null, taxpayer_id?: string | null, type?: string | null, source?: string | null, group?: string | null, zone?: string | null, contact_channel_list: Array<any>, status: number, remark_status?: string | null, tag_id_list: Array<number>, inactive_date?: any | null, address_list: Array<any>, contact_person_list: Array<any>, credit_limit?: number | null, credit_day?: number | null, attribute_list: Array<any>, attachment_list: Array<any>, member_detail?: any | null, remark?: string | null, tag_list: Array<{ __typename?: 'Tag', document_type: TagDocumentType, id: number, name: string }> } };


export const CrmUniqueIdGenerateDocument = `
    query CrmUniqueIdGenerate($modelType: CrmModelType!) {
  UniqueIdGenerate(modelType: $modelType)
}
    `;
export const useCrmUniqueIdGenerateQuery = <
      TData = CrmUniqueIdGenerateQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: CrmUniqueIdGenerateQueryVariables,
      options?: UseQueryOptions<CrmUniqueIdGenerateQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<CrmUniqueIdGenerateQuery, TError, TData>(
      ['CrmUniqueIdGenerate', variables],
      fetcher<CrmUniqueIdGenerateQuery, CrmUniqueIdGenerateQueryVariables>(client, CrmUniqueIdGenerateDocument, variables, headers),
      options
    );
export const CustomerCreateDocument = `
    mutation CustomerCreate($data: CustomerCreateInput!) {
  CustomerCreate(data: $data) {
    id
    unique_id
    branch_unique_id
    img_url
    created_date
    business_type
    business_type_detail
    title_name
    name
    last_name
    nick_name
    gender
    source_country
    taxpayer_id
    type
    source
    group
    zone
    sales_channel
    contact_channel_list
    status
    remark_status
    tag_id_list
    tag_list {
      document_type
      id
      name
    }
    inactive_date
    address_list
    contact_person_list
    credit_limit
    credit_day
    attribute_list
    attachment_list
    sales_person_list
    member_detail
    remark
  }
}
    `;
export const useCustomerCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CustomerCreateMutation, TError, CustomerCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CustomerCreateMutation, TError, CustomerCreateMutationVariables, TContext>(
      ['CustomerCreate'],
      (variables?: CustomerCreateMutationVariables) => fetcher<CustomerCreateMutation, CustomerCreateMutationVariables>(client, CustomerCreateDocument, variables, headers)(),
      options
    );
export const CustomerDeleteDocument = `
    mutation CustomerDelete($uniqueInput: CustomerUniqueInput!) {
  CustomerDelete(uniqueInput: $uniqueInput) {
    id
  }
}
    `;
export const useCustomerDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CustomerDeleteMutation, TError, CustomerDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CustomerDeleteMutation, TError, CustomerDeleteMutationVariables, TContext>(
      ['CustomerDelete'],
      (variables?: CustomerDeleteMutationVariables) => fetcher<CustomerDeleteMutation, CustomerDeleteMutationVariables>(client, CustomerDeleteDocument, variables, headers)(),
      options
    );
export const CustomerFindUniqueDocument = `
    query CustomerFindUnique($uniqueInput: CustomerUniqueInput!) {
  CustomerFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    branch_unique_id
    img_url
    created_date
    business_type
    business_type_detail
    title_name
    name
    last_name
    nick_name
    gender
    source_country
    taxpayer_id
    type
    source
    group
    zone
    sales_channel
    contact_channel_list
    status
    remark_status
    tag_id_list
    tag_list {
      document_type
      id
      name
    }
    inactive_date
    address_list
    contact_person_list
    credit_limit
    credit_day
    attribute_list
    attachment_list
    sales_person_list
    member_detail
    remark
  }
}
    `;
export const useCustomerFindUniqueQuery = <
      TData = CustomerFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: CustomerFindUniqueQueryVariables,
      options?: UseQueryOptions<CustomerFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<CustomerFindUniqueQuery, TError, TData>(
      ['CustomerFindUnique', variables],
      fetcher<CustomerFindUniqueQuery, CustomerFindUniqueQueryVariables>(client, CustomerFindUniqueDocument, variables, headers),
      options
    );
export const CustomerUpdateDocument = `
    mutation CustomerUpdate($uniqueInput: CustomerUniqueInput!, $data: CustomerUpdateInput!) {
  CustomerUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    branch_unique_id
    img_url
    created_date
    business_type
    business_type_detail
    title_name
    name
    last_name
    nick_name
    gender
    source_country
    taxpayer_id
    type
    source
    group
    zone
    sales_channel
    contact_channel_list
    status
    remark_status
    tag_id_list
    tag_list {
      document_type
      id
      name
    }
    inactive_date
    address_list
    contact_person_list
    credit_limit
    credit_day
    attribute_list
    attachment_list
    sales_person_list
    member_detail
    remark
  }
}
    `;
export const useCustomerUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CustomerUpdateMutation, TError, CustomerUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CustomerUpdateMutation, TError, CustomerUpdateMutationVariables, TContext>(
      ['CustomerUpdate'],
      (variables?: CustomerUpdateMutationVariables) => fetcher<CustomerUpdateMutation, CustomerUpdateMutationVariables>(client, CustomerUpdateDocument, variables, headers)(),
      options
    );
export const GenerateContactReportDocument = `
    query GenerateContactReport($input: ReportGenerateInput) {
  GenerateReportNoQueue(input: $input)
}
    `;
export const useGenerateContactReportQuery = <
      TData = GenerateContactReportQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GenerateContactReportQueryVariables,
      options?: UseQueryOptions<GenerateContactReportQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GenerateContactReportQuery, TError, TData>(
      variables === undefined ? ['GenerateContactReport'] : ['GenerateContactReport', variables],
      fetcher<GenerateContactReportQuery, GenerateContactReportQueryVariables>(client, GenerateContactReportDocument, variables, headers),
      options
    );
export const ImportContactAddressDocument = `
    mutation ImportContactAddress($importInput: ContactAddressImportInput!) {
  ImportContactAddress(importInput: $importInput) {
    success
    timestamp
    status
    message
    jobId
    detail
  }
}
    `;
export const useImportContactAddressMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ImportContactAddressMutation, TError, ImportContactAddressMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ImportContactAddressMutation, TError, ImportContactAddressMutationVariables, TContext>(
      ['ImportContactAddress'],
      (variables?: ImportContactAddressMutationVariables) => fetcher<ImportContactAddressMutation, ImportContactAddressMutationVariables>(client, ImportContactAddressDocument, variables, headers)(),
      options
    );
export const ImportContactAttributeDocument = `
    mutation ImportContactAttribute($importInput: ContactAttributeImportInput!) {
  ImportContactAttribute(importInput: $importInput) {
    success
    timestamp
    status
    message
    jobId
    detail
  }
}
    `;
export const useImportContactAttributeMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ImportContactAttributeMutation, TError, ImportContactAttributeMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ImportContactAttributeMutation, TError, ImportContactAttributeMutationVariables, TContext>(
      ['ImportContactAttribute'],
      (variables?: ImportContactAttributeMutationVariables) => fetcher<ImportContactAttributeMutation, ImportContactAttributeMutationVariables>(client, ImportContactAttributeDocument, variables, headers)(),
      options
    );
export const ImportContactChannelDocument = `
    mutation ImportContactChannel($importInput: ContactChannelImportInput!) {
  ImportContactChannel(importInput: $importInput) {
    success
    timestamp
    status
    message
    jobId
    detail
  }
}
    `;
export const useImportContactChannelMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ImportContactChannelMutation, TError, ImportContactChannelMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ImportContactChannelMutation, TError, ImportContactChannelMutationVariables, TContext>(
      ['ImportContactChannel'],
      (variables?: ImportContactChannelMutationVariables) => fetcher<ImportContactChannelMutation, ImportContactChannelMutationVariables>(client, ImportContactChannelDocument, variables, headers)(),
      options
    );
export const ImportContactPersonDocument = `
    mutation ImportContactPerson($importInput: ContactPersonImportInput!) {
  ImportContactPerson(importInput: $importInput) {
    success
    timestamp
    status
    message
    jobId
    detail
  }
}
    `;
export const useImportContactPersonMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ImportContactPersonMutation, TError, ImportContactPersonMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ImportContactPersonMutation, TError, ImportContactPersonMutationVariables, TContext>(
      ['ImportContactPerson'],
      (variables?: ImportContactPersonMutationVariables) => fetcher<ImportContactPersonMutation, ImportContactPersonMutationVariables>(client, ImportContactPersonDocument, variables, headers)(),
      options
    );
export const ImportCustomerDocument = `
    mutation ImportCustomer($importInput: CustomerImportInput!) {
  ImportCustomer(importInput: $importInput) {
    success
    timestamp
    status
    message
    jobId
    detail
  }
}
    `;
export const useImportCustomerMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ImportCustomerMutation, TError, ImportCustomerMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ImportCustomerMutation, TError, ImportCustomerMutationVariables, TContext>(
      ['ImportCustomer'],
      (variables?: ImportCustomerMutationVariables) => fetcher<ImportCustomerMutation, ImportCustomerMutationVariables>(client, ImportCustomerDocument, variables, headers)(),
      options
    );
export const ImportVendorDocument = `
    mutation ImportVendor($importInput: VendorImportInput!) {
  ImportVendor(importInput: $importInput) {
    success
    timestamp
    status
    message
    jobId
    detail
  }
}
    `;
export const useImportVendorMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ImportVendorMutation, TError, ImportVendorMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ImportVendorMutation, TError, ImportVendorMutationVariables, TContext>(
      ['ImportVendor'],
      (variables?: ImportVendorMutationVariables) => fetcher<ImportVendorMutation, ImportVendorMutationVariables>(client, ImportVendorDocument, variables, headers)(),
      options
    );
export const ValidateContactImportDocument = `
    query ValidateContactImport($validateInput: ValidateContactImportInput!) {
  ValidateContactImport(validateInput: $validateInput) {
    existing_contact_unique_id_list
    missing_contact_unique_id_list
    missing_branch_unique_id_list
    missing_source_list
    missing_contact_type_list
    missing_contact_group_list
    missing_contact_attribute_list
    missing_zone_list
    missing_sales_channel_list
    missing_tag_list
    missing_sales_person_unique_id_list
    import_type
  }
}
    `;
export const useValidateContactImportQuery = <
      TData = ValidateContactImportQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: ValidateContactImportQueryVariables,
      options?: UseQueryOptions<ValidateContactImportQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ValidateContactImportQuery, TError, TData>(
      ['ValidateContactImport', variables],
      fetcher<ValidateContactImportQuery, ValidateContactImportQueryVariables>(client, ValidateContactImportDocument, variables, headers),
      options
    );
export const VendorCreateDocument = `
    mutation VendorCreate($data: VendorCreateInput!) {
  VendorCreate(data: $data) {
    id
    unique_id
    branch_unique_id
    img_url
    created_by
    created_date
    business_type
    business_type_detail
    title_name
    name
    last_name
    nick_name
    gender
    source_country
    taxpayer_id
    type
    source
    group
    zone
    contact_channel_list
    status
    remark_status
    tag_id_list
    tag_list {
      document_type
      id
      name
    }
    inactive_date
    address_list
    contact_person_list
    credit_limit
    credit_day
    attribute_list
    attachment_list
    member_detail
    remark
  }
}
    `;
export const useVendorCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<VendorCreateMutation, TError, VendorCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<VendorCreateMutation, TError, VendorCreateMutationVariables, TContext>(
      ['VendorCreate'],
      (variables?: VendorCreateMutationVariables) => fetcher<VendorCreateMutation, VendorCreateMutationVariables>(client, VendorCreateDocument, variables, headers)(),
      options
    );
export const VendorDeleteDocument = `
    mutation VendorDelete($uniqueInput: VendorUniqueInput!) {
  VendorDelete(uniqueInput: $uniqueInput) {
    id
  }
}
    `;
export const useVendorDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<VendorDeleteMutation, TError, VendorDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<VendorDeleteMutation, TError, VendorDeleteMutationVariables, TContext>(
      ['VendorDelete'],
      (variables?: VendorDeleteMutationVariables) => fetcher<VendorDeleteMutation, VendorDeleteMutationVariables>(client, VendorDeleteDocument, variables, headers)(),
      options
    );
export const VendorFindUniqueDocument = `
    query VendorFindUnique($uniqueInput: VendorUniqueInput!) {
  VendorFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    branch_unique_id
    img_url
    created_date
    business_type
    business_type_detail
    title_name
    name
    last_name
    nick_name
    gender
    source_country
    taxpayer_id
    type
    source
    group
    zone
    contact_channel_list
    status
    remark_status
    tag_id_list
    tag_list {
      document_type
      id
      name
    }
    inactive_date
    address_list
    contact_person_list
    credit_limit
    credit_day
    attribute_list
    attachment_list
    member_detail
    remark
  }
}
    `;
export const useVendorFindUniqueQuery = <
      TData = VendorFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: VendorFindUniqueQueryVariables,
      options?: UseQueryOptions<VendorFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<VendorFindUniqueQuery, TError, TData>(
      ['VendorFindUnique', variables],
      fetcher<VendorFindUniqueQuery, VendorFindUniqueQueryVariables>(client, VendorFindUniqueDocument, variables, headers),
      options
    );
export const VendorUpdateDocument = `
    mutation VendorUpdate($uniqueInput: VendorUniqueInput!, $data: VendorUpdateInput!) {
  VendorUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    branch_unique_id
    img_url
    created_by
    created_date
    business_type
    business_type_detail
    title_name
    name
    last_name
    nick_name
    gender
    source_country
    taxpayer_id
    type
    source
    group
    zone
    contact_channel_list
    status
    remark_status
    tag_id_list
    tag_list {
      document_type
      id
      name
    }
    inactive_date
    address_list
    contact_person_list
    credit_limit
    credit_day
    attribute_list
    attachment_list
    member_detail
    remark
  }
}
    `;
export const useVendorUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<VendorUpdateMutation, TError, VendorUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<VendorUpdateMutation, TError, VendorUpdateMutationVariables, TContext>(
      ['VendorUpdate'],
      (variables?: VendorUpdateMutationVariables) => fetcher<VendorUpdateMutation, VendorUpdateMutationVariables>(client, VendorUpdateDocument, variables, headers)(),
      options
    );