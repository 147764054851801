import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { IGoodsTransfer } from "../../../types/Inventory/GoodsTransfer";
import { useInventoryError } from "../../../hooks/use-inventory-error";
import {
  goodsTransferSchema,
  goodsTransferValidation,
} from "../../../components/Form/Inventory/GoodsTransfer/schema";
import GoodsTransferHeader from "../../../components/Form/Inventory/GoodsTransfer/Header";
import GoodsTransferItemList from "../../../components/Table/Inventory/GoodsTransfer/itemList";
import GoodsTransferInfo from "../../../components/Form/Inventory/GoodsTransfer/info";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { Box, CircularProgress, Stack } from "@mui/material";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import {
  GoodsTransferCreateInput,
  GoodsTransferUpdateInput,
  InventoryDocumentType,
  ItemSkuQtysQuery,
  useGoodsTransferCreateMutation,
  useGoodsTransferQuery,
  useGoodsTransferUpdateMutation,
  useInventoryDocumentCancelMutation,
  useInventoryDocumentCancelWithPendingScanMutation,
  useItemSkuQtysQuery,
} from "../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { enqueueSnackbar } from "notistack";
import {
  goodsTransferCreateFormatter,
  goodsTransferQueryFormatter,
  goodsTransferUpdateFormatter,
} from "../../../utils/Formatter/Inventory/GoodsTransfer";
import { useCallback, useEffect, useState } from "react";
import { useDisable } from "../../../hooks/use-disable";
import { useTransferButtonByStatus } from "../../../hooks/Inventory/use-transfer-button-by-status";
import GoodsTransferHeaderBreadcrumbs from "../../../components/Form/Inventory/GoodsTransfer/HeaderBreadcrumbs";
import { IApprovalTemplate } from "../../../types/global";
import useInventoryControl from "../../../hooks/Inventory/use-inventory-control";
import { useStateContext } from "../../../contexts/auth-context";
import { useActivityLog } from "../../../hooks/use-activity-log";
import {
  ActivityType,
  ActivityLogDocumentType,
} from "../../../generated/general";
import dayjs from "dayjs";

const GoodsTransferContainer = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const graphQLClient = createGraphQLClientWithMiddleware("wms");
  const [, setDisabled] = useDisable();
  setDisabled(false);
  const {
    state: { authUser, permissions },
  } = useStateContext();

  const { createActivityLog } = useActivityLog();

  const [approverList, setApproverList] = useState<IApprovalTemplate[]>([]);
  const [approvalStep, setApprovalStep] = useState<number | null | undefined>(
    0
  );
  const [isHaveApproval, setIsHaveApproval] = useState<boolean>(false);

  const { data, isLoading, refetch, isSuccess } = useGoodsTransferQuery(
    graphQLClient,
    {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    },
    {
      enabled: !!id,
    }
  );

  const goodsTransferInfo = data?.GoodsTransfer;

  const { refetch: refetchItemSku } = useItemSkuQtysQuery<ItemSkuQtysQuery>(
    graphQLClient,
    {
      findManyInput: {
        where: {
          barcode: {
            in: goodsTransferInfo?.trace_entry_list.reduce<string[]>(
              (list, traceEntry) => {
                if (traceEntry.barcode) list.push(traceEntry.barcode);

                return list;
              },
              []
            ),
          },
        },
      },
    },
    {
      enabled: !!id,
    }
  );

  const currentStatus = goodsTransferInfo?.aggrid_status;

  const methods = useForm<IGoodsTransfer>({
    defaultValues: goodsTransferSchema,
    resolver: yupResolver<any>(goodsTransferValidation),
  });

  const { mutateAsync: create, isLoading: isCreating } =
    useGoodsTransferCreateMutation<Error>(graphQLClient);

  const { mutateAsync: updateTransfer, isLoading: isUpdate } =
    useGoodsTransferUpdateMutation<Error>(graphQLClient);

  const { mutateAsync: cancelTransfer, isLoading: isCanceling } =
    useInventoryDocumentCancelMutation<Error>(graphQLClient);

  const { mutateAsync: cancelPendingScan, isLoading: isCancelingPendingScan } =
    useInventoryDocumentCancelWithPendingScanMutation<Error>(graphQLClient);

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
  } = methods;

  const destination_branch_id = useWatch({
    control,
    name: "destination_branch_id",
  });

  const destination_warehouse_id = useWatch({
    control,
    name: "destination_warehouse_id",
  });

  const { isOptionsLoading, approvalTemplates } = useInventoryControl(
    destination_branch_id,
    destination_warehouse_id,
    "goods_transfer"
  );

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "trace_entry_list",
  });

  const formatGT = useCallback(
    async (data: IGoodsTransfer) => {
      const isFreezeStockQty = ["in_transit", "finished", "cancelled"].includes(
        currentStatus || ""
      );
      const itemSkuData = isFreezeStockQty
        ? undefined
        : (await refetchItemSku()).data;
      const goodsTransfer = goodsTransferQueryFormatter(data, itemSkuData);

      reset(goodsTransfer);

      if (currentStatus && ["finished", "cancelled"].includes(currentStatus)) {
        setDisabled(true);
      }
    },
    [reset, setDisabled, currentStatus, refetchItemSku]
  );

  const getGoodsTransfer = useCallback(async () => {
    if (isSuccess) {
      const { GoodsTransfer } = data;
      const goodsTransfer = GoodsTransfer as IGoodsTransfer;
      formatGT(goodsTransfer);

      const currentStep = GoodsTransfer?.approval_step;
      setApprovalStep(currentStep);

      if (GoodsTransfer?.aggrid_status === "wait_approve") {
        setApproverList(approvalTemplates as IApprovalTemplate[]);
      } else {
        const allApproval = GoodsTransfer?.approver_list || [];
        setApproverList(allApproval);
      }

      const currentReviewerList =
        approvalTemplates.find(
          (template) => template?.step_number === currentStep
        )?.approver_list || [];

      const isHaveApproval = currentReviewerList.some(
        (reviewer) => reviewer.id === authUser?.id
      );

      setIsHaveApproval(isHaveApproval);
    }
  }, [approvalTemplates, authUser?.id, data, formatGT, isSuccess]);

  useEffect(() => {
    getGoodsTransfer();
  }, [getGoodsTransfer]);

  const statusButtonInfo = useTransferButtonByStatus(
    approvalTemplates.length > 0,
    isHaveApproval
  );

  const handleCreateUpdate = async (
    data: IGoodsTransfer,
    title: string,
    nextStatus: string | undefined
  ) => {
    try {
      if (nextStatus === "cancelled") {
        reset();
        return;
      }

      let changingStatus = nextStatus;
      let currentApprovalList: IApprovalTemplate[] = [];
      let currentApprovalStep = approvalStep || 0;
      const reviewed_by = {
        user_id: authUser?.id,
        first_name: authUser?.first_name || "",
        last_name: authUser?.last_name || "",
        reviewed_date: dayjs(),
      };

      if (data.aggrid_status === "wait_approve") {
        if (nextStatus === "not_approved") {
          changingStatus = "not_approved";
          currentApprovalList = approvalTemplates as IApprovalTemplate[];
        } else {
          if (approvalStep && approvalStep > approvalTemplates.length - 1) {
            if (nextStatus) {
              changingStatus = "wait_transfer";
              currentApprovalList = approvalTemplates as IApprovalTemplate[];
            }
          } else {
            changingStatus = "wait_approve";
            if (nextStatus) {
              currentApprovalStep = currentApprovalStep + 1;
            }
          }
        }
      } else {
        if (nextStatus === "wait_approve") {
          currentApprovalStep = 1;
        }
        changingStatus = nextStatus;
      }

      const formatData = id
        ? goodsTransferUpdateFormatter(
            data,
            changingStatus,
            currentApprovalStep,
            currentApprovalList,
            reviewed_by
          )
        : goodsTransferCreateFormatter(
            data,
            changingStatus,
            approvalTemplates.length > 0 ? 1 : 0
          );

      const result: any = id
        ? await updateTransfer({
            uniqueInput: { id: id ? parseInt(id) : undefined },
            updateInput: formatData as GoodsTransferUpdateInput,
          })
        : await create({ createInput: formatData as GoodsTransferCreateInput });

      if (!id) {
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsTransfer,
          reference_id: result.GoodsTransferCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: changingStatus,
          },
        });
      } else if (id && changingStatus === "wait_transfer") {
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsTransfer,
          reference_id: result.GoodsTransferUpdate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Approve,
            message: [`ลำดับที่ ${approvalStep}`],
            prev_status: "wait_approve",
            curr_status: "wait_transfer",
          },
        });
      } else if (
        id &&
        changingStatus === "wait_approve" &&
        approvalStep !== currentApprovalStep
      ) {
        await createActivityLog({
          activity_type: ActivityType.Approve,
          document_type: ActivityLogDocumentType.GoodsTransfer,
          reference_id: result.GoodsTransferUpdate?.id,
          activity_detail: {
            message: [`ลำดับที่ ${approvalStep}`],
          },
        });
      } else if (id && nextStatus && data.aggrid_status !== nextStatus) {
        const formattedDirtyFields = Object.keys(dirtyFields);
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsTransfer,
          reference_id: result.GoodsTransferUpdate?.id,
          activity_detail: {
            secondary_operation:
              formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
            prev_status: data.aggrid_status,
            curr_status: changingStatus,
            updated_fields:
              formattedDirtyFields?.length > 0
                ? formattedDirtyFields
                : undefined,
          },
        });
      } else {
        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.GoodsTransfer,
          reference_id: result.GoodsTransferUpdate?.id,
          activity_detail: {},
        });
      }

      if (!id)
        navigate(`/inventory/goods-transfer/${result.GoodsTransferCreate.id}`);
      await refetch();

      enqueueSnackbar(`${title}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${title}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const cancelHandler = async () => {
    try {
      await cancelTransfer({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        documentType: InventoryDocumentType.GoodsTransfer,
      });
      await createActivityLog({
        activity_type: ActivityType.Cancel,
        document_type: ActivityLogDocumentType.GoodsTransfer,
        reference_id: parseInt(id!),
        activity_detail: {},
      });
      await refetch();
      enqueueSnackbar(`${t("status.cancelled")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("status.cancelled")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const cancelPendingScanHandler = async () => {
    try {
      await cancelPendingScan({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        documentType: InventoryDocumentType.GoodsTransfer,
      });
      await createActivityLog({
        activity_type: ActivityType.CancelPendingScan,
        document_type: ActivityLogDocumentType.GoodsTransfer,
        reference_id: parseInt(id!),
        activity_detail: {},
      });
      await refetch();
      enqueueSnackbar(`${t("button.cancel_pending_scan")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("button.cancel_pending_scan")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const renderButton = (status?: string) => {
    if (permissions?.goods_transfer?.update || !status) {
      return (
        <>
          {statusButtonInfo(status).map((button, index) => {
            return (
              <CustomizedButton
                key={index}
                variant={button.variant}
                title={button.title}
                onClick={handleSubmit((data) =>
                  handleCreateUpdate(data, button.title, button.next)
                )}
              />
            );
          })}
        </>
      );
    } else {
      return null;
    }
  };

  useInventoryError(errors);

  if (
    isOptionsLoading ||
    (id &&
      (isLoading ||
        isCreating ||
        isUpdate ||
        isCanceling ||
        isCancelingPendingScan))
  ) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <FormProvider {...methods}>
      <GoodsTransferHeaderBreadcrumbs
        cancelHandler={cancelHandler}
        cancelPendingScanHandler={cancelPendingScanHandler}
      />
      <GoodsTransferHeader
        approverList={approverList}
        approvalStep={approvalStep}
      />
      <GoodsTransferInfo />
      <GoodsTransferItemList
        fields={fields}
        remove={remove}
        update={update}
        append={append}
      />
      <BottomNavbar>
        <Stack direction="row" spacing={1} alignItems="center">
          {renderButton(currentStatus || "")}
        </Stack>
      </BottomNavbar>
    </FormProvider>
  );
};

export default GoodsTransferContainer;
