import { Grid, IconButton, Typography } from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import {
  Controller,
  UseFieldArrayReplace,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useState } from "react";
import { ISelectOption } from "../../../../types/global";
import { IGoodsReceive } from "../../../../types/Inventory/GoodsReceive";
import ControlledSelect from "../../../Controller/ControlledSelect";
import useInventoryControl from "../../../../hooks/Inventory/use-inventory-control";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import { useParams } from "react-router-dom";
import {
  branchInfoFromSessionStorage,
  checkIsNotDraftOrEmptyStatus,
} from "../../../../utils/Global";
import LabelInput from "../../../UI/LabelInput";
import { goodsReceiveTypeValueFormatter } from "../../../../utils/Formatter/Inventory/GoodsReceive";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { PURCHASE_ORDER_AGGRID } from "../../../../services/AgGrid/PurchaseAgGrid";
import { formatPurchaseItemListToGoodsReceiveItemList } from "../../../../utils/Formatter/Purchase/PurchaseOrder";
import { useModal } from "../../../../hooks/use-modal";
import { useStateContext } from "../../../../contexts/auth-context";

const typeOptions: ISelectOption[] = [
  {
    label: "ซื้อ",
    value: "purchase_order",
  },
  {
    label: "อื่นๆ",
    value: "other",
  },
];

type Props = {
  generateUniqueId: () => void;
  replace: UseFieldArrayReplace<IGoodsReceive, "trace_entry_list">;
};

const GoodsReceiveInfo = ({ generateUniqueId, replace }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    resetField,
  } = useFormContext();
  const [currentType, setCurrentType] = useState<string>("");
  const { branch_id, is_hq } = branchInfoFromSessionStorage();
  const graphQLClientWithHeader = createGraphQLClientWithMiddleware("purchase");

  // const [currentReference, setCurrentReference] = useState<string>("");
  // const [referenceUniqueIdOptions, setReferenceUniqueIdOptions] = useState<
  //   ISelectOption[]
  // >([]);
  const {
    state: { permissions },
  } = useStateContext();

  const watchBranchId = useWatch({
    control,
    name: "branch_id",
  });

  const watchType = useWatch({
    control,
    name: "type",
  });

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const {
    branchesOptions,
    warehousesOptions,
    renderBranchOptions,
    renderWarehousesOptions,
    purchaseOrderOptions,
  } = useInventoryControl(watchBranchId, undefined, watchType);

  const onTypeChangeHandler = () => {
    replace([]);
    setValue("type", currentType);
    setValue("reference_unique_id_list", []);
  };

  const {
    modal: modalChangeType,
    openModalHandler: openModalChangeType,
    closeModalHandler: closeModalChangeType,
    submitModalHandler: submitModalChangeType,
  } = useModal(onTypeChangeHandler);

  // const { fields, append, remove } = useFieldArray({
  //   name: "reference_unique_id_list",
  //   control,
  // });

  // const addReferenceUniqueIdHandler = () => {
  //   append("");
  // };

  // const removeReferenceUniqueIdHandler = (index: number) => {
  //   remove(index);
  // };

  const onReferenceChangeHandler = async (po_unique_id: string) => {
    const { PurchaseOrderViewsAggrid } = await graphQLClientWithHeader.request(
      PURCHASE_ORDER_AGGRID,
      {
        aggridInput: {
          startRow: 0,
          endRow: 9999,
          filterModel: {
            unique_id: {
              filterType: "text",
              type: "contains",
              filter: po_unique_id,
            },
          },
        },
      }
    );

    setValue(
      "trace_entry_list",
      formatPurchaseItemListToGoodsReceiveItemList(
        PurchaseOrderViewsAggrid.results[0]
      )
    );
  };

  const notDraft = checkIsNotDraftOrEmptyStatus(status);
  const isFinishedOrUneditable =
    ["finished", "cancelled"].includes(status) ||
    (Boolean(id) && !permissions?.goods_receive?.update);

  return (
    <CustomizedBox margin={"0 0 1.5rem 0"}>
      <Typography color="primary.main" fontWeight={"bold"} mb={2}>
        {t("sentence.detail")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={11} sm={11} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="unique_id"
            control={control}
            label={t(`sentence.unique_id`)}
            error={Boolean(errors.unique_id)}
            helperText={errors.unique_id?.message?.toString()}
            disabled={Boolean(id)}
            viewMode={isFinishedOrUneditable}
            required
          />
        </Grid>
        <Grid item xs={1} sm={1} md={8} lg={8} xl={8}>
          {!Boolean(id) && (
            <CustomizedTooltip
              title={`เรียก${t(`sentence.unique_id`)}`}
              enterNextDelay={200}
            >
              <IconButton onClick={generateUniqueId} color="primary">
                <RestartAltOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {isFinishedOrUneditable ? (
            <LabelInput
              label={t("sentence.type")}
              value={goodsReceiveTypeValueFormatter(getValues("type"))}
            />
          ) : (
            <ControlledSelect
              control={control}
              name="type"
              label={t("sentence.type")}
              options={typeOptions}
              onChange={(e: any) => {
                setCurrentType(e.target.value);
                if (e.target.value === "other") {
                  setValue("reference_unique_id_list", []);
                }
                const currentTrace = getValues("trace_entry_list");
                if (currentTrace.length > 0) {
                  onTypeChangeHandler();
                }
              }}
              disabled={notDraft}
              required
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {isFinishedOrUneditable ? (
            <LabelInput
              label={t("branch.index")}
              value={renderBranchOptions(getValues("branch_id"))}
            />
          ) : (
            <Controller
              control={control}
              name="branch_id"
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  label={t("branch.index")}
                  options={
                    is_hq === "true"
                      ? branchesOptions
                      : branchesOptions.filter(
                          (branch) =>
                            branch.value === parseInt(branch_id ?? "0")
                        )
                  }
                  disabled={notDraft}
                  onChange={(_, newValue) => {
                    field.onChange(newValue?.value ?? "");
                    resetField("warehouse_id");
                  }}
                  value={renderBranchOptions(field.value)}
                  error={Boolean(errors.branch_id)}
                  helperText={errors.branch_id?.message?.toString()}
                  required
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {isFinishedOrUneditable ? (
            <LabelInput
              label={t("inventory.warehouse")}
              value={renderWarehousesOptions(getValues("warehouse_id"))}
            />
          ) : (
            <Controller
              control={control}
              name="warehouse_id"
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  label={t("inventory.warehouse")}
                  options={warehousesOptions}
                  disabled={notDraft || warehousesOptions.length === 0}
                  onChange={(_, newValue) => {
                    field.onChange(newValue?.value ?? "");
                  }}
                  value={renderWarehousesOptions(field.value)}
                  error={Boolean(errors.warehouse_id)}
                  helperText={errors.warehouse_id?.message?.toString()}
                  required
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {isFinishedOrUneditable ? (
            <LabelInput
              label={t("sentence.reference_unique_id")}
              value={getValues("reference_unique_id_list").join(",")}
            />
          ) : (
            <Controller
              control={control}
              name="reference_unique_id_list"
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  label={t("sentence.reference_unique_id")}
                  value={field.value[0]}
                  options={purchaseOrderOptions}
                  required={watchType && watchType !== "other"}
                  disabled={notDraft || watchType === "other"}
                  onChange={async (_, newValue) => {
                    if (newValue) {
                      field.onChange([newValue.value]);
                      onReferenceChangeHandler(newValue.value);
                    } else {
                      field.onChange([]);
                      setValue("trace_entry_list", []);
                    }
                  }}
                  error={Boolean(errors.reference_unique_id)}
                  helperText={errors.reference_unique_id?.message?.toString()}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <ControlledTextField
            control={control}
            name="remark"
            label={t("inventory.remark")}
            viewMode={isFinishedOrUneditable}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default GoodsReceiveInfo;
