import { Grid } from "@mui/material";
import ControlledTextField from "../../Controller/ControlledTextField";
import ControlledSelect from "../../Controller/ControlledSelect";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { ICompany } from "../../../types/Setting/Company";
import { useDisable } from "../../../hooks/use-disable";
import LabelInput from "../../UI/LabelInput";
import ControlledAddressBox from "../../Controller/ControlledAddressBox";
import { countryOptions } from "../../../utils/Global";
import { countryFormatter } from "../../../utils/Formatter/Global";

type Props = {
  index: number;
  addressList: any[] | null | undefined;
};

const AddressDetail = ({ index, addressList }: Props) => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<ICompany>();
  const [disabled] = useDisable();
  const { t } = useTranslation();

  const [addressValue, setAddressValue] = useState<any>("");

  const defaultAddressValue = useWatch({
    control,
    name: `address_list.0`,
  });

  const isSameAsDefault = useWatch({
    control,
    name: `address_list.${index}.is_same_as_default_address`,
  });

  useEffect(() => {
    if (isSameAsDefault) {
      const { contact_name, contact_phone, fax } = getValues(
        `address_list.${index}`
      );
      const newAddressValue = {
        ...defaultAddressValue,
        contact_name: contact_name ?? "",
        contact_phone: contact_phone ?? "",
        fax: fax ?? "",
        is_default: false,
        is_same_as_default_address: true,
        type: index === 1 ? "ที่อยู่จัดส่งสินค้า" : "",
      };
      setValue(`address_list.${index}`, newAddressValue);
      setAddressValue(newAddressValue);
    }
  }, [defaultAddressValue, index, isSameAsDefault, setValue, getValues]);

  useEffect(() => {
    if (disabled) {
      setAddressValue(addressList ? addressList[index] : "");
    }
  }, [addressList, disabled, index]);

  return (
    <>
      {index !== 0 && (
        <>
          <Grid item xs={12}>
            <ControlledTextField
              name={`address_list.${index}.contact_name`}
              control={control}
              label={t("contact.address.contact_person_name")}
              error={Boolean(errors.address_list?.[index]?.contact_name)}
              helperText={
                errors.address_list &&
                errors.address_list?.[index]?.contact_name &&
                errors.address_list?.[index]?.contact_name?.message
              }
              viewMode={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <ControlledTextField
              name={`address_list.${index}.contact_phone`}
              control={control}
              label={t("contact.address.phone_number")}
              error={Boolean(errors.address_list?.[index]?.contact_phone)}
              helperText={
                errors.address_list &&
                errors.address_list?.[index]?.contact_phone &&
                errors.address_list?.[index]?.contact_phone?.message
              }
              viewMode={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <ControlledTextField
              name={`address_list.${index}.fax`}
              control={control}
              label={t("contact.address.fax")}
              error={Boolean(errors.address_list?.[index]?.fax)}
              helperText={
                errors.address_list &&
                errors.address_list?.[index]?.fax &&
                errors.address_list?.[index]?.fax?.message
              }
              viewMode={disabled}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <ControlledTextField
          name={`address_list.${index}.address_name`}
          control={control}
          label={t("contact.address.index")}
          error={Boolean(errors.address_list?.[index]?.address_name)}
          helperText={
            errors.address_list &&
            errors.address_list?.[index]?.address_name &&
            errors.address_list?.[index]?.address_name?.message
          }
          rows={4}
          multiline
          disabled={isSameAsDefault}
          viewMode={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <ControlledAddressBox
          name={`address_list.${index}`}
          type="sub_district"
          disabled={isSameAsDefault}
          getValues={getValues}
          setValue={setValue}
          addressValue={addressValue}
          setAddressValue={setAddressValue}
          viewMode={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <ControlledAddressBox
          name={`address_list.${index}`}
          type="district"
          disabled={isSameAsDefault}
          getValues={getValues}
          setValue={setValue}
          addressValue={addressValue}
          setAddressValue={setAddressValue}
          viewMode={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <ControlledAddressBox
          name={`address_list.${index}`}
          type="province"
          disabled={isSameAsDefault}
          getValues={getValues}
          setValue={setValue}
          addressValue={addressValue}
          setAddressValue={setAddressValue}
          viewMode={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <ControlledAddressBox
          name={`address_list.${index}`}
          type="postal_code"
          disabled={isSameAsDefault}
          getValues={getValues}
          setValue={setValue}
          addressValue={addressValue}
          setAddressValue={setAddressValue}
          viewMode={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        {disabled ? (
          <LabelInput
            label={t("contact.address.country")}
            value={countryFormatter(getValues(`address_list.${index}.country`))}
          />
        ) : (
          <ControlledSelect
            name={`address_list.${index}.country`}
            control={control}
            label={t("contact.address.country")}
            error={Boolean(errors.address_list?.[index]?.country)}
            helperText={
              errors.address_list &&
              errors.address_list?.[index]?.country &&
              errors.address_list?.[index]?.country?.message
            }
            options={countryOptions}
            disabled={true}
          />
        )}
      </Grid>
    </>
  );
};

export default AddressDetail;
